<template>
  <div
    v-loading="isLoading"
    class="data-entry-landing"
  >
    <template v-if="isInfoLoaded">
      <div
        class="data-entry-containers"
        data-test="entry container"
      >
        <container
          v-for="(container, containerIndex) in containers"
          :key="container.id.join('_')"
          :container="container"
          :container-index="containerIndex"
          :warning-message="
            !reviewAlertDismissed
              && !containerIndex
              && warningMessage ? warningMessage : null"
          @dismissAlert="reviewAlertDismissed = true"
        />
        <div
          v-if="!isLoading && !isStopLoss"
          id="rate-guarantee-container"
        >
          <div>
            <h3>Rate guarantee</h3>
            <ElForm
              ref="form"
              label-width="120px"
              label-position="left"
            >
              <ElFormItem
                label="# of months"
                size="medium"
                :class="{ 'is-error': rateGuaranteeError }"
              >
                <TfInputWithOptions
                  ref="rateGuarantee"
                  v-model="rateGuarantee"
                  v-bind="{
                    allowEnter: false,
                    disabled: $route.meta.readonly,
                    options: rateEntry.rate_guarantee
                      ? rateEntry.rate_guarantee.normalized_values
                      : [],
                    prop: 'rate guarantee'
                  }"
                  placeholder="Enter guarantee"
                  validation-type="whole-number"
                  data-test="guarantee field"
                />
              </ElFormItem>
            </ElForm>
          </div>
        </div>
      </div>
      <footer :class="['renewal-footer', { 'review-footer' : $route.meta.readonly }]">
        <div class="btn-group">
          <AppButton
            text="Your Quotes"
            data-test="back button"
            icon="fa-solid fa-circle-left"
            class="icon-left"
            @click="sendYourQuotesAnalytics"
          />
          <AppButton
            v-if="showReviewPlanDesignSecondaryButton"
            text="Review Plan Design"
            data-test="continue secondary"
            @click="() => $router.push({ name: 'PlanDesign' })"
          />
          <AppButton
            v-if="showSubmitQuoteSecondaryButton"
            text="Submit Quote"
            data-test="submit quote secondary"
            @click="handleSubmitQuote"
          />
        </div>
        <div
          data-test="stepper helptext"
          class="stepper-helptext"
        >
          {{ rateErrorCountText }}
        </div>
        <div class="btn-group">
          <AppButton
            v-if="$route.meta.readonly"
            icon="check"
            text="Done"
            @click="$router.push({ name: 'RfpOverview' })"
          />
          <AppButton
            v-else-if="showNextButton"
            text="Next"
            data-test="next error button"
            class="submit-button"
            icon="fa-solid fa-circle-right"
            @click="() => nextError()"
          />
          <template v-else>
            <template v-if="!isPlanDesignValid">
              <AppButton
                data-test="continue"
                class="submit-button"
                icon="fa-solid fa-circle-right"
                text="Review Plan Design"
                type="primary"
                @click="$router.push({ name: 'PlanDesign' })"
              />
            </template>
            <AppButton
              v-else
              id="btn-submit-quote"
              :is-disabled="!canSubmit || validatingProduct"
              :is-loading="validatingProduct"
              icon="fa-solid fa-circle-right"
              class="submit-button"
              data-test="submit quote"
              text="Submit Quote"
              @click="onSubmitQuote"
            />
          </template>
        </div>
      </footer>
      <SendAltsToCarrierModal
        v-if="showSendAltsModal"
        data-test="send alts modal"
        :product-id="productId"
        :visible.sync="showSendAltsModal"
      />
    </template>
  </div>
</template>

<script>
  import { mapState, mapActions, mapWritableState } from 'pinia';
  import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
  import { useNotificationsStore } from '@/stores/notifications.js';
  import { useRateEntryStore } from '@/stores/rateEntry.js';
  import { useQuoteEditsStore } from '@/stores/quoteEdits.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductStore } from '@/stores/product.js';
  import { useAccountStore } from '@/stores/account.js';
  import { usePlanDesignStore } from '@/stores/planDesign.js';
  import Container from '@/components/RateEntry/RateEntryContainer.vue';
  import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
  import SendAltsToCarrierModal from '@/components/Modals/SendAltsToCarrierModal.vue';

  /**
   * Rate Entry for the product page.
   *
   * @exports ProductLanding/RateEntryRenewalLanding
   */
  export default {
    name: 'RateEntryRenewalLanding',
    components: { Container, SendAltsToCarrierModal },
    data: () => ({
      destroyProductId: null,
      displayAlertReview: false,
      fauxLoading: false,
      timer: 0,
      validatingProduct: false,
      warningMessage: null,
      currentMissingIndex: -1,
      currentError: -1,
      fieldSwitchTimer: null,
      showSendAltsModal: false,
    }),

    computed: {
      ...mapWritableState(useNotificationsStore, ['reviewAlertDismissed']),
      ...mapWritableState(useRateEntryStore, {
        rateEntryRateGuarantee: 'rateGuarantee',
        validRateEntry: 'validRateEntry',
        rateErrors: 'rateErrors',
        editingRateAttributeValue: 'editingRateAttributeValue',

      }),
      ...mapState(useCarrierInfoStore, { carrierId: 'id', carrierName: 'name' }),
      ...mapState(useRateEntryStore, {
        rateAttributes: 'rateAttributes',
        loading: 'loadingRateEntry',
        rateEntry: 'info',
        isInfoLoaded: 'isLoaded',
        containers: 'rateEntryContainers',
        patchingGuarantee: 'patchingGuarantee',
        isRateEntryValid: 'isRateEntryValid',
        rateUpdateRequests: 'rateUpdateRequests',
      }),
      ...mapState(useProjectStore, ['project', 'projectId', 'proposalDocumentId']),
      ...mapState(useProductStore, [
        'currentProduct',
        'isStopLoss',
        'productId',
        'productState',
        'currentProductPreviouslySubmitted',
        'isSmartProposal',
        'smartDocType',
        'isProductNotStartedOrNotSubmitted',
      ]),
      ...mapState(useAccountStore, ['rateProductsVisited', 'roleNames']),
      ...mapState(usePlanDesignStore, ['validPlanDesign', 'isPlanDesignValid']),
      /**
       * Loading conditions
       *
       * @returns {boolean}
       */
      isLoading() {
        return (this.loading && !this.rateUpdateRequests.length) || this.fauxLoading;
      },
      /**
       * Whether to show rate guarantee field with a red border
       *
       * @returns {boolean}
       */
      rateGuaranteeError() {
        return !this.rateGuarantee && !this.isProductNotStartedOrNotSubmitted;
      },
      /**
       * Error count text for stepper.
       *
       * @returns {string}
       */
      rateErrorCountText() {
        if (this.isProductNotStartedOrNotSubmitted) {
          return '';
        }

        if (this.rateErrors?.length) {
          const rateFieldCount = Object.values(this.rateAttributes)
            .map((attr) => Object.values(attr.rateValues)
              .map(({ values }) => values.length)).flat().reduce((sum, count) => sum + count, 0);

          return `Missing ${this.rateErrors.length} of ${rateFieldCount} rates`;
        }

        if (!this.isStopLoss && !this.rateGuarantee) {
          return 'Missing rate guarantee';
        }

        return 'Rates are complete';
      },
      /**
       * Only show secondary button for smart proposals
       *
       * @returns {boolean}
       */
      showSubmitQuoteSecondaryButton() {
        return this.isSmartProposal && !this.isPlanDesignValid && this.isRateEntryValid;
      },
      /**
       * Show secondary review plan design button
       *
       * @returns {boolean}
       */
      showReviewPlanDesignSecondaryButton() {
        return !this.isPlanDesignValid && this.showNextButton;
      },
      /**
       * Don't show Next button on Not Started products
       *
       * @returns {boolean}
       */
      showNextButton() {
        return !this.isRateEntryValid && (this.isSmartProposal || !this.isProductNotStartedOrNotSubmitted);
      },
      /**
       * Returns a boolean to determine if the continue button is enabled based on if all proposal inputs.
       * For stop loss products, we ignore the rateGuarantee.
       *
       * @returns {boolean}
       */
      canSubmit() {
        if (this.loading) {
          return false;
        }

        return this.currentProduct && this.isRateEntryValid;
      },
      rateGuarantee: {
        /**
         * For stop loss products, we ignore the rateGuarantee.
         * Mapped to the rateEntry.rateGuarantee.
         *
         * @returns {string}
         */
        get() {
          return this.currentProduct && !this.isStopLoss
            ? this.rateEntryRateGuarantee?.value
            : null;
        },
        /**
         * Fires `patchRateGuarantee` action to update the rate guarantee in the store.
         *
         * @param {string} value
         */
        set(value) {
          if (value !== this.rateGuarantee) {
            this.updateRateGuarantee({ value });
          }
        },
      },

      /**
       * Dynamic array of all the values to test if continue button is disabled.
       *
       * @returns {Array}
       */
      rateValues() {
        if (this.loading) {
          return undefined;
        }
        const rateValues = {};

        Object.keys(this.rateAttributes).forEach((attributeId) => {
          Object.keys(this.rateAttributes[attributeId].rateValues).forEach((subtypeId) => {
            this.rateAttributes[attributeId].rateValues[subtypeId].values.forEach((value) => {
              rateValues[`${attributeId}_${subtypeId}_${value.label}`] = value.value === 0
                ? 0
                : value.value || null; // empty strings should be converted to null
            });
          });
        });

        return Object.values(rateValues);
      },

      /**
       * Only show the Next Error button if the product is not in a Not Started or Not Submitted state.
       * In that case, only show it when there are remaining errors.
       *
       * @returns {boolean}
       */
      showNextErrorButton() {
        const stateCheck = ['not_started', 'in_progress_modifying', 'in_progress'];

        if (stateCheck.includes(this.currentProduct.state)) {
          return false;
        }

        return !this.isRateEntryValid;
      },
      /**
       * Will show finished button.
       *
       * @returns {boolean}
       */
      showFinishedButton() {
        const roleMatch = /threeflow_reviewer|admin/i.test(this.roleNames);

        return this.isSmartProposal && roleMatch && this.currentProduct.state === 'threeflow_assist';
      },
    },
    watch: {
      rateErrors() {
        if (this.rateErrors.length) {
          this.currentError = this.currentErrorIndex();
        }
      },
    },
    /**
     * get rate entry
     * set any warning messages
     * set destroyProductId
     */
    created() {
      // If the product is in a completed state and they haven't dismissed the alert.
      if (this.currentProduct.state === 'completed' && !this.reviewAlertDismissed) {
        this.displayAlertReview = true;
        this.warningMessage = 'Your quote is hidden from brokers while you make edits. Click Submit to make your quote visible to them.';
      }

      // destroyProductId needs to be set as the productId to be used in the beforeDestroy lifecycle.
      this.destroyProductId = this.productId;

      this.setProductsFiltersSelected([+this.$route.params.productId]);

      /**
       * Wait for rate errors to be populated, do some stuff, then destroy the watcher.
       * We only want this to fire once, on load, but not immediately. Only once the rate errors are loaded.
       */
      const unwatch = this.$watch('rateErrors', () => {
        if (this.rateErrors.length > 0) {
          // Focus on the first error field
          this.nextError();
          document.querySelectorAll('input, textarea').forEach(
            (field) => {
              // When the user focuses a field we need to store which element in rateErrors the field is.
              field.addEventListener('focus', () => {
                this.currentError = this.currentErrorIndex();
              });
              // When the field is blurred, we need to wait a moment for the click handler to (potentially) run
              // then re-set the current error field index. This mostly handles setting the currentError value
              // back to 0 if the user clicks out of the field rather than clicking Next or Back
              field.addEventListener('blur', () => {
                this.fieldSwitchTimer = setTimeout(() => {
                  this.$nextTick(() => {
                    this.currentError = this.currentErrorIndex();
                  });
                }, 200);
              });
            },
          );
          unwatch();
        }
      });
    },
    /**
     * validate rate entry then clear it
     */
    async beforeDestroy() {
      // Validate the Rate Entry on destroy instead of covering every page exit
      // Only do this when clicking on continue or if they've filled out the form
      // and then left the page w/o clicking continue. This is sort of edge casey but want to cover it.
      if (this.canSubmit && !this.validRateEntry) {
        try {
          await this.validateRateEntry(this.destroyProductId);
        } catch (e) {
        // We are ignoring any error state here b/c we don't want to show anything
        // But we want to avoid uncaught promises
        }
      }

      // If a user leaves any rate entry values blank before moving back to plan design, we want to
      // make sure the edit state is reset back to false as an empty value leaves the edit state as true
      this.editingRateAttributeValue = false;
      this.rateErrors = [];
    },
    methods: {
      ...mapActions(useQuoteEditsStore, ['setProductsFiltersSelected']),
      ...mapActions(useRateEntryStore, [
        'getMissingRateAttributes',
        'clearRateEntry',
        'validateRateEntry',
        'patchRateGuarantee',
      ]),
      ...mapActions(useProductStore, [
        'validateProductAndSubmitQuote',
      ]),
      /**
       * Sends your quotes analytics call.
       */
      sendYourQuotesAnalytics() {
        trackSegmentEvent(`Smart ${this.smartDocType(true)} back to Your Quotes`, { project_id: this.$route.params.projectId });
        this.$router.push({ name: 'RfpOverview' });
      },
      /**
       * Index of the currently focused error field
       *
       * @returns {number}
       */
      currentErrorIndex() {
        return this.rateErrors.findIndex((field) => field().contains(document.activeElement));
      },
      /**
       * Advance focus to the next invalid field.
       */
      nextError() {
        // If the user clicked the next button we can kill the timeout
        // since we're going to set the value ourselves at the end of this method.
        clearTimeout(this.fieldSwitchTimer);
        if (!this.isStopLoss && !this.rateGuarantee && !this.rateErrors.length) {
          this.$refs.rateGuarantee.$el.querySelector('textarea').focus();
        } else if (this.currentError >= this.rateErrors.length - 1 && !this.rateGuarantee && !this.isStopLoss) {
          this.$refs.rateGuarantee.$el.querySelector('textarea').focus();
        } else if (this.rateErrors.length) {
          const index = this.currentError >= this.rateErrors.length - 1 ? 0 : this.currentError + 1;

          this.rateErrors[index]().focus();
        }
        // Save the current error field index
        this.currentError = this.currentErrorIndex();
      },
      /**
       * We need to pulse until the rate guarantee has patched successfully
       * If the Rate Guarantee is still getting patched, then we put up a fake loader until done.
       */
      onSubmitQuote() {
        const checkPatching = () => {
          setTimeout(() => {
            if (this.patchingGuarantee || this.editingRateAttributeValue || !this.canSubmit) {
              this.fauxLoading = true;
              checkPatching();
            } else {
              this.handleSubmitQuote();
            }
          }, 200);
        };

        checkPatching();
      },
      /**
       * handle 'Finish Later' button click
       */
      onFinishLaterClick() {
        this.$router.push({ name: 'RfpOverview' });
      },
      /**
       * Validate Both Plan Design && Rate Entry before submitting the form
       */
      async handleSubmitQuote() {
        // disable the submit button to prevent double clicking
        this.validatingProduct = true;

        try {
          await this.validateProductAndSubmitQuote(this.productId);
        } catch {
          this.validateError();

          return;
        }
        const quoteOrRenewal = this.isSmartProposal ? 'Proposal' : 'Renewal';

        trackSegmentEvent(`Submit Smart ${quoteOrRenewal} from footer clicked`, {
          product_id: this.productId,
          product_type: this.currentProduct.product_type_name,
          carrier_name: this.carrierName,
          carrier_id: this.carrierId,
          project_id: this.projectId,
          proposal_id: this.proposalDocumentId,
        });

        this.$router.push({ name: 'RfpOverview' });
      },
      /**
       * Puts page in state to show error and activate Submit button on validation error for resubmit
       */
      validateError() {
        this.validatingProduct = false;
        this.$message({
          showClose: true,
          message: 'Your request did not go through. Please check the Rate Entry values for any errors.',
          type: 'error',
        });
      },
      /**
       * Updates the store value
       *
       * @param {object} root0
       * @param {string} root0.value
       */
      updateRateGuarantee({ value }) {
        // Debounce value updating and save
        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
          this.rateEntryRateGuarantee.value = value;
          this.validRateEntry = false;

          this.patchRateGuarantee({
            productId: this.productId,
            productState: this.productState,
          });
        }, 200);
      },
    },
  };
</script>

<style lang="scss" scoped>
footer.renewal-footer {
  background: var(--tf-gray-dark);
  color: var(--tf-white);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
  // space for Intercom icon.
  padding-right: 90px;

  > div {
    flex: 0;
  }

  .app-button {
    background: rgba(255, 255, 255, .2);
    border-color: var(--tf-white);
    border-width: 1px;
  }

  /**
   * TODO: This should be moved to shared. AppButton doesn't currently support icons on the left.
   */

  .icon-left {
    flex-direction: row-reverse;

    :deep(.button-text) {
      padding: 0 0 0 6px;
    }
  }

  .stepper-helptext {
    text-transform: uppercase;
    border-left: 1px solid var(--tf-white);
    border-right: 1px solid var(--tf-white);
    margin-left: 16px;
    margin-right: 16px;
    flex: 1;
  }

  .submit-button {
    border-color: #a3a5f9;
    background: rgba(163, 165, 249, .2);

    :deep(.app-icon) {
      color: #a3a5f9;
    }
  }
}

#link-return-to-plan {
  display: flex;
  flex: 1;
  justify-content: flex-start;
}

#rate-guarantee-container {
  > div {
    width: 100%;
    max-width: 450px;
    margin: 30px auto;
  }

  h3 {
    margin-bottom: 5px;
  }

  /* stylelint-disable selector-max-compound-selectors */
  :deep() {
    .el-form-item.is-error {
      .el-form-item__label {
        color: var(--tf-gray-dark);
      }

      .el-textarea__inner {
        border-color: var(--tf-warning);
      }
    }
  }
  /* stylelint-enable selector-max-compound-selectors */
}
</style>
