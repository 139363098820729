<template>
  <header>
    <RouterLink
      :to="breadcrumb"
      data-test="to carrier management"
    >
      <AppButton
        :text="breadcrumbText"
        icon="fa-solid fa-chevron-left"
        size="text"
        prepend
      />
    </RouterLink>
    <h1>
      <slot />
    </h1>
  </header>
</template>

<script>
/**
 * Header Component for Management pages
 *
 * @exports Management/Header
 */
  export default {
    name: 'ManagementHeader',
    props: {
      breadcrumb: {
        type: Object,
        default: () => ({}),
      },
      breadcrumbText: {
        type: String,
        default: null,
      },
    },
  };
</script>

<style scoped>
  header {
    margin-bottom: 20px;
  }
</style>
