<template>
  <div class="quote-submission">
    <ThreeflowWordmark class="wordmark" />
    <div class="content">
      <div
        v-if="isLoading"
        v-loading="isLoading"
        element-loading-text="Loading…"
      />
      <template v-else>
        <AppAlert
          v-if="!isLoading"
          class="summary-alert"
          v-bind="{
            closable: false,
            'custom-icon': customIcon,
            'show-icon': true,
            'is-rounded': true,
            'icon-size': '5x',
            title: alertTitle,
            description: '',
            type: alertType,
          }"
        />

        <div class="copy">
          <template v-if="isError">
            <p>
              An error occurred while submitting your {{ quoteTypeLabel }}.<br>
              <router-link :to="{ name: 'SignIn' }">
                Sign in
              </router-link>
              to review, edit, or check the status of this {{ quoteTypeLabel }}.
            </p>
          </template>
          <template v-else-if="!isPreviouslyCompleted">
            <p>
              Your quotes have been submitted to the broker.<br>
              You’ll receive an email shortly as confirmation.
            </p>
            <p>Feel free to close this window.</p>
          </template>
          <template v-else>
            <p>
              Your quotes have already been submitted to the broker.<br>
              <router-link :to="{ name: 'SignIn' }">
                Sign in
              </router-link>
              to review, edit, or check the status of this {{ quoteTypeLabel }}.
            </p>
            <p>Otherwise, feel free to close this window.</p>
          </template>
        </div>
      </template>
    </div>
    <AuthPageFooter />
  </div>
</template>

<script>
  import { submitQuote } from '@/services/submission.js';

  /**
   * Quote Submission
   *
   * @exports QuoteSubmission
   */
  export default {
    name: 'QuoteSubmission',
    props: {
      quoteType: {
        type: String,
        required: true,
      },
      token: {
        type: String,
        required: true,
      },
    },
    data: () => ({
      isError: false,
      isLoading: true,
      isPreviouslyCompleted: false,
    }),
    computed: {
      /**
       * Smart quote type (proposal or renewal) derrived from the mmatched route name.
       * Workaround because we say 'quote' for 'proposal' in the modal.
       *
       * @returns {string}
       */
      quoteTypeLabel() {
        return this.quoteType === 'renewal' ? 'renewal' : 'quote';
      },
      /**
       * Alert title
       *
       * @returns {string}
       */
      alertTitle() {
        if (this.isError) return 'Something went wrong';

        return `Your ${this.quoteTypeLabel}s have ${this.isPreviouslyCompleted ? 'already ' : ''} been submitted`;
      },
      /**
       * Alert type
       *
       * @returns {string}
       */
      alertType() {
        return this.isError ? 'danger' : 'success';
      },
      /**
       * Custom icon
       *
       * @returns {string|null}
       */
      customIcon() {
        return this.isError ? null : 'fa-kit fa-tf-ai-magic';
      },
    },
    async created() {
      try {
        /* eslint-disable camelcase */
        const { previously_completed } = await submitQuote(this.token, this.quoteType);

        this.isLoading = false;
        this.isError = false;
        this.isPreviouslyCompleted = previously_completed;
        /* eslint-enable camelcase */
      } catch (error) {
        this.isLoading = false;
        this.isError = true;
      }
    },
  };
</script>

<style
  lang="scss"
  scoped
>
  .quote-submission {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .wordmark {
    position: fixed;
    top: 60px;
    left: 60px;
    width: 220px;

    svg {
      width: 100%;
    }
  }

  :deep(.app-alert) {
    /* stylelint-disable declaration-no-important  */
    margin: 0 !important;
    /* stylelint-enable declaration-no-important  */

    max-width: 100%;
    text-align: center;

    .title {
      font-size: 24px;
      text-transform: uppercase;
      font-weight: normal;
      margin-right: 20px;
    }
  }

  .copy {
    margin-top: 30px;

    p {
      margin: 0 auto 40px;
      padding-left: 90px;
      max-width: calc(100% - 90px);
      font-size: 20px;
      line-height: 30px;
      text-align: left;
    }
  }

  :deep(footer) {
    .tf-button {
      display: inline-block;
      font-size: inherit;
    }
  }
</style>
