<template>
  <section>
    <AppRegisterConfirmation
      v-bind="{
        ...registerProps,
        userInfo
      }"
    />
  </section>
</template>

<script>
  import { mapState } from 'pinia';
  import { useAccountStore } from '@/stores/account.js';
  /**
   * Register Confirmation Page
   *
   * @exports Authentication/RegisterConfirmation
   */
  export default {
    name: 'RegisterConfirmation',
    computed: {
      ...mapState(useAccountStore, ['userInfo']),
      /**
       * Evaluate the route params and returns an object with all of the correct data.
       *
       * @returns {object}
       */
      registerProps() {
        const { projectId } = this.$route.params;
        const registerProps = {
          linkLogin: { name: 'SignIn' },
          linkRegister: { name: 'RegistrationForm' },
          linkResend: { name: 'ResendConfirmation' },
        };

        if (projectId) {
          Object.keys(registerProps).forEach((prop) => {
            registerProps[prop] = {
              name: `Project${registerProps[prop].name}`,
              params: { projectId },
            };
          });
        }

        return registerProps;
      },
    },
  };
</script>
