<template>
  <div class="table-display-options">
    <div>
      <h4><slot name="title" /></h4>
      <AppButton
        v-if="!isPlanSummary"
        icon="fa-solid fa-clock"
        size="text"
        text="View quote edits"
        data-test="view quote edits"
        @click="openQuoteEdits"
      />
    </div>
    <ElForm
      ref="form"
      label-width="100px"
      label-position="right"
    >
      <slot name="formElements" />
      <template v-if="!isPlanSummary">
        <template v-if="!readonly">
          <slot name="highlightSource" />
          <!-- Slot for the Copy From for Stop Loss products -->
          <slot name="copyFrom" />
          <!-- Show deviation checkbox is a slot so it's custom. -->
          <ElFormItem
            :class="{
              'has-source-dropdown': isSmartProposal
            }"
          >
            <slot name="showDeviations" />
          </ElFormItem>
        </template>
        <span
          v-else
          class="edit-values-btn-container"
        >
          <slot name="btnEdit" />
        </span>
      </template>
    </ElForm>
  </div>
</template>

<script>
  import { useQuoteEditsStore } from '@/stores/quoteEdits.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductStore } from '@/stores/product.js';
  import { mapState, mapActions } from 'pinia';
  /**
   * Table Header
   *
   * @exports DataEntry/TableHeader
   */
  export default {
    name: 'TableHeader',
    inject: [
      'isPlanSummary',
    ],
    props: {
      /**
       * Determines if Edit Button or Highlight Deviations checkbox should show.
       */
      readonly: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState(useProjectStore, [
        'projectId',
        'employerName',
        'proposalDocumentId',
      ]),
      ...mapState(useProductStore, [
        'productId',
        'isSmartProposal',
      ]),
    },
    methods: {
      ...mapActions(useQuoteEditsStore, ['setProductsFiltersSelected']),
      /**
       * opens the quote edits page
       */
      openQuoteEdits() {
        this.setProductsFiltersSelected([this.productId]);

        // regex match rate or plan in route name
        const currentPage = this.$route.name.toLowerCase().match(/rate|plan/)[0];
        const baseUrl = `/project/${this.projectId}/product/${this.productId}/${currentPage}`;
        const quoteEditsUrl = `${baseUrl}${this.readonly ? '/review' : ''}/quote-edits`;

        this.$router.push(quoteEditsUrl);
      },
    },
  };
</script>

<style lang="scss" scoped>
.table-display-options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 30px;

  div:last-child {
    text-align: right;
  }

  h4,
  .edit-values-btn-container,
  .el-form-item {
    margin-bottom: 15px;
  }

  h4 {
    .dialog-plan-summary & {
      font-size: 14px;
    }
  }

  .dialog-plan-summary & {
    flex-direction: column;
    margin: 0 0 30px;
  }
}

.edit-values-btn-container {
  display: flex;
  justify-content: flex-end;
  min-height: 18px;
}

// stylelint-disable-next-line selector-max-compound-selectors
:deep(.el-select .el-input.el-input--medium .el-input__inner) {
  // without this the Rate Options dropdown doesn't line up....b/c....things
  // stylelint-disable-next-line declaration-no-important
  padding-right: 30px !important;
  margin-right: 12px;
}

.has-source-dropdown {
  :deep(.el-form-item__content) {
    /* Needs !important to override higher specificity from ElFormItem */
    // stylelint-disable-next-line declaration-no-important
    margin-left: 30px !important;
    transform: translateY(25px);
  }
}
</style>
