<template>
  <ElDialog
    title="Decline Products"
    :visible="visible"
    :before-close="onBeforeClose"
    data-test="start or update renewal dialog"
    top="70px"
    width="620px"
  >
    <div>
      <h3
        data-test="decline header"
        class="inner-header"
      >
        Select products to decline
      </h3>
      <div id="product-list">
        <h3
          data-test="decline products header"
        >
          Products
        </h3>
        <ul data-test="product list">
          <ElCheckboxGroup v-model="selectedProducts">
            <li
              v-for="product in products"
              :key="product.id"
            >
              <ElCheckbox
                :value="selectedProducts.includes(product.id)"
                :label="product.id"
                :data-test="`${productLabel(product)} checkbox`"
                @change="triggerProductSelectionAnalyticsEvent($event, product.id)"
              >
                {{ productLabel(product) }}
              </ElCheckbox>
            </li>
          </ElCheckboxGroup>
        </ul>
      </div>
      <AppButton
        class="select-all-cta"
        size="text"
        data-test="select all products to decline"
        text="Select all"
        @click="selectAllProducts"
      />
      <div class="btn-group">
        <AppButton
          :is-disabled="noProductsSelected"
          icon="fa-solid fa-arrow-circle-right"
          size="large"
          data-test="confirm declined products selected"
          @click="handleNextStep"
        >
          Next
        </AppButton>
      </div>
    </div>
  </ElDialog>
</template>

<script>
  import { mapWritableState } from 'pinia';
  import project from '@/mixins/project.js';
  import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
  import { useProductStore } from '@/stores/product.js';
  import { productLabel } from '@/utils/product.js';

  /**
   * Start or update renewal modal dialog. Conditionally displays Rate Pass/Rate Guarantee form or Upload Renewal documents form.
   *
   * @exports Modals/SelectDeclineModal
   */
  export default {
    name: 'SelectDeclineModal',
    mixins: [project],
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      products: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      selectedProducts: [],
    }),
    computed: {
      ...mapWritableState(useProductStore, ['productsToDecline']),

      /**
       * Evaluate if any products are selected in step one.
       *
       * @returns {boolean}
       */
      noProductsSelected() {
        return this.selectedProducts.length === 0;
      },
    },
    methods: {
      productLabel,
      /**
       * Move the modal to the next step, either step three if no products selected or which opens the
       * "Rate Guarantees" template and closes the "Select Products" template. Then trigger an analytics call.
       */
      handleNextStep() {
        this.productsToDecline = this.products.filter(({ id }) => this.selectedProducts.includes(id));
        this.selectedProducts = [];
        this.$emit('closeDialog');
      },
      /**
       * If you click the 'x' make sure the dialog closes.
       *
       * @param {Function} done
       */
      onBeforeClose(done) {
        this.$emit('closeDialog');
        done();
      },
      /**
       * Selects all listed products.
       */
      selectAllProducts() {
        this.selectedProducts = this.products.map(({ id }) => id);
      },
      /**
       * Trigger an analytics call if the product is selected in the "Select Products" modal.
       *
       * @param {boolean} checked
       */
      triggerProductSelectionAnalyticsEvent(checked) {
        if (checked) {
          trackSegmentEvent('Product selected from rate pass', {
            product_ids: this.selectedProducts,
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
:deep(.el-icon-close:before) {
  color: var(--tf-blue);
}

:deep(.el-dialog__body) {
  padding: 0 40px;
}

.inner-header {
  margin-top: 32px;
}

#product-list {
  border: 1px solid var(--tf-gray-medium);
  margin-bottom: 8px;

  h3 {
    padding: 8px 16px;
    border-bottom: 1px solid var(--tf-gray-medium);
    margin-bottom: 0;
    background: var(--tf-gray-light);
  }

  ul {
    li {
      padding: 8px 16px;
      border-bottom: 1px solid var(--tf-gray-medium);

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.btn-group {
  margin: 16px 0 24px;
}
</style>
