/**
 * Mixin methods for RFP overview
 *
 * @exports Mixins/RfpOverview
 */
// pinia
import { mapState } from 'pinia';
import { useNotificationsStore } from '@/stores/notifications.js';
import { useBrokerUpdatesStore } from '@/stores/brokerUpdates.js';
import { useCommentsStore } from '@/stores/comments.js';
import { useProjectStore } from '@/stores/project.js';
import { hideMarketActivityTab } from '@/utils/featureFlags.js';

export default {
  computed: {
    ...mapState(useCommentsStore, ['unreadComments']),
    ...mapState(useNotificationsStore, ['unreadEdits']),
    ...mapState(useBrokerUpdatesStore, ['notifications']),
    ...mapState(useProjectStore, ['broker', 'brokerHierarchy']),
    /**
     * If the broker either IS a broker parent or HAS a broker parent
     * that is deemed restricted to see the Market Activity tab, this will return true.
     * The feature flag consists of a comma separated string of parent broker IDs, or
     * 'none' if the flag is disabled.
     *
     * @returns {boolean}
     */
    hideMarketTab() {
      const idsAsString = this.$ld.checkFlags(hideMarketActivityTab);

      if (idsAsString === 'none' || !this.brokerHierarchy.hasHierarchy) {
        return false;
      }

      // If the broker has a hierarchy but no parent_id, then the broker IS the parent of its hierarchy.
      const hierarchyParentId = this.brokerHierarchy.parentId ?? this.broker.id;
      // Convert the comma separated string of broker ids into an array of numbers.
      const restrictedParentIds = idsAsString
        .replace(/\s/, '')
        .split(',')
        .map((id) => +id);

      return restrictedParentIds.includes(hierarchyParentId);
    },
    /**
     * Evaluates the RfpOverview route's children and creates an item with badgeCount, name, path, and title for each
     * that is primarily used for navigation.
     *
     * @returns {Array}
     */
    rfpOverviewTabs() {
      const { routes } = this.$router.options;
      const index = routes.findIndex(({ name }) => name === 'RfpOverview');
      const tabs = routes[index].children
        .map(({ meta, name, path }) => {
          const { title } = meta;
          let badgeCount;

          if (
            name === 'UpdatesEdits'
            && (this.notifications || this.unreadEdits)
          ) {
            badgeCount = this.notifications + this.unreadEdits;
          }

          if (name === 'Comments') {
            badgeCount = this.unreadComments;
          }

          return {
            badgeCount,
            name,
            path,
            title,
            id: name,
            label: title,
          };
        });

      return this.hideMarketTab
        ? tabs.filter(({ name }) => name !== 'MarketActivity')
        : tabs;
    },
  },
};
