<template>
  <div class="document-category">
    <h4
      v-if="title"
      v-text="title"
    />
    <slot>
      <ul>
        <DocumentManagerItem
          v-for="file in fileList"
          :key="file.id"
          :file="file"
          :show-button-label="true"
          application-namespace="carrier_portal"
          @downloaded="$emit('downloaded', { title, name: file.name })"
        />
      </ul>
    </slot>
  </div>
</template>

<script>
  /**
   * RFP Document Category
   *
   * @exports RFPDocuments/RfpDocumentCategory
   */
  export default {
    name: 'RfpDocumentCategory',
    components: {
      DocumentManagerItem: () => import('@/components/DocumentManagerItem.vue'),
    },
    props: {
      fileList: {
        type: Array,
        default: () => [],
      },
      title: {
        type: String,
        default: null,
      },
    },
  };
</script>

<style lang="scss" scoped>
  h4 {
    font-size: 16px;
  }

  .document-category {
    padding-bottom: 25px;
  }
</style>
