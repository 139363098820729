import { ReusableData } from '@watchtowerbenefits/shared-components';

export const TWENTY_MB = 20971520;

/**
 * Checks the file extension/type given against the accepted extensions and filetypes array,
 * and returns true if a match is found.
 *
 * @param {object} acceptanceCriteria - the file to check and the extensions and filetypes
 * to check the file against
 * @param {object} acceptanceCriteria.file - the file to check
 * @param {Array<string>} acceptanceCriteria.acceptedExtensions
 * @param {Array<string>} acceptanceCriteria.acceptedFileTypes
 * @returns {boolean} the boolean result of the comparison
 */
export const isValidExtension = ({
  file,
  acceptedExtensions = ReusableData.getAcceptableUploadFileExtensions(),
  acceptedFileTypes = ReusableData.getAcceptableUploadFileTypes(),
}) => {
  const fileExtension = file.name.split('.').pop();
  const fileType = file.raw && file.raw.fileType;

  return (fileType && acceptedFileTypes.includes(fileType))
    || acceptedExtensions.includes(fileExtension);
};
/**
 * Checks the size of the file given against the accepted fileSize,
 * and returns true if size is less than or equal to fileSize.
 * defaults to 20mb
 *
 * @param {object} file - the file to check
 * @param {number} file.size - the size of the file
 * @param {number} maxSize - the file size limit
 * @returns {boolean} the boolean result of the comparison
 */
export const isValidFileSize = ({ size }, maxSize = TWENTY_MB) => size <= maxSize;
/**
 * Flattens an objects values into an array
 *
 * @param {object} object - vallues to flatten
 * @returns {Array} flattened values
 */
export const flattenObjectValues = (object) => [].concat(...Object.values(object));
