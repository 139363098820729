import Vue from 'vue';
import { defineStore } from 'pinia';
import { CalloutsService } from '@watchtowerbenefits/shared-components';
import { config } from '@/utils/config.js';

const {
  readCallouts,
  createCallout,
  updateCallout,
  patchCalloutRead,
  deleteCallout,
} = CalloutsService;
const apiConfig = {
  baseUrl: config.VUE_APP_API_URL,
  apiNamespace: config.VUE_APP_API_NAMESPACE,
  cookieNamespace: config.VUE_APP_COOKIE_NAMESPACE,
};

export const useCallouts = defineStore('callouts', {
  state: () => ({
    callouts: [],
    calloutMap: {},
    isLoaded: false,
  }),

  getters: {
    getSingleCallout: (state) => (pdvId) => state.callouts.find(
      (callout) => callout.plan_design_value_id === pdvId,
    ),
  },

  actions: {
    /**
     * Resets the callout store.
     *
     * @returns {void}
     */
    resetCallouts() {
      this.$reset();
    },
    /**
     * rff: benefitCalloutComments
     * Load all callouts for the provided product ids. If useMap is true, callouts
     * will be stored in a map by plan design value id. useMap is a feature flag of sorts
     * in order to keep the existing callouts store working as is.
     *
     * @param {Array<number>} productIds
     * @param {boolean} useMap - if true, callouts will be stored in a map by plan design value id.
     * @returns {Promise<Array|object>} Resolves to an array of callouts or a map of callouts by plan design value id.
     */
    async loadCallouts(productIds, useMap) {
      this.$reset();

      try {
        // eslint-disable-next-line no-restricted-syntax
        for await (const productId of productIds) {
          if (useMap) {
            this.calloutMap = { ...this.calloutMap, ...await readCallouts(productId, apiConfig) };
          } else {
            this.callouts = [...this.callouts, ...await readCallouts(productId, apiConfig)];
          }
        }
      } catch (error) {
        throw new Error('There was an error when loading callouts.');
      } finally {
        this.isLoaded = true;
      }

      return useMap ? this.calloutMap : this.callouts;
    },
    /**
     * rff: benefitCalloutComments
     * Create a new callout and add it to the store. If useMap is true, callouts
     * will be stored in a map by plan design value id. useMap is a feature flag of sorts
     *
     * @param {number} productId
     * @param {number} planDesignValueId
     * @param {string} note
     * @param {boolean} useMap - If true, callouts will be stored in a map by plan design value id.
     */
    async addCallout(productId, planDesignValueId, note, useMap) {
      try {
        const newCallout = await createCallout(productId, planDesignValueId, note, apiConfig);

        if (useMap) {
          if (!this.calloutMap[newCallout.plan_design_value_id]) {
            Vue.set(this.calloutMap, newCallout.plan_design_value_id, []);
          }

          this.calloutMap[newCallout.plan_design_value_id].push(newCallout);
        } else {
          this.callouts.push(newCallout);
        }
      } catch {
        throw new Error('There was an error when creating a callout.');
      }
    },
    /**
     * edit the note of a single callout
     *
     * @param {number} productId
     * @param {number} calloutId
     * @param {string} note
     */
    async editCallout(productId, calloutId, note) {
      const updatedCallout = await updateCallout(productId, calloutId, note, apiConfig);
      const calloutIndex = this.callouts.findIndex((callout) => callout.id === updatedCallout.id);

      Vue.set(this.callouts, calloutIndex, updatedCallout);
    },
    /**
     * delete a single callout
     *
     * @param {number} productId
     * @param {number} calloutId
     */
    async removeCallout(productId, calloutId) {
      await deleteCallout(productId, calloutId, apiConfig);
      const calloutIndex = this.callouts.findIndex((callout) => callout.id === calloutId);

      Vue.delete(this.callouts, calloutIndex);
    },
    /**
     * Mark a callout as read in the API and update the callout in the store.
     *
     * @param {number} calloutId
     * @param {number} planDesignValueId - if provided, the callout will be updated in the calloutMap.
     */
    async markCalloutAsRead(calloutId, planDesignValueId) {
      const updatedCallout = await patchCalloutRead(calloutId, apiConfig);

      if (planDesignValueId !== undefined) {
        const calloutIndex = this.calloutMap[planDesignValueId]
          .findIndex(({ id }) => id === calloutId);

        Vue.set(this.calloutMap[planDesignValueId], calloutIndex, updatedCallout);
      } else {
        const calloutIndex = this.callouts
          .findIndex(({ id }) => id === calloutId);

        Vue.set(this.callouts, calloutIndex, updatedCallout);
      }
    },
  },
});

export default {
  useCallouts,
};
