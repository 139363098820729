import Vue from 'vue';
import { defineStore } from 'pinia';
// Services
import AccountService from '@/services/account.js';

export const useUserPreferredPdvsStore = defineStore('userPreferredPdvs', {
  state: () => ({
    userPreferredPlanDesignValues: {},
    productsWithPreferredPdvs: [],
  }),

  actions: {
    /**
     * Reset the state
     */
    resetUserPreferredPdvs() {
      this.$reset();
    },
    /**
     * Adds or edits the object on the current project that are different
     * from the in-force or company defaults of a particular pdv for the user to use as a
     * predefined value the next time they are on a plan of this type (does not save them)
     *
     * @param {object} updatedPdv
     */
    editUserPreferredPdv(updatedPdv) {
      Vue.set(this.userPreferredPlanDesignValues, updatedPdv.plan_design_attribute_id, updatedPdv);
    },
    /**
     * Saves the users changed plan design values as their defaults
     * for future projects of the same type
     *
     * @param {number} productTypeId
     * @param {object} userPreferredPlanDesignValues
     * @returns {Promise}
     */
    savePreferredPlanDesignValues(productTypeId, userPreferredPlanDesignValues) {
      return AccountService
        .saveUserPredefinedPlanDesignValues(productTypeId, userPreferredPlanDesignValues)
        .then(() => {
          this.resetUserPreferredPdvs();
        });
    },
  },
});

export default {
  useUserPreferredPdvsStore,
};
