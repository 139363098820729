<template>
  <div class="not-found">
    <h2>Page not found</h2>
    <p>
      The page you are looking for doesn't exist, try going to your
      <RouterLink
        :to="{ name: 'Dashboard' }"
      >
        Dashboard
      </RouterLink>.
    </p>
  </div>
</template>

<script>
/**
 * Not Found
 *
 * @exports NotFound
 */
  export default {
    name: 'NotFound',
    data() {
      return {
      };
    },
  };
</script>

<style scoped>
  .not-found {
    padding-top: 20px;
    text-align: center;
  }
</style>
