<!-- eslint-disable vue/no-v-html -->
<template>
  <ElDialog
    :append-to-body="appendToBody"
    :title="isAutoRenewal
      ? 'Upload renewal documents'
      : 'Supplemental documents'"
    data-test="upload renewal dialog"
    :visible="visible"
    class="manage-proposals-dialog"
    top="70px"
    @close="$emit('update:visible', false)"
  >
    <div class="upload-proposal">
      <p
        v-if="isAutoRenewal"
        data-test="auto renewal disclaimer"
      >
        <strong data-test="disclaimer text">
          Once you upload your documents, we'll complete and send the renewal in 2 business days.
        </strong>
        We recommend working with your broker partner to verify final renewal terms.
      </p>
      <template v-else>
        <h4>
          Upload documents
        </h4>
        <p>
          Benefits and provisions are not populated directly from your uploaded proposal.
          Your documents will simply be a reference as you enter plan information.
        </p>
      </template>

      <AppAlert
        v-if="uploadError"
        :closable="false"
        class="upload-failure-disclaimer"
        type="danger"
        show-icon
      >
        {{ uploadError }}
        <AppButton
          class="select-new-file"
          text="Select a new file"
          size="text"
        />
        and try again. If the problem persists, please <TfCtaEmailSupport />.
      </AppAlert>

      <ElUpload
        ref="upload"
        data-test="drag and drop files"
        :action="`${apiUrl}/v1/carrier_portal/documents/${proposalDocumentId}/upload_sources`"
        :headers="{'X-API-AUTHTOKEN': `${authToken}`}"
        :on-success="onUploadSuccess"
        :before-upload="onBeforeUpload"
        :on-error="onUploadError"
        :on-progress="onProgressUpdate"
        :accept="acceptableFileExtensions.join(',')"
        :show-file-list="false"
        drag
        name="files[]"
        multiple
      >
        <img
          class="el-icon-upload"
          :src="uploadFilesIcon"
          alt="No proposals icon"
        >
        <div class="el-upload__text">
          Drop file here <br> or <em>click to upload</em>
        </div>
        <div
          slot="tip"
          class="el-upload__tip"
        >
          Accepted file types PDF, DOC, Excel, RTF files. 20MB limit.
        </div>
      </ElUpload>
    </div>

    <div class="manage-documents">
      <div class="manage-documents-heading">
        <h4>Manage documents</h4>
        <AppButton
          v-if="ffDownloadAllSupplementalDocuments"
          :is-loading="isLoading"
          data-test="download all proposal documents"
          icon="fa-solid fa-arrow-down-to-line"
          size="text"
          text="Download all"
          @click="downloadAllDocuments"
        />
      </div>
      <p v-if="clonedProposals && !clonedProposals.length && !Object.keys(uploadingFiles).length">
        No documents uploaded.
      </p>
      <ul
        v-else
        class="file-list"
        data-test="file list"
      >
        <li
          v-for="file in uploadingFiles"
          :key="file.uploadingFile.uid"
        >
          <span class="name">
            {{ file.name }}
          </span>
          <ElProgress
            :show-text="false"
            :stroke-width="18"
            :percentage="file.progress"
          />
          <AppButton
            class="cancel-upload-button"
            data-test="cancel file upload"
            icon="fa-solid fa-xmark"
            size="text-small"
            text="Cancel upload"
            type="danger"
            @click="onCancelUploadClick(file)"
          />
        </li>
        <li
          v-for="proposal in clonedProposals"
          :key="proposal.id"
        >
          <span
            v-if="!proposal.editing"
            :data-test="`file ${proposal.name}`"
            class="name"
          >
            {{ proposal.name }}
          </span>
          <span
            v-else
            class="edit-container"
          >
            <form @submit.prevent="onInputEnter(proposal)">
              <ElInput
                v-model="proposal.inputName"
              >
                <template slot="append">.{{ proposal.extension }}</template>
              </ElInput>
            </form>
          </span>
          <div class="btn-group">
            <template v-if="proposal.editing">
              <AppButton
                class="cancel-button"
                data-test="cancel edit filename"
                icon="fa-solid fa-xmark"
                size="text-small"
                text="Cancel changes"
                type="danger"
                @click="onCancelEdit(proposal)"
              />
              <AppButton
                data-test="save filename changes"
                icon="fa-solid fa-check"
                size="text-small"
                text="Save changes"
                type="success"
                @click="onSaveChanges(proposal)"
              />
            </template>
            <template v-else>
              <AppButton
                data-test="edit filename"
                icon="fa-solid fa-pencil"
                size="text-small"
                text="Edit filename"
                @click="onEditClick(proposal)"
              />
              <AppButton
                :data-test="`delete file ${proposal.name}`"
                icon="fa-solid fa-trash-can"
                size="text-small"
                text="Delete file"
                type="danger"
                @click="handleDeleteClick(proposal)"
              />
            </template>
          </div>
        </li>
      </ul>
    </div>

    <div class="done-button-container">
      <template v-if="isAutoRenewal">
        <span id="modal-terms-disclaimer">
          By submitting, you agree to our&nbsp;
          <AppButton
            data-test="open terms modal"
            size="text"
            text="terms"
            @click="showTermsModal = !showTermsModal"
          />
          .
        </span>
        <AppButton
          data-test="click cancel renewal"
          size="text"
          text="Cancel"
          @click="$emit('update:visible', false)"
        />
        <AppButton
          :is-disabled="!proposals.length"
          data-test="submit renewal"
          size="large"
          text="Submit renewal"
          @click="submitRenewal"
        />
      </template>
      <template v-else>
        <AppButton
          data-test="click done"
          size="small"
          text="Done"
          @click="$emit('update:visible', false)"
        />
      </template>
    </div>
    <TermsModal
      v-if="showTermsModal"
      :visible.sync="showTermsModal"
      data-test="terms modal"
      @closeDialog="showTermsModal = false"
    />
  </ElDialog>
</template>

<script>
  import Vue from 'vue';
  import { mapState, mapActions } from 'pinia';
  import { captureException, addBreadcrumb } from '@sentry/vue';
  import { segmentData } from '@/utils/analytics.js';
  import {
    reusableAcceptableUploadFileTypes,
    reusableAcceptableUploadFileExtensions,
    trackSegmentEvent,
    getCookie,
  } from '@watchtowerbenefits/es-utils-public';
  import { config } from '@/utils/config.js';
  import { isValidFileSize } from '@/utils/file.js';
  import Interceptor from '@/services/interceptor.js';
  import ProposalService from '@/services/proposal.js';
  import DocumentService from '@/services/documents.js';
  import uploadFilesIcon from '@/assets/upload-files-icon.svg';
  import { downloadAllSupplementalDocuments } from '@/utils/featureFlags.js';
  import { useAccountStore } from '@/stores/account.js';
  import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
  import { useProjectStore } from '@/stores/project.js';
  import TermsModal from '@/components/Modals/TermsModal.vue';

  const apiUrl = config.VUE_APP_API_URL;
  const authKey = 'X-API-AUTHTOKEN';
  const cookieNameSpace = config.VUE_APP_COOKIE_NAMESPACE;
  const apiConfig = {
    apiUrl,
    cookieNamespace: cookieNameSpace,
  };

  /**
   * Proposals Dialog
   *
   * @exports Modals/ProposalManagerModal
   */
  export default {
    name: 'ProposalManagerModal',
    components: {
      TermsModal,
    },
    props: {
      /**
       * determines if we should show the language regarding the upload
       * and renew workflow in the template or default to regular file uploads
       */
      isAutoRenewal: {
        type: Boolean,
        default: false,
      },
      appendToBody: {
        type: Boolean,
      },
      proposals: {
        type: Array,
        default: () => [],
      },
      visible: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        apiUrl,
        authToken: null,
        clonedProposals: [],
        extensionErrorMessage: 'We do not accept this file type, only PDF, DOC, Excel, or RTF file types.',
        headers: {
          [authKey]: null,
        },
        fileList: [],
        sourceService: this.$TF.SourceService,
        uploadError: '',
        uploadFilesIcon,
        uploadingFiles: {},
        showTermsModal: false,
        isLoading: false,
      };
    },

    computed: {
      ...mapState(useCarrierInfoStore, { carrierId: 'id' }),
      ...mapState(useAccountStore, ['userInfo']),
      ...mapState(useProjectStore, ['broker', 'employerName', 'proposalDocumentId']),
      /**
       * Check if the user has the download all supplemental documents feature flag enabled
       *
       * @returns {boolean}
       */
      ffDownloadAllSupplementalDocuments() {
        return this.$ld.checkFlags(downloadAllSupplementalDocuments);
      },
      /**
       * Compute an array with both upper and lowercase version of allowed extensions
       *
       * @returns {Array}
       */
      acceptableFileExtensions() {
        return reusableAcceptableUploadFileExtensions().concat(
          reusableAcceptableUploadFileExtensions().map((extension) => extension.toUpperCase()),
        );
      },
    },

    /**
     * create a drag area listener in order to run onDrop function on listener call
     */
    mounted() {
      if (this.checkUploadDragger()) {
        this.$refs.upload.$el
          .querySelector('.el-upload-dragger')
          .addEventListener('drop', this.onDrop);
      }
    },

    /**
     * set current auth token
     * split up the proposal file name by extension for each proposal
     * clear all proposal errors
     */
    created() {
      // grab the cookienamespace and the auth token to pass to the upload component
      this.authToken = getCookie(`${cookieNameSpace}-auth-token`);
      this.headers[authKey] = this.authToken;

      this.clonedProposals = JSON.parse(JSON.stringify(this.proposals)) || [];

      this.clonedProposals.forEach((proposal) => {
        // eslint-disable-next-line no-unused-vars
        const scopedProposal = proposal;

        this.splitProposalFileName(scopedProposal);
      });
    },

    /**
     * remove all listeners and upload errors
     */
    beforeDestroy() {
      // destroy any listeners left on the page
      const newFileLink = this.$el.querySelector('a.select-new-file');

      if (newFileLink) {
        newFileLink.removeEventListener('click', this.clickUploadArea);
      }

      if (this.checkUploadDragger()) {
        this.$refs.upload.$el
          .querySelector('.el-upload-dragger')
          .removeEventListener('drop', this.onDrop);
      }

      this.uploadError = '';
    },

    methods: {
      ...mapActions(useProjectStore, ['updateProposal', 'removeProposal', 'setProposals']),
      /**
       * Checks for .el-upload-dragger
       *
       * @returns {boolean}
       */
      checkUploadDragger() {
        return this.$refs.upload && this.$refs.upload.$el.querySelector('.el-upload-dragger');
      },

      /**
       * Click the upload component to trigger the file navigator
       *
       * @param {Event} event
       */
      clickUploadArea(event) {
        event.preventDefault();
        this.$el.querySelector('.el-upload-dragger').click();
      },

      /**
       * Split the proposals name into two section so we can show the extension seperatly
       *
       * @param {object} proposal
       * @returns {object}
       */
      splitProposalFileName(proposal) {
        const scopedProposal = proposal;

        scopedProposal.editing = false;
        // editing flags whether or not the user is editing the file name
        if (scopedProposal.name) {
          // we split the files name into parts so the user can edit the name but not the extension
          const extensionArray = scopedProposal.name.split('.');

          scopedProposal.extension = extensionArray[extensionArray.length - 1];
          extensionArray.splice(extensionArray.length - 1, 1);
          scopedProposal.inputName = extensionArray.join('.');
        } else {
          scopedProposal.inputName = '';
          scopedProposal.extension = '';
        }

        return scopedProposal;
      },

      /**
       * Manually set the progress attribute so we can update the progress elements on the page
       *
       * @param {object} event
       * @param {object} file
       */
      onProgressUpdate(event, file) {
        let progress = event.percent;

        progress -= (progress * 0.03);
        // show the progress component as %3 less then it really is (this way it doesn't look like it hangs at 100%)
        this.uploadingFiles[file.uid].progress = Math.floor(progress);
      },

      /**
       * Save the name of a file, call the proposal service and update the store
       *
       * @param {object} file
       */
      onSaveChanges(file) {
        const index = this.clonedProposals.findIndex((proposal) => proposal.id === file.id);
        const proposal = JSON.parse(JSON.stringify(this.clonedProposals[index]));

        proposal.name = `${proposal.inputName}.${proposal.extension}`;

        ProposalService.updateProposalName(proposal.id, proposal.name);
        proposal.editing = false;
        this.clonedProposals.splice(index, 1, proposal);
        this.updateProposal(proposal);
      },

      /**
       * Call onSaveChanges if the user hits enter while editing a file name
       *
       * @param {object} file
       */
      onInputEnter(file) {
        this.onSaveChanges(file);
      },

      /**
       * Cancel the editing of a file's name
       *
       * @param {object} file
       */
      onCancelEdit(file) {
        const index = this.clonedProposals.findIndex((proposal) => proposal.id === file.id);
        const proposal = JSON.parse(JSON.stringify(this.clonedProposals[index]));
        const extensionArray = proposal.name.split('.');

        proposal.extension = extensionArray[extensionArray.length - 1];
        extensionArray.splice(extensionArray.length - 1, 1);
        proposal.inputName = extensionArray.join('.');

        proposal.editing = false;
        this.clonedProposals.splice(index, 1, proposal);
      },

      /**
       * Cancel the editing of a file's name
       *
       * @param {object} file
       */
      onEditClick(file) {
        const index = this.clonedProposals.findIndex(({ id }) => id === file.id);
        const scopedFile = file;

        scopedFile.editing = true;
        this.clonedProposals.splice(index, 1, scopedFile);
      },

      /**
       * Cancel an upload in progress
       *
       * @param {object} file
       */
      onCancelUploadClick(file) {
        const ref = this.$refs.upload;

        ref.abort(file.uploadingFile);
        Vue.delete(this.uploadingFiles, file.uploadingFile.uid);
      },
      /**
       * Call the Proposal Service and delete this file from the server then update the store
       *
       * @param {object} file
       * @param {string|number} file.id
       */
      async handleDeleteClick({ id }) {
        const index = this.clonedProposals.findIndex((proposal) => proposal.id === id);

        try {
          await this.sourceService.deleteSource(id, 'carrier_portal', apiConfig);
          this.removeProposal(id);
          this.clonedProposals.splice(index, 1);
        } catch {
          this.$message({
            message: 'There was an error deleting the document. Please try again.',
            type: 'error',
          });
        }
      },

      /**
       * On Upload Success we remove the file from the uploadFiles object and update the proposals array in the store
       *
       * @param {object} response
       * @param {object} file
       */
      onUploadSuccess(response, file) {
        let proposal = response.successfully_uploaded_sources[0];

        proposal = this.splitProposalFileName(proposal);
        this.clonedProposals.unshift(proposal);
        Vue.delete(this.uploadingFiles, file.uid);
        this.setProposals(response.sources);
      },

      /**
       * When a file is dropped, element automatically filters out unacceptable file names
       * So we watch the drop as well and throw errors when necessary
       *
       * @param {Event} e
       */
      onDrop(e) {
        const { files } = e.dataTransfer;

        Object.keys(files).forEach((item) => {
          const file = files[item];
          const extensionArray = file.name.split('.');
          const extension = extensionArray[extensionArray.length - 1];

          if (!isValidFileSize(file)) {
            this.uploadError = 'File too large. Uploads must be 20MB or less.';
            window.setTimeout(() => {
              this.$el.querySelector('a.select-new-file').addEventListener('click', this.clickUploadArea);
            }, 200);
          } else if (file.raw && file.raw.type) {
            if (!reusableAcceptableUploadFileTypes().includes(file.raw.type)) {
              this.uploadError = this.extensionErrorMessage;
            }
          } else if (!this.acceptableFileExtensions.includes(`.${extension}`)) {
            this.uploadError = this.extensionErrorMessage;
          }
        });
      },

      /**
       * If there is an error on upload we do a combination of the following:
       * Sign the user out (on 401), Throw a Sentry Error, Display a generic error message to the user
       *
       * @param {string} msg
       * @param {object} file
       */
      onUploadError(msg, file) {
        const message = 'Upload proposal error';

        if (msg.status === 401) {
          Interceptor.inactiveLogout();
        }

        addBreadcrumb({
          data: {
            msg,
            file,
          },
          level: 'info',
          message,
        });
        captureException(message);

        this.uploadError = 'Upload failed. Please try again.';
      },

      /**
       * Before we send the file to the server we do some checks on the file extension/type and size
       *
       * @param {object} file
       * @returns {boolean}
       */
      onBeforeUpload(file) {
        let valid = false;

        if (this.headers[authKey] !== getCookie(`${cookieNameSpace}-auth-token`)) {
          Interceptor.inactiveLogout();

          return valid;
        }

        const extensionArray = file.name.split('.');
        const extension = extensionArray[extensionArray.length - 1];

        if (!isValidFileSize(file)) {
          this.uploadError = 'File too large. Uploads must be 20MB or less.';

          window.setTimeout(() => {
            this.$el.querySelector('a.select-new-file').addEventListener(
              'click',
              this.clickUploadArea,
            );
          }, 200);
        } else {
          valid = file.raw && file.raw.type
            ? reusableAcceptableUploadFileTypes().includes(file.raw.type)
            : this.acceptableFileExtensions.includes(`.${extension}`);
          this.uploadError = this.extensionErrorMessage;
        }

        if (valid) {
          const { name, uid } = file;
          const fileObject = {
            name,
            uploadingFile: file,
            progress: 0,
          };

          this.uploadError = '';

          this.$set(this.uploadingFiles, uid, fileObject);
          trackSegmentEvent('Upload documents', segmentData());
        }

        return valid;
      },
      async submitRenewal() {
        try {
          await DocumentService.startRenewal(this.proposalDocumentId);

          this.$message({
            message: `
              You've successfully submitted ${this.employerName} to ${this.broker.name} for renewal.
              Your renewal will be ready to review in 2 business days.
            `,
            type: 'success',
          });

          // Send out a segment event in order to track auto-renewals
          // this is the first event of two necessary to track auto-renewals
          trackSegmentEvent('Submit auto-renewal', {
            project_id: +this.$route.params.projectId,
            carrier_id: this.carrierId,
            user_carrier_id: this.userInfo.id,
          });

          this.$router.push({ name: 'Dashboard' });
        } catch {
          this.$message({
            message: 'Renewal submission failed. If the problem persists, please contact support.',
            type: 'error',
          });
        }
      },
      /**
       * Downloads all files that have been uploaded for this project
       *
       */
      async downloadAllDocuments() {
        const filename = `${this.proposalDocumentId}_proposals.zip`;

        this.isLoading = true;
        try {
          const data = await DocumentService.getAllDocumentSources(this.proposalDocumentId);
          const u8 = new Uint8Array(data);
          const blob = new Blob([u8], { type: 'application/zip' });
          const link = document.createElement('a');

          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();
        } catch {
          this.$message({
            showClose: true,
            message: 'There was an error downloading the documents zipfile, please try again.',
            type: 'error',
            duration: 30000,
          });
        } finally {
          this.isLoading = false;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
.upload-proposal {
  padding-bottom: 40px;
  color: var(--tf-gray-dark);

  :deep() {
    .el-upload,
    .el-upload-dragger {
      width: 100%;
    }
  }

  p {
    margin: 15px  0 30px;
  }
}

.upload-failure-disclaimer {
  margin-bottom: 20px !important; // stylelint-disable-line
}

.manage-documents {
  border-bottom: 1px solid var(--tf-gray-light-medium);
  padding-bottom: 16px;
  color: var(--tf-gray-dark);
}

.manage-documents-heading {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--tf-gray-light-medium);
  padding-bottom: 20px;

  h4 {
    margin: 0;
  }
}

.download-arrow {
  margin-left: 7px;
}

li {
  display: flex;
  justify-content: space-between;
  min-height: 26px;
  padding: 16px 0;
  margin: 0;
  border-bottom: 1px dotted var(--tf-gray-light-medium);

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.name {
  flex-grow: 1;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  word-break: break-all;
}

.edit-container {
  flex-grow: 1;
  margin-right: 10px;

  .el-input {
    max-width: 417px;
  }

  @media (max-width: 550px) {
    :deep(.el-input-group__append) {
      display: none;
    }
  }
}

.el-progress {
  width: 137px;
  margin-right: 24px;
  align-self: center;
}
</style>

<style lang="scss">
.manage-proposals-dialog .el-dialog {
  max-width: 771px;
  width: 90%;
  @media (min-width: 600px) {
    width: 100%;
  }

  strong {
    color: var(--tf-blue);
  }

  .done-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
  }

  #modal-terms-disclaimer {
    flex: 1;
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    font-size: 14px;
    margin-right: 70px;
  }
}
</style>
