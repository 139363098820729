export const useBasicSort = (direction, key) => (a, b) => {
  const checkForReverse = (value) => (direction === 'asc' ? value : -value);
  const keyA = a[key];
  const keyB = b[key];

  if (Number.isInteger(+keyA) && Number.isInteger(+keyB)) {
    return checkForReverse(+keyA - +keyB);
  }
  if (keyA.toLowerCase() < keyB.toLowerCase()) {
    return checkForReverse(-1);
  }
  if (keyA.toLowerCase() > keyB.toLowerCase()) {
    return checkForReverse(1);
  }

  return 0;
};

export const useMultiSort = (direction, ...keys) => (a, b) => {
  const localKeys = [...keys];

  while (localKeys.length) {
    const key = localKeys.shift();
    const basicSort = useBasicSort(direction, key);
    const result = basicSort(a, b);

    if (result) return result;
  }

  return 0;
};
