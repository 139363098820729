<template>
  <footer>
    <div class="need-help">
      Need help? <br>
      <a
        data-test="contact support"
        target="_blank"
        href="mailto:support@threeflow.com"
      >Contact support</a>
    </div>
    <div class="requested-by">
      <h3>
        RFP requested by
        <img
          :src="broker.logoUrl"
          :alt="broker.name"
        >
      </h3>
    </div>
  </footer>
</template>

<script>
  import { mapState } from 'pinia';
  import { useProjectStore } from '@/stores/project.js';

  /**
   * RFP Overview Footer
   *
   * @exports RfpOverview/RfpFooter
   */
  export default {
    name: 'RfpOverviewFooter',
    computed: {
      ...mapState(useProjectStore, ['broker']),
    },
  };
</script>

<style lang="scss" scoped>
  footer {
    background: $tf-extra-light-gray-2;
    text-align: center;
    padding: 10px 0;
    border-top: 1px solid #d1dbe5;
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: 650px) {
      flex-direction: row;
    }

    .need-help {
      font-size: 12px;
      width: 25%;
      flex-direction: column;
      align-self: center;
      padding-top: 18px;

      @media (min-width: 650px) {
        padding-left: 33px;
        padding-top: 0;
        text-align: left;
      }
    }
  }

  .requested-by {
    display: flex;
    flex-grow: 1;

    &:after {
      content: '';
      display: block;

      @media (min-width: 650px) {
        width: 25vw;
      }
    }

    h3 {
      font-size: 12px;
      color: #8492a6;
      text-transform: uppercase;
      font-weight: bold;
      flex-grow: 1;
    }

    img {
      display: block;
      height: 50px;
      margin: 7px auto 0;
    }
  }
</style>
