import axios from 'axios';
import { AxiosConfig } from '@watchtowerbenefits/shared-components';
import { config } from '@/utils/config.js';

const axiosConfig = () => AxiosConfig.getConfig(config.VUE_APP_COOKIE_NAMESPACE);
const documentsEndpoint = `${config.VUE_APP_API_URL}/v1/carrier_portal/documents`;
const dealHighlightsEndpoint = `${config.VUE_APP_API_URL}/v1/carrier_portal/deal_highlights`;

/**
 * All project related ajax calls
 *
 * @exports Services/Project
 */
export default {
  /**
   * Declines a Document
   *
   * @param {number} documentId
   * @param {object} declineReason
   * @returns {Promise}
   */
  declineDocument(documentId, declineReason) {
    const url = `${documentsEndpoint}/${documentId}/decline`;

    return axios
      .patch(
        url,
        declineReason,
        axiosConfig(),
      )
      .then(({ data: { document } }) => document);
  },
  /**
   * Get Info related to specific Document
   *
   * @param {number} documentId
   * @returns {Promise}
   */
  getDocumentInfo(documentId) {
    const url = `${documentsEndpoint}/${documentId}`;

    return axios.get(url, axiosConfig())
      .then(({ data }) => (data));
  },
  /**
   * Patch the firm quote (quote) expiration date.
   *
   * @param {object} root0
   * @param {number} root0.documentId
   * @param {string} root0.date
   * @returns {Promise}
   */
  patchFirmQuote({ documentId, date }) {
    const url = `${documentsEndpoint}/${documentId}/submit_stop_loss_firm_quote`;

    return axios
      .patch(
        url,
        {
          firm_quote_expiration_date: date,
        },
        axiosConfig(),
      )
      .then(({ data }) => data);
  },
  /**
   * Start a Document
   *
   * @param {number} documentId
   * @returns {Promise}
   */
  startDocument(documentId) {
    const url = `${documentsEndpoint}/${documentId}/start`;

    return axios.patch(url, {}, axiosConfig())
      .then(({ data }) => data)
      .catch(() => { });
  },
  /**
   * Start a Renewal Document
   *
   * @param {number} documentId
   * @param {object} productIds
   * @returns {Promise}
   */
  startRenewal(documentId, productIds = {}) {
    const url = `${documentsEndpoint}/${documentId}/start_renewal`;

    return axios.patch(url, productIds, axiosConfig())
      .then(({ data }) => data);
  },
  /**
   * Decline a Document
   *
   * @param {number} documentId
   * @returns {Promise}
   * I don't believe this is being used as of 2/12 but I need to double check.
   * I also don't believe this is being used, not sure how to check further
   */
  submitDocument(documentId) {
    return new Promise((resolve, reject) => {
      const url = `${documentsEndpoint}/${documentId}/submit`;

      axios
        .patch(
          url,
          {},
          axiosConfig(),
        )
        .then(({ data }) => resolve(data))
        .catch((e) => reject(e));
    });
  },
  /**
   * Get Rate Guarantees for all Products on a Document
   *
   * @param {number}  documentId
   * @returns {Promise}
   */
  getRateGuaranteeValues(documentId) {
    const url = `${documentsEndpoint}/${documentId}/rate_guarantee_values`;

    return axios
      .get(
        url,
        axiosConfig(),
      )
      .then(({ data }) => data);
  },
  /**
   * Start a Rate Pass Renewal
   *
   * @param {number} documentId
   * @param {object} rateGuarantees
   * @returns {Promise}
   */
  ratePassRenewal(documentId, rateGuarantees) {
    const url = `${documentsEndpoint}/${documentId}/rate_pass_renewal`;

    return axios
      .patch(
        url,
        rateGuarantees,
        axiosConfig(),
      )
      .then(({ data }) => data);
  },
  /**
   * Download all documents associated with the proposal
   *
   * @param {number} documentId
   * @returns {Promise}
   */
  getAllDocumentSources(documentId) {
    const url = `${documentsEndpoint}/${documentId}/sources_download`;

    return axios
      .get(
        url,
        {
          responseType: 'arraybuffer',
          ...axiosConfig(),
        },
      )
      .then(({ data }) => data);
  },
};

/**
 * Gets the deal highlight associated with the id provided.
 *
 * @param {number} highlightId - the id for the deal highlight
 * @returns {Promise} - the deal highlight retrieved
 */
export const getDealHighlight = async (highlightId) => {
  const url = `${dealHighlightsEndpoint}/${highlightId}`;
  const { data: { deal_highlight: dealHighlight } } = await axios.get(url, axiosConfig());

  return dealHighlight;
};

/**
 * Creates a new deal highlight for the given document ID.
 *
 * @param {number} documentId - the id for the document
 * @param {string} summary - the user inputted text for the deal highlight
 * @param {boolean} submitted - whether or not the user is submitting the deal highlight
 * @returns {Promise} - the newly created deal highlight
 */
export const postDealHighlight = async (documentId, summary, submitted) => {
  const url = `${documentsEndpoint}/${documentId}/deal_highlights`;
  const {
    data: { deal_highlight: dealHighlight },
  } = await axios.post(url, { summary, confirmation_sent: submitted }, axiosConfig());

  return dealHighlight;
};

/**
 * Updates the deal highlight with the given id. Can update either the summary or the confirmation_sent
 * field, which is used to indicate whether or not the user has submitted the deal highlight.
 *
 * @param {number} highlightId - the id for the deal highlight
 * @param {string} summary - the user inputted text for the deal highlight
 * @param {boolean} submitted - whether or not the user is submitting the deal highlight
 * @returns {Promise} - the updated deal highlight
 */
export const patchDealHighlight = async (highlightId, summary, submitted) => {
  const url = `${dealHighlightsEndpoint}/${highlightId}`;
  const {
    data: { deal_highlight: dealHighlight },
  } = await axios.patch(url, { summary, confirmation_sent: submitted }, axiosConfig());

  return dealHighlight;
};

/**
 * Deletes the deal highlight with the given id from the database.
 *
 * @param {number} highlightId - the id for the deal highlight
 * @returns {Promise} - does not return an object after resolved.
 */
export const deleteDealHighlight = async (highlightId) => {
  const url = `${dealHighlightsEndpoint}/${highlightId}`;
  const { data } = await axios.delete(url, axiosConfig());

  return data;
};

/**
 * Start a Smart Proposal Document
 *
 * @param {number} documentId
 * @returns {Promise}
 */
export const startSmartProposal = async (documentId) => {
  const url = `${documentsEndpoint}/${documentId}/start_proposal`;
  const { data } = await axios.patch(url, {}, axiosConfig());

  return data;
};

/**
 * Send Plan Alts to ThreeFlow Assist
 *
 * @param {number} documentId
 * @returns {Promise}
 */
export const sendPlanAltsToThreeFlowAssist = async (documentId) => {
  const url = `${documentsEndpoint}/${documentId}/threeflow_assist`;
  const { data } = await axios.patch(url, {}, axiosConfig());

  return data;
};
