<template>
  <ElDialog
    title="Firm Quote Requested"
    :visible="visible"
    data-test="firm quote dialog"
    top="70px"
    width="470px"
    @close="closeDialog"
  >
    <p
      data-test="firm quote header"
      class="inner-header"
    >
      Enter the date when your firm quote expires:
    </p>
    <ElForm
      class="firm-quote-form"
      @submit.native.prevent
    >
      <ElFormItem>
        <label
          class="form-label"
          v-text="'Expiration Date:'"
        />
        <ElInput
          ref="input-datepicker"
          v-model="dateInputModel"
          data-test="expiration date"
          class="firm-quote-date-input"
          placeholder="Pick a date"
          @blur="setDate"
          @change="setDate"
        />
        <datepicker
          v-model="selectedDate"
          :inline="true"
          :disabled-dates="disabledDates"
          calendar-class="calendar-content"
          @input="dateSelected"
        />
      </ElFormItem>
    </ElForm>
    <div class="btn-group">
      <AppButton
        size="large"
        icon="fa-solid fa-check-circle"
        :is-disabled="saving || isBeforeToday"
        data-test="confirm firm quote selected"
        @click="submit"
      >
        Firm Quote
      </AppButton>
    </div>
  </ElDialog>
</template>

<script>
  import { mapActions } from 'pinia';
  import { useProductStore } from '@/stores/product.js';
  import DocumentServices from '@/services/documents.js';
  import { segmentData } from '@/utils/analytics.js';
  import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
  import dayjs from 'dayjs';

  /**
   * Start or update renewal modal dialog. Conditionally displays Rate Pass/Rate Guarantee form or Upload Renewal documents form.
   *
   * @exports Modals/StartOrUpdateRenewalModal
   */
  export default {
    name: 'FirmQuoteModal',
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      products: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        saving: false,
        selectedDate: null,
        dateInputModel: null,
      };
    },
    computed: {
      /**
       * Disable past dates
       *
       * @returns {object}
       */
      disabledDates() {
        return {
          to: new Date(),
        };
      },
      /**
       * Determines if selectedDate day is before today.
       *
       * @returns {boolean}
       */
      isBeforeToday() {
        return dayjs().isAfter(this.selectedDate, 'day');
      },
    },
    created() {
      const firmQuoteDate = this.products.find(
        ({ firm_quote_expires_on: exp }) => exp,
      )?.firm_quote_expires_on;

      if (firmQuoteDate) {
        this.selectedDate = new Date(firmQuoteDate);
      } else {
        const startDate = new Date();

        startDate.setDate(startDate.getDate() + 14);

        this.selectedDate = startDate;
      }
      this.dateSelected();
    },
    methods: {
      ...mapActions(useProductStore, ['setProducts']),

      /**
       * Set selectedDate when the user types in a date ONLY if it's a valid date
       */
      setDate() {
        const newDate = new Date(this.dateInputModel);

        if (!Number.isNaN(newDate) && /\d+\/\d+\/\d+/.test(this.dateInputModel)) {
          this.selectedDate = newDate;
        }
      },
      /**
       * Set dateInputModel when the user selects a date
       */
      dateSelected() {
        const newDate = new Date(this.selectedDate);

        this.dateInputModel = this.selectedDate
          ? `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getFullYear()}`
          : null;
      },
      /**
       * Hide the dialog
       */
      closeDialog() {
        this.$emit('update:visible', false);
      },
      /**
       * Sends a API call to patch the firm quote (quote) using and updates the store to show the success/error message
       * and sends analytics.
       */
      async submit() {
        this.saving = true;

        try {
          const updatedProducts = await Promise.all(this.products.map(async ({ document_id: id }) => {
            const { document: { products } } = await DocumentServices.patchFirmQuote({
              date: this.selectedDate,
              documentId: id,
            });

            return products;
          }));

          this.setProducts(updatedProducts[0]);
          this.saving = false;

          trackSegmentEvent('Firm quote date done', segmentData());
        } catch {
          this.saving = false;
        }

        this.closeDialog();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .inner-header {
    margin: 20px 0;
  }

  .firm-quote-form {
    display: flex;
    justify-content: center;
  }

  .firm-quote-date-input {
    display: block;
    max-width: 300px;
    margin: 10px 0;
  }

  .vdp-datepicker {
    display: flex;
    justify-content: center;
  }

  :deep() {
    .vdp-datepicker__calendar {
      border-color: var(--tf-blue);

      .cell {
        border-radius: 50%;

        &.selected {
          background: var(--tf-blue);
          color: var(--tf-white);
        }
      }
    }
  }

  :deep(.el-icon-close:before) {
    color: var(--tf-blue);
  }

  :deep(.el-dialog__body) {
    padding: 0 40px;
  }

  :deep(.btn-group) {
    margin: 16px 0 24px;
  }
</style>
