<template>
  <div
    v-loading="!isLoaded"
    class="market-activity-tab"
  >
    <section
      v-if="isLoaded"
      class="market-activity-table-container"
    >
      <h3>Market activity</h3>
      <table>
        <thead>
          <tr>
            <th
              v-for="column in tableData"
              :key="column.label"
              :class="column.label"
              :data-test="`${column.label} column header`"
              v-text="$options.filters.capitalize(column.label)"
            />
          </tr>
        </thead>
        <tbody data-test="market activity table">
          <tr
            v-for="(row, index) in totalRows"
            :key="index"
          >
            <td>{{ tableData[0].data[index] }}</td>
            <td>{{ tableData[1].data[index] }}</td>
            <td>{{ tableData[2].data[index] }}</td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</template>

<script>
  import { mapState } from 'pinia';
  import { useProjectStore } from '@/stores/project.js';
  import RfpOverview from '@/mixins/rfpOverview.js';

  /**
   * RFP Overview Market Activity Tab
   *
   * @exports RfpOverview/MarketActivity
   */
  export default {
    name: 'MarketActivity',
    mixins: [RfpOverview],
    data: () => ({
      isLoaded: false,
    }),
    computed: {
      ...mapState(useProjectStore, [
        'brokerHierarchy',
        'documents',
        'inforceDocument',
      ]),
      /**
       * creates the table data array based on the project documents or an empty array if no document exists
       *
       * @returns {Array} - the table data
       */
      tableData() {
        if (!this.documents || !this.inforceDocument.products.length) {
          return [];
        }

        const submitted = [];
        const pending = [];
        const declined = [];

        this.documents.forEach((document) => {
          if (document.document_type.toLowerCase() !== 'policy') {
            let returnString = document.carrier.name;
            const inforceCarriers = this.inforceDocument.products.map(
              (product) => product.project_product.inforce_carrier_id,
            );

            if (inforceCarriers.includes(document.carrier.id)) {
              returnString += ' (in-force)';
            }

            if (document.state === 'finalized') {
              submitted.push(returnString);
            } else if (document.state === 'declined') {
              declined.push(returnString);
            } else {
              pending.push(returnString);
            }
          }
        });

        return [
          {
            label: 'submitted',
            data: submitted,
          },
          {
            label: 'pending',
            data: pending,
          },
          {
            label: 'declined',
            data: declined,
          },
        ];
      },

      /**
       * Evaluates the tableData data lengths to determine what the max number is.
       *
       * @returns {number}
       */
      totalRows() {
        return this.tableData.length
          ? Math.max(...this.tableData.map(({ data }) => data.length))
          : 0;
      },
    },
    watch: {
      'brokerHierarchy.hasHierarchy': {
        handler(hasHierarchy) {
          if (hasHierarchy !== undefined && !this.hideMarketTab) {
            this.isLoaded = true;
          }
        },
        immediate: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  section {
    padding: 0 20px;
  }

  h3 {
    margin-bottom: 23px;
  }

  table {
    width: 100%;
    font-size: 14px;
  }

  th,
  td {
    padding: 14px 20px;
    border: 1px solid $tf-extra-light-gray-1;
  }

  th {
    width: percentage(.3333);
    font-weight: 600;
  }

  .submitted {
    background-color: #e2f8f0;
    border: 1px solid $tf-green;
  }

  .pending {
    background-color: #fff4ea;
    border-color: $tf-orange;
  }

  .declined {
    background-color: #f7cfca;
    border-color: $tf-red;
  }
</style>
