<template>
  <ElDialog
    title="Send Plan Alts to Carrier"
    :visible="visible"
    data-test="send alts to carrier"
    top="70px"
    width="500px"
    @close="closeDialog"
  >
    <p class="modalText">
      Are you sure that the product is ready to be returned to the carrier?
    </p>
    <div class="btn-group">
      <AppButton
        size="large"
        data-test="confirm send alts to carrier"
        text="Yes"
        @click="handleSendAlts"
      />
    </div>
  </ElDialog>
</template>

<script>
  import { patchProductThreeflowAssistState } from '@/services/product.js';
  import { useProductStore } from '@/stores/product.js';
  import { mapActions } from 'pinia';
  /**
   * Will send product to update the state from threeflow_assist.
   *
   * @exports Modals/SendAltsToCarrierModal
   */
  export default {
    name: 'SendAltsToCarrierModal',
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      productId: {
        type: Number,
        required: true,
      },
    },
    methods: {
      ...mapActions(useProductStore, ['updateProduct']),
      /**
       * Closes the dialog.
       */
      closeDialog() {
        this.$emit('update:visible', false);
      },
      /**
       * Hits endpoint to send alts for product to carrier.
       */
      async handleSendAlts() {
        try {
          const { data: { product } } = await patchProductThreeflowAssistState(this.productId);

          this.updateProduct(product);
        } catch {
          this.$message({
            duration: 10000,
            message: 'There was an error sending alts to the carrier.',
            showClose: true,
            type: 'error',
          });
        } finally {
          this.closeDialog();
          this.$router.push({ name: 'YourQuotes', path: 'your-quotes' });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  :deep(.el-icon-close:before) {
    color: var(--tf-blue);
  }

  :deep(.el-dialog__body) {
    padding: 0 40px;
  }

  :deep(.btn-group) {
    margin: 16px 0 24px;
  }

  .modalText {
    margin-top: 16px;
  }
</style>
