import { defineStore } from 'pinia';
import { useCarrierInfoStore } from '@/stores/carrierInfo.js';

export const useAccountStore = defineStore('account', {
  state: () => ({
    accountIsLoaded: false,
    userInfo: {},
    roleNames: [],
    planDesignProductsVisited: [],
    rateProductsVisited: [],
    invitedToProject: null,
    auth: {},
  }),

  getters: {
    commentUserInfo: (state) => {
      const { first_name: firstName, last_name: lastName } = state.userInfo;

      return {
        firstName,
        lastName,
        organization: useCarrierInfoStore().name,
        type: 'carrier',
      };
    },
  },
  actions: {
    /**
     * resets the userInfo object
     */
    signOutUser() {
      useCarrierInfoStore().$reset();
      this.$reset();
    },
    /**
     * sets the attributes of the user object
     *
     * @param {object} user
     */
    setUserInfo(user) {
      this.accountIsLoaded = false;

      // Set the user info
      this.userInfo.id = user.id ? user.id : null;
      this.userInfo.email = user.email ? user.email : null;
      this.userInfo.first_name = user.first_name ? user.first_name : null;
      this.userInfo.last_name = user.last_name ? user.last_name : null;
      this.userInfo.confirmed = user.confirmed ? user.confirmed : null;
      this.userInfo.created_at = user.created_at ? user.created_at : null;
      this.userInfo.auth_token_expires_at = user.auth_token_expires_at
        ? user.auth_token_expires_at
        : null;
      this.userInfo.temp_password = user.temp_password ? user.temp_password : null;
      this.userInfo.roles = user.roles ? user.roles : null;
      this.userInfo.department = user.department ? user.department : null;
      this.roleNames = user.roles?.map((role) => role.name) ?? [];

      if (user.confirmed) {
        this.setLocalStorage();
        this.setAuthStatus(true);
      }

      if (user.carrier) {
        this.setCarrierInfo(user.carrier);
      }

      this.accountIsLoaded = true;
    },
    /**
     * sets the user email
     *
     * @param {string} email
     */
    setUserEmail(email = '') {
      this.userInfo.email = email;
    },
    /**
     * Sets the carrierInfoStore
     *
     * @param {object} carrier
     */
    setCarrierInfo(carrier) {
      const { id, name, logo_url: logoUrl } = carrier;
      const carrierInfoStore = useCarrierInfoStore();

      carrierInfoStore.$patch({
        id,
        name,
        logoUrl,
      });
    },
    /**
     * Adds a plan design product to the list of visited products and sets it in local storage so it can persist across sessions.
     *
     * @param {number|string} productId
     */
    addPlanDesignProductVisited(productId) {
      this.planDesignProductsVisited.push(productId);
      window.localStorage.setItem('planDesignProductsVisited', JSON.stringify(this.planDesignProductsVisited));
    },
    /**
     * Adds a rate product to the list of visited products and sets it in local storage so it can persist across sessions.
     *
     * @param {number|string} productId
     */
    addRateProductVisited(productId) {
      this.rateProductsVisited.push(productId);
      window.localStorage.setItem('rateProductsVisited', JSON.stringify(this.rateProductsVisited));
    },
    /**
     * If the user has visited a plan design or rate entry page for a product, the productId is saved in local storage.
     * On login (or page refresh), this method will set up those products in the store from the local storage values.
     */
    setLocalStorage() {
      const { localStorage } = window;

      if (localStorage.planDesignProductsVisited) {
        this.planDesignProductsVisited = JSON.parse(localStorage.planDesignProductsVisited);
      }
      if (localStorage.rateProductsVisited) {
        this.rateProductsVisited = JSON.parse(localStorage.rateProductsVisited);
      }
    },
    /**
     * Sets the auth.tempPasswordSent so we know if they need to reset their password
     *
     * @param {boolean} status
     */
    setTempPasswordStatus(status) {
      this.auth = { ...this.auth, tempPasswordSent: status };
    },
    /**
     * Sets the auth.status
     *
     *  @param {boolean} auth
     */
    setAuthStatus(auth = false) {
      this.auth = { ...this.auth, status: auth };

      if (!auth) {
        this.auth.confirmed = false;
      }
    },
    /**
     * Sets the auth.inactive
     *
     *  @param {boolean} inactive
     */
    setUserInactive(inactive) {
      this.auth = { ...this.auth, inactive };
    },
  },
});
