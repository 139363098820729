/**
 * Project mixin
 *
 * @exports Mixins/Project
 */

// pinia
import { useBrokerUpdatesStore } from '@/stores/brokerUpdates.js';
import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
import { useQuoteEditsStore } from '@/stores/quoteEdits.js';
import { useCommentsStore } from '@/stores/comments.js';
import { useNotificationsStore } from '@/stores/notifications.js';
import { useProjectStore } from '@/stores/project.js';
import { useAccountStore } from '@/stores/account.js';
import { mapState, mapActions, mapWritableState } from 'pinia';
import ServiceAccount from '@/services/account.js';

export default {
  computed: {
    ...mapWritableState(useNotificationsStore, ['unreadEdits']),
    ...mapWritableState(useBrokerUpdatesStore, { unreadBrokerUpdates: 'notifications' }),
    ...mapState(useQuoteEditsStore, ['quoteEditsFetched']),
    ...mapState(useCommentsStore, { commentsIsLoaded: 'isLoaded' }),
    ...mapState(useCarrierInfoStore, { carrierId: 'id' }),
    ...mapState(useProjectStore, {
      brokerIsLoaded: 'brokerIsLoaded',
      brokerIsLoading: 'brokerIsLoading',
      project: 'project',
      projectId: 'projectId',
      localProjectIsLoaded: 'projectIsLoaded',
      localProjectIsLoading: 'projectIsLoading',
      documentsIsLoaded: 'documentsIsLoaded',
      documentsIsLoading: 'documentsIsLoading',
      proposalDocument: 'proposalDocument',
      proposalsIsLoaded: 'proposalsIsLoaded',
      proposalsIsLoading: 'proposalsIsLoading',
      inforceDocumentId: 'inforceDocumentId',
    }),
    ...mapWritableState(useAccountStore, ['accountIsLoaded', 'invitedToProject', 'userInfo', 'auth']),

    /**
     * Evaluates the appropriate loaded states to determine
     * if the component is loaded on a project level.
     *
     * @returns {boolean}
     */
    projectIsLoaded() {
      return this.brokerIsLoaded
        && this.documentsIsLoaded
        && this.localProjectIsLoaded
        && this.proposalsIsLoaded;
    },

    /**
     * Evaluates the appropriate loading states to determine
     * if the component is loading on a project level.
     *
     * @returns {boolean}
     */
    projectIsLoading() {
      return this.brokerIsLoading
        || this.documentsIsLoading
        || this.localProjectIsLoading
        || this.proposalsIsLoading;
    },
  },
  methods: {
    ...mapActions(useBrokerUpdatesStore, ['getUpdatesAndNotifications']),
    ...mapActions(useCommentsStore, ['getCommentsNotifications']),
    ...mapActions(useQuoteEditsStore, ['getQuoteEdits', 'resetQuoteEditState']),
    ...mapActions(useNotificationsStore, ['getEditsNotifications']),
    ...mapActions(useProjectStore, ['getBrokerInfo', 'getProjectInfo', 'getProjectDocuments', 'getProposalDocument', 'getPolicyDocument', 'setDocumentsLoaded', 'resetProjectState']),

    /**
     * Calls the 'checkInvitedToProject' API if invitedToProject isn't set to an initial value of `null`.
     * If the user is not invited, push them to the dashboard page with a warning message.
     */
    checkInvited() {
      if (this.invitedToProject !== null) {
        return;
      }

      this.setDocumentsLoaded(true);

      ServiceAccount
        .checkInvitedToProject(this.userInfo.email, this.$route.params.projectId)
        .then(({ invited }) => {
          this.invitedToProject = invited;

          if (!invited) {
            this.$router.push({ name: 'Dashboard' });
            this.$message({
              showClose: true,
              message: 'You have not been invited to this RFP.',
              type: 'warning',
            });
          }
        })
        .catch(() => { });
    },

    /**
     * Local action to call of the project data.
     * Since projectId (getter) has potentially not been established, projectId should be a param.
     *
     * @param {number} projectId
     */
    async getAllProjectData(projectId) {
      await Promise.all([
        this.getProjectInfo(projectId),
        this.getBrokerInfo(projectId),
        this.getProjectDocuments(this.carrierId, projectId),
        this.getCommentsNotifications(projectId),
        this.getEditsNotifications(projectId),
        this.getUpdatesAndNotifications(projectId),
      ]);
      // This is for an edge case where a user manually changes a project ID in the URL.
      if (!this.proposalDocument) {
        return;
      }

      const { id } = this.proposalDocument;

      await Promise.all([
        this.getQuoteEdits(id),
        this.getProposalDocument(id),
        this.getPolicyDocument(this.inforceDocumentId),
      ]);

      if (this.unreadBrokerUpdates) this.createToast();
    },

    /**
     * Method that goes through a list of loaded states resets the data if required.
     */
    resetProject() {
      const testAndReset = [
        ['localProjectIsLoaded'], // reset project data first.
        ['quoteEditsFetched', 'resetQuoteEditState'],
      ];

      this.invitedToProject = null;
      this.unreadEdits = null;
      this.unreadBrokerUpdates = 0;

      if (this.commentsIsLoaded) useCommentsStore().$reset();

      this.resetProjectState();

      testAndReset.forEach(([test, action]) => {
        if (this[test]) this[action]();
      });
    },
    /**
     * Method to create toast message with link to broker updates page.
     */
    createToast() {
      const create = this.$createElement;
      let messageUpdatesEdits = null;
      const link = create(
        'tf-button',
        {
          props: { type: 'primary', size: 'text' },
          style: { display: 'inline-block' },
          on: {
            click: () => {
              const route = this.$route.meta.pageType === 'dataEntry'
                ? `${this.$route.fullPath}/broker-updates`
                : { name: 'UpdatesEdits' };

              this.$router.push(route);
              messageUpdatesEdits.close();
            },
          },
        },
        ' View updates',
      );
      const message = create('p', null, [
        create('span', null, 'Your RFP has been recently updated. '),
        link,
        create('span', null, '.'),
      ]);

      messageUpdatesEdits = this.$message({
        showClose: true,
        message,
        type: 'warning',
        duration: 10000,
      });
    },
  },
};
