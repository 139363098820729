/**
 * PDV sources.
 */
export const sourceMap = {
  dive: 'Source: Proposal Document',
  connect_api: 'Source: ThreeFlow Connect',
  carrier_default: 'Source: Carrier Default',
  user_default: 'Source: User Saved Preference',
  not_found: 'Missing Information',
};
