import axios from 'axios';
import { AxiosConfig } from '@watchtowerbenefits/shared-components';
import { config } from '@/utils/config.js';

/**
 * All Smart Quote related submission calls
 *
 * @exports Submission
 */

/**
 * Submit renewal token to endpoint to transition the product(s) to completed
 *
 * @param {string} token
 * @param {string} quoteType
 * @returns {Promise}
 */
export async function submitQuote(token, quoteType = 'renewal') {
  const url = `${config.VUE_APP_API_URL}/v1/carrier_portal/projects/submit_${quoteType}`;
  const axiosConfig = () => AxiosConfig.getConfig(config.VUE_APP_COOKIE_NAMESPACE);
  const { data } = await axios
    .patch(
      url,
      { product_submission_token: token },
      axiosConfig(),
    );

  return data;
}
