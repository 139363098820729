import { defineStore } from 'pinia';
import {
  getDealHighlight,
  postDealHighlight,
  patchDealHighlight,
  deleteDealHighlight,
} from '@/services/documents.js';
// eslint-disable-next-line import/no-cycle
import { useProjectStore } from '@/stores/project.js';
import { useProductStore } from '@/stores/product.js';

/**
 * Sets the isLoaded property to false while the given function is running. If the function
 * throws an error, the error message provided is stored in the error property.
 *
 * @param {Function} fn - the async function to run
 * @param {string} errorMessage - the error message to store if the function throws an error
 * @returns {Promise<void>}
 */
const handleAsync = async (fn, errorMessage = 'Something went wrong.') => {
  // eslint-disable-next-line no-use-before-define
  const store = useDealHighlightsStore();

  store.isLoaded = false;

  try {
    await fn();
  } catch {
    store.error = errorMessage;
  } finally {
    store.isLoaded = true;
  }
};

export const useDealHighlightsStore = defineStore('dealHighlights', {
  state: () => ({
    id: null,
    summary: '',
    confirmationSent: false,
    isLoaded: false,
    error: '',
  }),
  getters: {
    /**
     * Deal highlights are only able to be submitted when the opportunity hasn't been sold yet and hasn't been declined.
     *
     * @returns {boolean}
     */
    canSubmit() {
      const projectIsSold = useProductStore().products?.some((product) => product.is_sold);
      const projectStore = useProjectStore();
      const projectIsDeclined = projectStore.proposalDocument?.state === 'declined';

      return !(projectIsSold || projectIsDeclined || this.confirmationSent);
    },
  },
  actions: {
    /**
     * Fetches a deal highlight from the API and stores it in the store.
     *
     * @param {number} highlightId - the ID of the deal highlight to retrieve
     * @returns {Promise<void>}
     */
    async getDealHighlight(highlightId) {
      await handleAsync(async () => {
        const {
          id,
          summary,
          confirmation_sent: confirmationSent,
        } = await getDealHighlight(highlightId);

        this.id = id;
        this.summary = summary;
        this.confirmationSent = confirmationSent;
      }, 'There was an error retrieving the deal highlight.');
    },
    /**
     * Creates a deal highlight for the given document ID and stores it in the store.
     *
     * @param {number} documentId - the ID of the document to create a deal highlight for
     * @param {string} summary - the summary to create the deal highlight with
     * @param {boolean} sendConfirmation - whether or not to send a confirmation email
     */
    async createDealHighlight(documentId, summary, sendConfirmation = false) {
      await handleAsync(async () => {
        const {
          id,
          summary: savedSummary,
          confirmation_sent: confirmationSent,
        } = await postDealHighlight(documentId, summary, sendConfirmation);

        this.id = id;
        this.summary = savedSummary;
        this.confirmationSent = confirmationSent;
      }, 'There was an error creating the deal highlight.');
    },
    /**
     * Edits a deal highlight in the API and stores it in the store.
     *
     * @param {string} summary - the summary to update the deal highlight with
     * @param {boolean} sendConfirmation - whether or not to send a confirmation email. Defaults to the current value of confirmationSent.
     * @returns {Promise<void>}
     */
    async updateDealHighlight(summary, sendConfirmation = this.confirmationSent) {
      await handleAsync(async () => {
        const {
          id,
          summary: savedSummary,
          confirmation_sent: confirmationSent,
        } = await patchDealHighlight(this.id, summary, sendConfirmation);

        this.id = id;
        this.summary = savedSummary;
        this.confirmationSent = confirmationSent;
      }, 'There was an error updating the deal highlight.');
    },
    /**
     * Deletes a deal highlight from the API and resets the store.
     *
     * @returns {Promise<void>}
     */
    async deleteDealHighlight() {
      await handleAsync(async () => {
        await deleteDealHighlight(this.id);
        this.$reset();
      }, 'There was an error deleting the deal highlight.');
    },
    /**
     * Tells the API to send a confirmation email for the current deal highlight. If
     * there is no current deal highlight, a new one is created.
     *
     * @param {number} documentId - the ID of the document to create a deal highlight for
     * @returns {Promise<void>}
     */
    async sendConfirmation(documentId) {
      if (documentId) {
        await this.createDealHighlight(documentId, this.summary, true);
      } else {
        await this.updateDealHighlight(this.summary, true);
      }
    },
  },
});
