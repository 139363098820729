<template>
  <div
    v-loading="!quoteEditsFetched"
    :class="{ 'is-panel' : $route.meta.pageType === 'dataEntry' }"
    data-test="history updates container"
    class="history-updates-container"
  >
    <AppAlert
      v-if="quoteEditsError"
      class="loading-error-disclaimer"
      type="danger"
      show-icon
      description="There was an error getting the quote edits."
    />
    <div
      v-else-if="quoteEditsFetched"
      class="quote-edits-container"
      data-test="quote edits"
    >
      <header>
        <h3
          :class="{ 'has-dates': dates.length }"
          v-text="'Quote edits'"
        />
        <p
          v-if="dates.length"
          class="panel-paragraph-heading"
        >
          This is a record of confirmed edits to your offer, which is also visible to your broker.
        </p>
      </header>
      <div
        v-if="dates.length"
        class="filter-btns"
      >
        <AppButton
          v-if="!filterOpen"
          data-test="open quote edit filters"
          icon="fa-solid fa-filter"
          size="text"
          text="Open filters"
          data-dont-close-panel
          @click="openFilters"
        />
      </div>
      <!-- Filter section -->
      <div
        v-if="dates.length"
        :class="{'open' : filterOpen}"
        class="filters"
        data-test="filters panel"
      >
        <div class="filters-inner">
          <AppButton
            v-if="filterOpen"
            class="close-btn"
            icon="fa-solid fa-xmark"
            size="icon"
            data-dont-close-panel
            data-test="close filters"
            @click="closeFilters"
          />

          <p>I only want to see:</p>
          <div
            v-if="productFilters.length"
            class="product-filters"
          >
            <h3>Product</h3>
            <ElCheckboxGroup
              v-model="productFiltersSelected"
              @change="filterHistory"
            >
              <ElCheckbox
                v-for="product in productFilters"
                :key="product.id"
                :label="product.id"
              >
                {{ product.project_product.product_type_name }}
                <template v-if="product.project_product.alternative">
                  - {{ product.project_product.label }}
                </template>
              </ElCheckbox>
            </ElCheckboxGroup>
          </div>
          <div class="author-filters">
            <h3>Author</h3>
            <ElCheckboxGroup
              v-model="authorFiltersSelected"
              @change="filterHistory"
            >
              <ElCheckbox
                v-for="author in authorFilters"
                :key="author.id"
                :label="author.id"
              >
                {{ author.first_name }} {{ author.last_name }}
              </ElCheckbox>
            </ElCheckboxGroup>
          </div>
          <AppButton
            v-if="filterOpen"
            class="reset-btn"
            icon="fa-solid fa-redo"
            size="text"
            text="Reset filters"
            data-test="reset quote edit filters"
            type="danger"
            @click="resetFilters"
          />
        </div>
      </div>
      <!-- Quote edits -->
      <TfQuoteEditsExport
        :dates="filteredDates"
        :employer-name="employerName"
        :filters="filters"
        :project-id="Number(projectId)"
        button-title="Export quote edits"
        @click.native="sendAnalytics()"
      />
      <section
        v-for="(date, index) in filteredDates"
        :key="index"
      >
        <ul>
          <AppQuoteEditItem
            v-for="item in date"
            :key="item.id"
            v-bind="{
              attributeLink: getAttributeLink(item),
              item,
              showEditor,
            }"
          />
        </ul>
      </section>
      <!-- No results section -->
      <template v-if="dates">
        <div
          v-if="dates.length && !filteredDates.length"
          class="empty-state filtered-out"
          data-test="no quote edits to display"
        >
          <img
            :src="emptyIcon"
            alt="Filtered out icon"
          >
          <p>
            There are no quote edits that match your selected filters. Try selecting fewer filters.
          </p>
        </div>
        <!-- Empty section -->
        <div
          v-if="!dates.length"
          class="empty-state-container"
        >
          <div class="empty-state">
            <img
              :src="emptyIcon"
              alt="Empty icon"
            >
            <p v-if="proposalDocument.state === 'finalized'">
              Once you’ve submitted your quote, this will serve as a record of your
              confirmed edits to your offer. This will also be visible to your broker.
            </p>
            <p v-else>
              Once you’ve submitted your quote, this will serve as a record of your
              confirmed edits to your offer. This will also be visible to your broker.
            </p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { useQuoteEditsStore } from '@/stores/quoteEdits.js';
  import { useNotificationsStore } from '@/stores/notifications.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductStore } from '@/stores/product.js';
  import { mapState, mapActions, mapWritableState } from 'pinia';
  import { uniqBy } from 'lodash';
  import emptyIcon from '@/assets/check-list-record.svg';
  import { segmentData } from '@/utils/analytics.js';
  import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
  import { brokerQuoteSubmissionEdits } from '@/utils/featureFlags.js';
  import { config } from '@/utils/config.js';

  const apiConfig = {
    baseUrl: config.VUE_APP_API_URL,
    cookieNamespace: config.VUE_APP_COOKIE_NAMESPACE,
  };

  /**
   * Quote Edits Container
   *
   * @exports QuoteEdits/QuoteEditsContainer
   */
  export default {
    name: 'QuoteEditsContainer',
    data: () => ({
      authorFilters: [],
      authorFiltersSelected: [],
      emptyIcon,
      filteredDates: [],
      filterOpen: false,
      productFilters: [],
      productFiltersSelected: [],
    }),

    computed: {
      ...mapWritableState(useNotificationsStore, ['unreadEdits']),
      ...mapState(useQuoteEditsStore, ['quoteEditsError', 'dates', 'quoteEditsFetched']),
      ...mapState(useQuoteEditsStore, {
        storedProductFiltersSelected: 'productFiltersSelected',
      }),
      ...mapState(useProjectStore, ['employerName', 'projectId', 'proposalDocument']),
      ...mapState(useProductStore, ['products']),
      /**
       * creates the filters based on the `productFiltersSelected`
       *
       * @returns {object}
       */
      filters() {
        if (!this.products.length) {
          return {};
        }

        return {
          products: this.productFiltersSelected.map((selectedId) => {
            const product = this.products.find(({ id }) => id === selectedId);
            const projectProduct = product.project_product;
            let name = product.product_type_name;

            if (projectProduct.alternative || name !== projectProduct.label) {
              name += `, ${projectProduct.label}`;
            }

            return name;
          }),
          author: this.authorFiltersSelected.map((selectedId) => {
            const author = this.authorFilters.find(({ id }) => id === selectedId);

            return `${author.first_name} ${author.last_name}`;
          }),
        };
      },
      /**
       * Determine the prop for the `<AppQuoteEditItem />` component based on the feature flag value.
       *
       * @returns {Array}
       * @deprecated Rff:brokerQuoteSubmissionEdits.
       */
      showEditor() {
        return this.$ld.checkFlags(brokerQuoteSubmissionEdits);
      },
    },
    watch: {
      /**
       * when the dates prop changes we want to recreate our list of filter options
       */
      dates() {
        this.getFilterLists();
      },
      /**
       * if the products haven't loaded let we also recreate our filter options (because know we have a list of products to relate them to)
       */
      products() {
        this.getFilterLists();
      },
      /**
       * changes the selected product filters and if filter is open
       */
      storedProductFiltersSelected() {
        this.productFiltersSelected = this.storedProductFiltersSelected.slice(0);
        this.filterOpen = Boolean(this.productFiltersSelected.length);
        this.filterHistory();
      },
    },

    /**
     * when the component is created we look at the data passed in and call getFilterLists()
     * this function filters our data (though at this point we don't need to), it also creates the array to use for filters
     * this doesn't do much if this is on page reload (we have to call on the dates watch as well)
     */
    created() {
      this.getQuoteEdits(this.proposalDocument.id);
      if (this.unreadEdits) {
        this.clearEditNotifications();
      }

      this.productFiltersSelected = this.storedProductFiltersSelected.slice(0);

      if (this.productFiltersSelected.length) {
        this.filterOpen = true;
      }

      this.getFilterLists();
    },

    /**
     * reset selected product filters to none
     */
    beforeDestroy() {
      this.setProductsFiltersSelected([]);
    },

    methods: {
      ...mapActions(useQuoteEditsStore, ['setProductsFiltersSelected', 'getQuoteEdits']),
      /**
       * Call the notifications service to clear edit notifications
       */
      async clearEditNotifications() {
        await this.$TF.NotificationsService.clearEditNotifications(
          this.projectId,
          'carrier_portal',
          apiConfig,
        );
        this.unreadEdits = null;
      },

      /**
       * Get a list to populate the filters
       */
      getFilterLists() {
        // first we create a bunch of variables so we can do all the heavy lifting and then do a Vue.set at the end
        const authorFilters = [];
        let productFilters = [];

        this.authorFilters = [];
        this.productFilters = [];
        // only proceed if we actually have data to work with
        if (this.dates) {
          this.dates.forEach((date) => {
            date.forEach((edit) => {
              authorFilters.push(edit.history_details.responsible_user);
            });
          });

          productFilters = this.products.slice(0);
          // then lets filter our author array only to unique users
          this.authorFilters = uniqBy(authorFilters, (e) => e.id);

          Vue.set(this, 'productFilters', productFilters);
          this.filterHistory();
        }
      },

      /**
       * Open the filter drawer
       */
      openFilters() {
        this.filterOpen = true;
      },

      /**
       * Close the filter drawer
       */
      closeFilters() {
        this.filterOpen = false;
      },

      /**
       * Close the filter drawer and set all filters to selected
       */
      resetFilters() {
        this.authorFiltersSelected = [];
        this.productFiltersSelected = [];
        this.filteredDates = this.dates;
      },

      /**
       * Apply the filters to our dates prop and create a list of filtered dates
       */
      filterHistory() {
        let filteredDates = [];

        if (!this.authorFiltersSelected.length && !this.productFiltersSelected.length) {
          filteredDates = this.dates;
        } else {
          this.dates.forEach((date) => {
            const dateArray = date.filter((edit) => {
              const author = edit.history_details.responsible_user;

              /* eslint-disable max-len */
              return (!this.authorFiltersSelected.length || this.authorFiltersSelected.includes(author.id))
                && (!this.productFiltersSelected.length || this.productFiltersSelected.includes(edit.product_id));
              /* eslint-enable max-len */
            });

            if (dateArray.length) {
              filteredDates.push(dateArray);
            }
          });
        }
        Vue.set(this, 'filteredDates', filteredDates);
      },

      /**
       * On quote level history we provide links to go to a specfic product, this determines the link
       *
       * @param {object} item
       * @returns {string}
       */
      getAttributeLink(item) {
        const attributeType = item.attributable_type.toLowerCase() === 'plandesignattribute'
          && item.history_details.attribute_name.toLowerCase() !== 'rate guarantee'
          ? 'plan'
          : 'rate';

        return `/project/${this.projectId}/product/${item.product_id}/${attributeType}/review`;
      },
      /**
       * Sends event to segment
       */
      sendAnalytics() {
        trackSegmentEvent('Export RFP edits', segmentData());
      },
    },
  };
</script>

<style lang="scss" scoped>
  :deep(.pdf-export-generator) {
    padding: 20px 0 0 27px;
  }

  section {
    padding-left: 11px;

    &:first-of-type {
      margin-top: 5px;
    }

    &:last-child {
      li:last-child {
        border-bottom: 0;
      }
    }
  }

  header {
    border-bottom: 1px solid var(--tf-gray-light-medium);

    .is-panel & {
      text-align: center;
      margin: 0;
      padding: 0;
    }

    h3 {
      margin-bottom: 25px;

      &.has-dates {
        margin-bottom: 14px;
      }

      .is-panel & {
        font-size: 24px;
        margin-bottom: 25px;
        margin-top: 49px;
        line-height: 24px;
      }
    }

    p {
      color: var(--tf-gray);
      line-height: 18px;
      margin-top: 0;
      padding-bottom: 25px;
    }
  }

  .empty-state {
    padding: 10px 0;

    &.filtered-out {
      text-align: center;

      .filters:not(.open) + & img {
        margin-top: 58px;
      }
    }
  }

  .empty-state-container {
    display: flex;
    align-items: center;
    align-self: center;
    flex-grow: 1;
    text-align: center;
    max-height: calc(100vh - 342px);
    width: 100%;
    min-height: 200px;

    p {
      font-size: 18px;
      line-height: 26px;
    }

    .panel & {
      display: flex;
      padding-top: 0;

      p {
        padding-left: 26px;
        padding-right: 35px;
      }
    }

    img {
      width: 74px;
      height: 88px;
    }
  }

  .filters {
    background: var(--tf-gray-light);
    border-bottom: 1px solid var(--tf-gray-light-medium);
    max-height: 0;
    overflow: hidden;
    transition: max-height .5s;

    .el-checkbox {
      display: block;
      margin-bottom: 8px;

      + .el-checkbox {
        margin-left: 0;
      }

      :deep(&__label) {
        color: var(--tf-gray-dark);
      }
    }

    &.open {
      max-height: initial;
      border-bottom: 1px solid var(--tf-gray-light-medium);

      + .empty-state img {
        margin-top: 58px;
        transition: margin .1s;
      }
    }

    .filters-inner {
      padding: 12px 16px 30px 30px;
      text-align: right;

      > div {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        text-align: left;
        box-sizing: border-box;

        &.single {
          width: 100%;
        }
      }

      p {
        text-align: left;
        color: var(--tf-gray);
        margin-top: 0;
      }

      .close-btn {
        margin-left: auto;
      }
    }

    h3 {
      font-size: 14px;
      text-transform: uppercase;
      color: var(--tf-blue-dark);
      margin-bottom: 16px;
    }
  }

  .filter-btns {
    padding: 17px 27px;
  }

  .history-updates-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    p {
      color: var(--tf-gray);
    }
  }

  .quote-edits-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .loading-error-disclaimer {
    margin-top: 20px !important; //stylelint-disable-line declaration-no-important
  }

  .is-panel {
    width: 100%;
    text-align: left;
    min-width: 100vw;

    @media (min-width: 557px) {
      min-width: 557px;
    }
  }

  .reset-btn {
    margin: 14px 0 0 auto !important; // stylelint-disable-line declaration-no-important
  }
</style>
