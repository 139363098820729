<template>
  <section :class="{ active : active }">
    <ContainerHeader
      :active="active"
      :container="container"
      data-test="rate entry container header"
      @toggle="active = !active"
    >
      <template
        v-if="isUploadRenewalRatePass && containerIndex === 0"
        #policyIds
      >
        <div class="policy-ids-list">
          <div>Policy number</div>
          <div class="policy-ids-input">
            <ElInput
              id="policyIdsInput"
              v-model="policyIdsString"
              @blur="updatePolicyIds"
            />
            Separate policy IDs with commas
          </div>
        </div>
      </template>
      <template
        v-if="!isPlanSummary"
        #btnSeparateClasses
      >
        <AppButton
          v-if="!isPlan && container.id.length > 1"
          :is-disabled="!active || $route.meta.readonly || disableSeparateClasses"
          data-test="separate classes"
          size="text"
          text="Separate classes"
          @click="separateClasses"
        />
      </template>
      <template
        v-if="!isPlanSummary"
        #btnCopyInforce
      >
        <AppButton
          v-if="!isNewCoverage"
          :is-disabled="!active || $route.meta.readonly"
          data-test="copy inforce"
          icon="fa-sharp fa-files"
          text="Copy in-force rates"
          type="secondary"
          @click="copyFromInforce()"
        />
      </template>
      <template
        v-if="description"
        #description
      >
        <p data-test="rate entry description">
          <template v-if="description.length > truncateLength && !isPlanSummary">
            {{ description.substr(0, truncateLength) }}&hellip;
            <AppButton
              data-test="show full description"
              size="text"
              text="full description"
              @click="showDescriptionDialog = !showDescriptionDialog"
            />
          </template>
          <template v-else>
            {{ description }}
          </template>
        </p>
      </template>
    </ContainerHeader>
    <div v-show="active">
      <AppAlert
        v-if="!isPlanSummary && warningMessage"
        data-test="rate entry warning"
        class="rate-entry-warning"
        :description="warningMessage"
        type="info"
        closable
        show-icon
        @close="$emit('dismissAlert')"
      />
      <template v-for="(rateAttribute, rateAttributeIndex) in container.rateAttributes">
        <RateEntryAttribute
          :key="`${container.id.join('_')}-${rateAttribute.id}`"
          :rate-attribute="rateAttribute"
          :rate-attribute-index="rateAttributeIndex"
        />
        <hr
          v-if="container.rateAttributes.length > 1
            && rateAttributeIndex !== container.rateAttributes.length - 1
            && !isPlanSummary"
          :key="`${rateAttribute.id}-break`"
        >
      </template>
    </div>
    <ElDialog
      v-if="showDescriptionDialog"
      :visible.sync="showDescriptionDialog"
      :title="`Class description${container.containerNames.length > 1 ? 's' : ''}`"
      data-test="all rate entry class descriptions"
      top="70px"
      width="425px"
    >
      <div id="class-descriptions">
        <template v-for="(desc, idx) in container.description">
          <h4
            :key="container.containerNames[idx]"
            v-text="`Class ${container.containerNames[idx]}`"
          />
          <p
            :key="`description-${idx}`"
            class="class-description"
            v-text="desc"
          />
        </template>
      </div>
    </ElDialog>
  </section>
</template>

<script>
  import { computed } from 'vue';
  import { mapState, mapWritableState, mapActions } from 'pinia';
  import { useRateEntryStore } from '@/stores/rateEntry.js';
  import { useProductStore } from '@/stores/product.js';
  import ContainerHeader from '@/components/DataEntry/ContainerHeader.vue';
  import productService, { patchProductPolicyIds } from '@/services/product.js';
  import RateEntryAttribute from './RateEntryAttribute.vue';

  /**
   * Rate Entry Container
   *
   * @exports RateEntry/RateEntryContainer
   */
  export default {
    name: 'RatesEntryContainer',
    components: { ContainerHeader, RateEntryAttribute },
    /**
     * sends reactive containerIds and isPlan
     *
     * @returns {object}
     */
    provide() {
      return {
        containerIds: computed(() => this.containerIds),
        isPlan: computed(() => this.isPlan),
      };
    },
    inject: [
      'isPlanSummary',
    ],
    props: {
      container: {
        type: Object,
        required: true,
        default: () => {},
      },
      warningMessage: {
        type: String,
        default: null,
      },
      containerIndex: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        active: true,
        disableSeparateClasses: false,
        showDescriptionDialog: false,
        truncateLength: 200,
        updatedPolicyIds: null,
      };
    },
    computed: {
      ...mapWritableState(useProductStore, ['currentProduct']),
      ...mapWritableState(useRateEntryStore, [
        'rateEntryContainers',
        'classesAreSeparated',
        'separatedClassIds',
        'rateErrors',
        'drainingUpdateRequests',
      ]),
      ...mapState(useRateEntryStore, {
        isPlan: (state) => state.info.container_type.toLowerCase() === 'plan',
        isEditing: 'editingRateAttributeValue',
      }),
      ...mapState(useProductStore, [
        'isNewCoverage',
        'productId',
        'productState',
        'isUploadRenewalRatePass',
      ]),
      /**
       * Format policy ids array into a string / update local data
       */
      policyIdsString: {
        get() {
          return this.currentProduct?.policy_ids.join(', ');
        },
        set(newValue) {
          this.updatedPolicyIds = newValue;
        },
      },
      /**
       * ContainerIds is used throughout container child components
       *
       * @returns {object}
       */
      containerIds() {
        return this.container.id;
      },
      /**
       * Returns a joined string of the container descriptions array.
       *
       * @returns {string}
       */
      description() {
        return !this.isPlan
          ? this.container.description.join(', ')
          : null;
      },
    },
    watch: {
      drainingUpdateRequests(newValue) {
        this.disableSeparateClasses = newValue;
      },
    },
    methods: {
      ...mapActions(useRateEntryStore, [
        'getRateEntry',
        'copyInforceRate',
      ]),
      ...mapActions(useProductStore, ['updateProduct']),
      /**
       * Patch modified policy ids
       */
      async updatePolicyIds() {
        if (this.updatedPolicyIds !== this.policyIdsString) {
          try {
            const { id: currentProductId } = this.currentProduct;

            await patchProductPolicyIds(
              currentProductId,
              this.updatedPolicyIds.split(',').map((id) => id.trim()).filter((id) => id.length),
            );

            const { product } = await productService.getProduct(currentProductId);

            this.updateProduct(product);
            this.currentProduct = product;
          } catch {
            this.$message({
              showClose: true,
              message: 'Could not update policy ids.',
              type: 'error',
            });
          } finally {
            this.updatedPolicyIds = this.policyIdsString;
          }
        }
      },
      /**
       * Dispatch an action to the store to hit the server and copy over the inforce rates (then update the store with new rates)
       */
      async copyFromInforce() {
        try {
          await this.copyInforceRate(this.productId, this.containerIds, this.productState);
        } catch {
          this.$message({
            message: 'Error copying inforce rates',
            type: 'error',
          });
        }
      },
      /**
       * We need to pulse until any patching is successful.
       * This can happen if the user updates a value and clicks on the `Separate Classes` button
       * If there is still patching happening disable the button until the loader can pop up
       */
      async separateClasses() {
        const separateClasses = async () => {
          this.separatedClassIds = this.containerIds;
          this.rateEntryContainers = [];
          this.classesAreSeparated = true;
          await this.getRateEntry({
            rollOutIds: this.containerIds,
            productId: this.productId,
          });
          this.rateErrors.splice(0, this.rateErrors.length);
        };
        const checkPatching = () => {
          setTimeout(() => {
            if (this.isEditing) {
              this.disableSeparateClasses = true;
              checkPatching();
            } else {
              separateClasses();
            }
          }, 50);
        };

        // Separate the classes is no patching being done
        if (!this.isEditing) {
          await separateClasses();
          this.rateErrors.splice(0, this.rateErrors.length);

          return;
        }

        checkPatching();
      },
    },
  };
</script>

<style lang="scss" scoped>
section.active {
  border-bottom: 1px solid var(--tf-gray-medium);

  .dialog-plan-summary & {
    margin-bottom: 10px;
    border-bottom: none;
  }
}

.rate-entry-warning.app-alert {
  margin-top: 30px;
  max-width: 862px;
}

form {
  border-bottom: 1px solid var(--tf-gray-medium);
}

strong {
  font-weight: 600;
}

hr {
  max-width: 1108px;
  margin: 0 auto 40px;
  border-color: var(--tf-gray-medium);
}

.policy-ids-list {
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  label {
    margin-right: 5px;
  }
}

.policy-ids-input {
  text-align: left;
  max-width: 250px;
  font-size: 12px;
  color: var(--tf-gray);
}
</style>
