<template>
  <section>
    <AppResendConfirmation
      v-bind="{
        accountService,
        ...resendConfirmationProps,
        userInfo
      }"
    />
  </section>
</template>

<script>
  import { mapState } from 'pinia';
  import { useAccountStore } from '@/stores/account.js';
  // services
  import ServiceAccount from '@/services/account.js';

  /**
   * Resend Forgot Password
   *
   * @exports Authentication/ResendConfirmation
   */
  export default {
    name: 'ResendConfirmation',
    data: () => ({ accountService: ServiceAccount }),
    computed: {
      ...mapState(useAccountStore, ['userInfo']),
      /**
       * Evaluate the route params and returns an object with all of the correct data.
       *
       * @returns {object}
       */
      resendConfirmationProps() {
        const { projectId } = this.$route.params;
        const resendConfirmationProps = {
          linkConfirmation: { name: 'RegisterConfirmation' },
          linkLogin: { name: 'ProjectSignIn' },
          linkRegister: { name: 'RegistrationForm' },
        };

        if (projectId) {
          Object.keys(resendConfirmationProps).forEach((prop) => {
            resendConfirmationProps[prop] = {
              name: `Project${resendConfirmationProps[prop].name}`,
              params: { projectId },
            };
          });

          resendConfirmationProps.projectId = projectId;
        }

        return resendConfirmationProps;
      },
    },
  };
</script>
