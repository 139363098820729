<template>
  <nav>
    <div>
      <RouterLink
        v-if="carrierInfoStore.logoUrl && !isAdmin"
        :to="{ name: 'Dashboard' }"
      >
        <img
          id="carrier-logo"
          :data-test="`${carrierInfoStore?.name?.toLowerCase()} logo`"
          :src="carrierInfoStore.logoUrl"
          :alt="`${carrierInfoStore.name} logo`"
        >
      </RouterLink>
      <img
        v-if="isAdmin"
        id="admin-logo"
        src="@/assets/logo.svg"
        alt="Threeflow 3 logo"
      >
      <div
        v-if="$route.meta.pageType === 'dataEntry'"
        id="project-product-selector"
      >
        <ProjectProductSelector />
      </div>
    </div>
    <div>
      <span
        v-if="$route.meta.pageType === 'dataEntry'"
        class="proposal-name"
        v-text="employerName"
      />
      <GlobalHamburgerMenu />
    </div>
  </nav>
</template>

<script>
  import { mapStores, mapState } from 'pinia';
  import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useAccountStore } from '@/stores/account.js';
  // Components
  import ProjectProductSelector from '@/components/ProjectProductSelector/index.vue';
  import GlobalHamburgerMenu from '@/components/GlobalNavigation/GlobalHamburgerMenu.vue';

  /**
   * Global Navigation
   *
   * @exports GlobalNavigation
   */
  export default {
    name: 'GlobalNavigation',
    components: { GlobalHamburgerMenu, ProjectProductSelector },
    data: () => ({
      canClosePasswordMessageBox: false,
    }),
    computed: {
      ...mapStores(useCarrierInfoStore),
      ...mapState(useProjectStore, ['employerName']),
      ...mapState(useAccountStore, ['roleNames']),
      /**
       * does user roles contain admin
       *
       * @returns {boolean}
       */
      isAdmin() {
        return this.roleNames?.includes('admin');
      },
      /**
       * Evaluate the $route.meta.pageType to see if its a management page.
       *
       * @returns {boolean}
       */
      isManagement() {
        return this.$route.meta.pageType === 'management';
      },
      /**
       * Evaluate the $route.meta.pageType to see if its a dashboard.
       *
       * @returns {boolean}
       */
      isDashboard() {
        return this.$route.meta.pageType === 'dashboard';
      },
      /**
       * Check if the page type is a "Dashboard" or "Management" to determine what the sign out route should be.
       *
       * @returns {object}
       */
      signOutRoute() {
        let signOutRoute = { name: 'SignOut' };

        if (!this.isDashboard && !this.isManagement) {
          signOutRoute = {
            name: 'ProjectSignOut',
            params: { projectId: this.projectId },
          };
        }

        return signOutRoute;
      },
    },
  };
</script>

<style lang="scss" scoped>
  nav {
    justify-content: space-between;
    border-bottom: 1px solid $tf-light-gray;

    &,
    > div {
      display: flex;
      align-items: center;
    }
  }

  #carrier-logo, #admin-logo {
    height: 50px;
    margin-left: 20px;
  }

  #project-product-selector {
    width: 400px;
    margin-left: 30px;
  }

  .el-menu.el-menu--horizontal {
    border-bottom: 0;
  }

  .proposal-name {
    margin-right: 10px;
    font: {
      size: 16px;
      weight: bold;
    }
    color: $tf-dark-gray;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .nav-button {
    margin-right: 25px;

    :deep(.button-text > span) {
      margin-right: 10px;
    }
  }
</style>
