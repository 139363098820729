<template>
  <tr v-if="policySubtypes.subtype_names[0] || proposalSubtypes.subtype_names[0]">
    <td
      class="cell-sub-header"
      v-html="'&nbsp;'"
    />
    <!-- Don't show subtype names if renewing or if the only subtype is null  -->
    <template v-if="showPolicySubtypes">
      <!-- subtype name cell should take up 1 colspan if is equal to policy subtype count. -->
      <td
        v-for="subtype in policySubtypes.subtype_names"
        :key="`policy-${subtype}`"
        :colspan="subtypeColspan.policy / policySubtypes.subtype_names.length"
        class="cell-sub-header"
        v-text="subtype"
      />
    </template>
    <!-- Don't show subtype names if the only subtype is null  -->
    <template v-if="showProposalSubtypes">
      <!-- subtype name cell should take up 1 colspan if is equal to proposal subtype count. -->
      <td
        v-for="subtype in proposalSubtypes.subtype_names"
        :key="`proposal-${subtype}`"
        :colspan="subtypeColspan.proposal / proposalSubtypes.subtype_names.length"
        class="cell-sub-header"
        v-text="subtype || '--'"
      />
    </template>
  </tr>
</template>

<script>
  import { mapState } from 'pinia';
  import { useProductStore } from '@/stores/product.js';

  /**
   * Plan Design Attribute Grouping Title
   *
   * @exports PlanDesign/PlanDesignAttributeGroupingTitle
   */
  export default {
    name: 'PlanDesignCategoryTitle',
    inject: [
      'containerId',
      'isPlanSummary',
      'subtypeColspan',
    ],
    props: {
      /**
       * title info for the row that contains the products of the `subtypes_by_container_and_product` by Attribute Group
       */
      subtypes: {
        type: Object,
        required: true,
        default: () => {},
      },
    },
    computed: {
      ...mapState(useProductStore, ['isRenewalProduct']),
      /**
       * policy subtype for the appropriate container.
       *
       * @returns {object}
       */
      policySubtypes() {
        return this.subtypes.products[0].containers
          .find((container) => container.id === this.containerId);
      },
      /**
       * proposal subtype for the appropriate container.
       *
       * @returns {object}
       */
      proposalSubtypes() {
        return this.subtypes.products[1].containers
          .find((container) => container.id === this.containerId);
      },
      /**
       * Determines if the policy subtypes should be shown.
       * We should show the subtypes if the only policy subtype is `null`.
       *
       * @returns {boolean}
       */
      showPolicySubtypes() {
        const isValidSubtype = !this.isRenewalProduct && this.policySubtypes.subtype_names[0] !== 'null';

        return this.isPlanSummary || isValidSubtype;
      },
      /**
       * Determines if the proposal subtypes should be shown.
       * We should show the cell if there are policy subtypes as well. This is an edge case if there's incorrect data.
       *
       * @returns {boolean}
       */
      showProposalSubtypes() {
        const showProposalSubtypes = this.showPolicySubtypes
          || this.proposalSubtypes.subtype_names[0];

        return showProposalSubtypes && !this.isPlanSummary;
      },
    },
  };
</script>

<style lang="scss" scoped>
tr {
  background: $tf-light-green;
  border-right: 0;
}

td {
  height: 44px;
  padding: 0;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
</style>
