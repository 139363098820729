<template>
  <div class="document-section">
    <h4>{{ title }}</h4>
    <template v-for="sourceType in sourceGroup">
      <RfpDocumentCategory
        v-if="sourceType.sources.length"
        :key="sourceType.title"
        :file-list="sourceType.sources"
        :title="sourceType.title"
        data-test="download file"
        @downloaded="$emit('downloaded', $event)"
      />
    </template>
  </div>
</template>

<script>
  // components
  import RfpDocumentCategory from '@/components/RfpDocuments/RfpDocumentCategory.vue';

  /**
   * RFP Document Section
   *
   * @exports RFPDocuments/RfpDocumentSection
   */
  export default {
    name: 'RfpDocumentSection',
    components: { RfpDocumentCategory },
    props: {
      sourceGroup: {
        type: Array,
        default: () => [],
      },
      title: {
        type: String,
        default: null,
      },
    },
  };
</script>

<style lang="scss" scoped>
  h4 {
    margin-bottom: 7px;
    font-size: 18px;
    text-transform: uppercase;
  }

  .document-category {
    padding-bottom: 25px;
  }

  .document-section {
    padding-top: 25px;
    border-top: 1px solid $tf-extra-light-gray-1;
  }
</style>
