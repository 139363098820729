<template>
  <span class="container-prepend-slot">
    <TfProgressIcon
      v-if="showIcon"
      :is-active="isActive"
      :progress-state="progressState"
    />
  </span>
</template>

<script>
  import { mapState } from 'pinia';
  import { useProductStore } from '@/stores/product.js';

  /**
   * The Validation icon based on the product state or the stop loss progress.
   *
   * @exports ProjectProductSelector/ProgressSlot
   */
  export default {
    name: 'ProgressSlot',
    props: {
      isActive: {
        type: Boolean,
        default: false,
      },
      projectProductId: {
        type: Number,
        default: null,
      },
      showIcon: {
        type: Boolean,
        default: true,
      },
      stopLossProgress: {
        type: String,
        default: null,
      },
    },
    computed: {
      ...mapState(useProductStore, ['products']),
      /**
       * Returns `not-started`, `half`, or `full` based on the product's completion state.
       *
       * @returns {string}
       */
      productValidationState() {
        let progressIcon;

        if (!this.projectProductId) {
          return progressIcon;
        }

        const product = this.products
          .find(({ project_product: { id } }) => id === this.projectProductId);

        progressIcon = 'half';

        if (product.state === 'completed') {
          progressIcon = 'full';
        } else if (['not_started', 'declined'].includes(product.state)) {
          progressIcon = 'not-started';
        }

        return progressIcon;
      },
      /**
       * Returns the `stopLossProgress` string if passed. Otherwise returns the `productValidationState`.
       *
       * @returns {string}
       */
      progressState() {
        return this.stopLossProgress || this.productValidationState;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .container-prepend-slot {
    display: flex;
    align-items: center;
    width: 16px;
    margin-left: 5px;
  }
</style>
