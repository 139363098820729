import axios from 'axios';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

const apiUrl = config.VUE_APP_API_URL;
const cookieNameSpace = config.VUE_APP_COOKIE_NAMESPACE;

/**
 * All carrier insights related ajax calls
 *
 * @exports services/insight
 */

/**
 * Fetches looker url
 *
 * @param {number} carrierId
 * @returns {Promise} axios Promise
 */
export const getLookerUrl = ({ dashboardType, embedDomain }) => {
  const url = `${apiUrl}/v1/carrier_portal/insights/embed_url?dashboard_id=looker_external_client::external_${dashboardType}&embed_domain=${embedDomain}`;

  return axios.get(url, getAxiosConfig(cookieNameSpace))
    .then(({ data: { embed_url: embedUrl } }) => embedUrl);
};

export default { getLookerUrl };
