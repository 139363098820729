import axios from 'axios';
import { AxiosConfig } from '@watchtowerbenefits/shared-components';
import { config } from '@/utils/config.js';

const axiosConfig = () => AxiosConfig.getConfig(config.VUE_APP_COOKIE_NAMESPACE);
const normalizedEndpoint = `${config.VUE_APP_API_URL}/v1/carrier_portal`;

/**
 * All quote edits/history related ajax calls
 *
 * @exports Services/QuoteEdits
 */
export default {
  /**
   * Get proposal level quote history
   *
   * @param {number} documentId
   * @returns {Promise}
   */
  getQuoteHistory(documentId) {
    const url = `${normalizedEndpoint}/edit_history/document/${documentId}`;

    return axios.get(
      url,
      axiosConfig(),
    )
      .then(({ data }) => data);
  },
};
