import axios from 'axios';
import { config } from '@/utils/config.js';

/* eslint-disable import/prefer-default-export */

/**
 * Smart Parser/Annotation API calls
 *
 * @exports services/normalizedValues
 */

/**
 * Get normalized values from the API
 *
 * @returns {Promise}
 */
export const getCoreApiStatus = async () => {
  const url = `${config.VUE_APP_API_URL}/health_check`;

  return axios.get(url)
    .then((response) => response);
};
