<template>
  <div class="product-value-validation">
    <h4>Processed quote</h4>
    <section
      v-for="(proposals, type) in csvQuoteStore.proposalsByProductType"
      :key="type"
    >
      <h5>
        {{ type }}
      </h5>
      <ul>
        <li
          v-for="{ label, valid } of proposals"
          :key="label"
        >
          <span>
            <AppIcon
              :icon="`fa-solid ${valid ? 'fa-check' : 'fa-xmark'}`"
              :type="valid ? 'primary' : 'danger'"
              data-test="proposal validation"
            />
          </span>
          <h6>
            {{ label }}
          </h6>
          <div
            v-if="!valid"
            class="product-error"
          >
            An error occurred and this product will not be submitted.<br>
            If you would like to submit this product, delete the document and try again.
          </div>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
  // pinia
  import {
    mapActions, mapWritableState, mapStores, mapState,
  } from 'pinia';
  import { useCsvQuoteStore } from '@/stores/csvQuote.js';
  import { useProjectStore } from '@/stores/project.js';
  // services
  import ProposalService from '@/services/proposal.js';
  // utils
  import { flattenObjectValues } from '@/utils/file.js';

  /**
   * Submit sub-component for ModalQuoteFile component
   * Handles all the csv value validation and submission functionality for csv quote submission flow
   *
   * @exports Modals/QuoteFileModal/QuoteFileSubmit
   */
  export default {
    name: 'QuoteFileSubmit',
    props: {
      parentStep: {
        required: true,
        validator: (propValue) => ['upload', 'submit'].includes(propValue),
      },
    },
    computed: {
      ...mapStores(useCsvQuoteStore),
      ...mapWritableState(useCsvQuoteStore, ['alert']),
      ...mapState(useProjectStore, ['proposalDocumentId']),
    },
    /**
     * validate csv quote values when created
     */
    created() {
      const errorMessages = [];

      // on created we run the csv values validation
      ProposalService
        .validateCsvQuoteValues(this.csvQuoteStore.id, this.proposalDocumentId)
        .then(({
          csv_bid_submission: {
            bid_evaluation: {
              product_types: productTypes,
            },
          },
        }) => {
          // the values are nested pretty deeply in the response so there's a bit of
          // finagling to get to the important stuff. this should simplify if/when charlie simplifies
          // the response model at some point.
          const flattenedProductTypes = Object
            .entries(productTypes)
            .reduce((typeAcc, [productName, { product_proposals: proposals }]) => {
              // loop through proposals to pull any errors out and store them
              proposals.forEach((proposal) => {
                if (!proposal.valid) {
                  const proposalErrors = flattenObjectValues(proposal.errors);

                  errorMessages.push(...proposalErrors);
                }
              });
              const scopedAcc = { ...typeAcc };

              scopedAcc[productName] = proposals;

              return scopedAcc;
            }, {});

          this.setQuote({ proposalsByProductType: flattenedProductTypes });
          if (errorMessages.length) {
            this.alert = {
              type: 'danger',
              messages: errorMessages,
            };
          }
        })
        .catch(() => {
          this.csvQuoteStore.$reset();
          this.alert = {
            type: 'danger',
            messages: ['Something went wrong when attempting to validate the values from the uploaded CSV. Please reupload the CSV file and try again.'],
          };
        });
    },
    methods: {
      ...mapActions(useCsvQuoteStore, ['resetQuote', 'setQuote']),
    },
  };
</script>

<style lang="scss" scoped>
  :deep() {
    h5, h6 {
      font: {
        weight: 400;
        size: 16px;
      }
    }
  }

  li {
    position: relative;
    color: $tf-medium-gray;
    margin: 30px 0;
    padding-left: 22px;

    &:first-child {
      margin-top: 0;
    }

    .product-error {
      color: $tf-red;
      font-size: 12px;
    }
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 14px;
    transform: translateY(4px);
  }

</style>
