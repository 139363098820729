<template>
  <ElDialog
    append-to-body
    :title="modalTitle"
    :visible="visible"
    data-test="products unsupported by dive and threeflow assist modal"
    top="70px"
    width="500px"
    @close="closeDialog"
  >
    <p class="unsupported-products-message">
      {{ formatArrayIntoListOfStrings(tfAssistAndDiveUnsupportedProductTypes) }}
      not currently supported by Smart Proposals, so you need to enter rates and plan design manually.
    </p>
    <div class="btn-group">
      <AppButton
        data-test="close products unsupported by dive and threeflow assist modal"
        size="large"
        text="Done"
        type="primary"
        @click="closeDialog"
      />
    </div>
  </ElDialog>
</template>

<script>
  import { formatArrayIntoListOfStrings } from '@/utils/general.js';

  /**
   * Modal to alert the user that there is a product that is unsupported by DIVE and ThreeFlow Assist in the current project
   *
   * @exports Modals/ThreeFlowAssistAndDiveUnsupportedModal
   */
  export default {
    name: 'ThreeFlowAssistAndDiveUnsupportedModal',
    props: {
      /**
       * Whether the modal is visible
       */
      visible: {
        type: Boolean,
        default: false,
      },
      /**
       * Array of unique product type strings that are unsupported by DIVE and ThreeFlow Assist
       */
      tfAssistAndDiveUnsupportedProductTypes: {
        type: Array,
        required: true,
      },
    },
    computed: {
      modalTitle() {
        return `${this.tfAssistAndDiveUnsupportedProductTypes.length === 1
          ? this.tfAssistAndDiveUnsupportedProductTypes[0]
          : 'These Products'} Need${this.tfAssistAndDiveUnsupportedProductTypes.length === 1
          ? 's'
          : ''} Your Help`;
      },
    },
    methods: {
      formatArrayIntoListOfStrings,
      closeDialog() {
        this.$emit('update:visible', false);
      },
    },
  };
</script>

<style scoped>
  .unsupported-products-message {
    margin: 0 10px 25px;
  }
</style>
