import LogRocket from 'logrocket';

/**
 * returns default segment data object with all fields
 *
 * @param {object} customProperties - key/value pairs to add to payload or override exiting properties
 * @returns {object}
 */
export const segmentData = (customProperties = {}) => (
  {
    broker_id: null,
    employer_id: null,
    project_id: null,
    project_product_id: null,
    product_type_id: null,
    product_id: null,
    carrier_id: null,
    document_id: null,
    project_products_container_id: null,
    rate_attribute_id: null,
    rate_value_id: null,
    plan_design_attribute_id: null,
    plan_design_value_id: null,
    user_broker_id: null,
    user_carrier_id: null,
    target_carrier_id: null,
    target_broker_id: null,
    target_user_id: null,
    target_email: null,
    ...customProperties,
  }
);

/**
 * Utility file for segment analytics
 *
 * @exports Utils/Analytics
 */
export default { segmentData };

/**
 * Utility function to track LogRocket events
 *
 * @param {string} eventName - name of the event
 * @param {object} eventProperties - properties that will be associated with the event
 * @returns {void}
 */
export const trackLogRocketEvent = (eventName, eventProperties = {}) => {
  if (LogRocket) LogRocket.track(eventName, eventProperties);
};
