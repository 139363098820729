<template>
  <div class="top-comment">
    <CommentInput
      v-model="message"
      :api-config="apiConfig"
      :auto-save-timestamp="timeStamp"
      :project-id="projectId"
      :proposal-document-id="proposalDocumentId"
      :user-info="commentUserInfo"
      application-namespace="carrier_portal"
      custom-session-name="commentTopLevel"
      data-test="comment field"
      @commentAdded="onCommentAdded"
      @cancel="$emit('close')"
    />
  </div>
</template>

<script>
  import { mapState, mapActions } from 'pinia';
  import { useCommentsStore } from '@/stores/comments.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useAccountStore } from '@/stores/account.js';
  import { segmentData } from '@/utils/analytics.js';
  import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
  import { config } from '@/utils/config.js';

  /**
   * Comments TopComment
   *
   * @exports Comments/TopComment
   */
  export default {
    name: 'TopComment',
    props: {
      autosaveData: {
        type: Object,
        default: () => ({
          message: null,
          timestamp: null,
        }),
      },
    },
    data: () => ({
      apiConfig: {
        baseUrl: config.VUE_APP_API_URL,
        cookieNamespace: config.VUE_APP_COOKIE_NAMESPACE,
      },
      message: null,
      timeStamp: null,
    }),
    computed: {
      ...mapState(useProjectStore, ['projectId', 'proposalDocumentId']),
      ...mapState(useAccountStore, ['commentUserInfo']),
    },
    /**
     * set the message and timestamp from the auto-save data
     */
    created() {
      this.message = this.autosaveData.message;

      if (this.autosaveData.timestamp) {
        this.timeStamp = this.autosaveData.timestamp.toString();
      }
    },
    methods: {
      ...mapActions(useCommentsStore, ['addComment']),
      /**
       * Adds the comment to the store and emits to close.
       *
       * @param {Array} comments
       */
      onCommentAdded(comments) {
        this.addComment(comments[0]);
        this.$emit('close');
        trackSegmentEvent('Send comment', segmentData());
      },
    },
  };
</script>

<style lang="scss" scoped>
  .top-comment {
    .comments-panel & {
      padding-top: 15px;
      padding-left: 5px;
    }
  }

  p {
    font-weight: bold;
    margin-bottom: 2px;
  }

  .el-textarea {
    margin-top: 15px;
    margin-bottom: 15px;
  }
</style>
