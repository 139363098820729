<template>
  <div>
    <h3
      data-test="product rate guarantee header"
      class="inner-header"
    >
      Verify or change product rate guarantees
    </h3>
    <div id="rate-pass-edit">
      <p v-if="isUploadRenewalRatePass">
        Please verify and submit the product rate guarantees or select a new value from the list.
      </p>
      <p v-else>
        Product rate guarantees have been pre-populated based on their quoting history
        on ThreeFlow. Please verify and confirm the product rate guarantees or select
        a new value from the list.
      </p>
    </div>
    <div id="rate-guarantee-edit">
      <h3 v-if="!isUploadRenewalRatePass">
        Product rate guarantees - # of months
      </h3>
      <p
        v-if="fetchError"
        data-test="fetch disclaimer"
        :class="{ 'fetch-error': fetchError }"
      >
        There was an error getting rate guarantees, you can still enter them manually.
      </p>
    </div>
    <ElForm id="rate-guarantees">
      <ElFormItem
        v-for="id in selectedProducts"
        :key="id"
        :data-test="`${proposalRateGuarantees[id].name} rate guarantee`"
      >
        <p>{{ proposalRateGuarantees[id].name }}</p>
        <div :class="{ 'error': proposalRateGuarantees[id].error }">
          <TfInputWithOptions
            v-model="proposalRateGuarantees[id].rate_guarantee"
            prop="rate_guarantee_months"
            name="rate-guarantee-input"
            required
            :disabled="proposalRateGuarantees[id].name.toLowerCase() === 'stop loss'"
            :options="rateGuaranteeOptions"
            validation-type="whole-number"
            placeholder="Enter rate guarantee"
            :data-test="`edit ${proposalRateGuarantees[id].name} rate guarantee`"
            @change="inputValidator($event, id)"
          />
          <div
            v-if="proposalRateGuarantees[id].name.toLowerCase() !== 'stop loss'"
            data-test="Rate guarantee input instructions"
          >
            {{ proposalRateGuarantees[id].error
              ? 'Enter a value between 1 and 100'
              : 'Enter or select a value'
            }}
          </div>
        </div>
      </ElFormItem>
    </ElForm>
    <div class="btn-group">
      <AppButton
        v-if="!isUploadRenewalRatePass"
        data-test="back to product selection"
        type="secondary"
        size="large"
        @click="$emit('updateStep', 'one');"
      >
        Back
      </AppButton>
      <AppButton
        icon="fa-solid fa-check-circle"
        :is-disabled="!canConfirm"
        :is-loading="btnSaving"
        size="large"
        data-test="confirm rate pass renew by product"
        @click="ratePassRenew"
      >
        {{ isUploadRenewalRatePass ? 'Submit Rate Pass' : 'Verify & confirm' }}
      </AppButton>
    </div>
  </div>
</template>
<script>
  import ProductService from '@/services/product.js';
  import project from '@/mixins/project.js';

  // Pinia
  import { mapState, mapWritableState } from 'pinia';
  import { useAccountStore } from '@/stores/account.js';
  import { useProductStore } from '@/stores/product.js';
  import { useProductTableStore } from '@/stores/productTable.js';
  import { useNotificationsStore } from '@/stores/notifications.js';
  import { useCarrierInfoStore } from '@/stores/carrierInfo.js';

  import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';

  /**
   * Allows you to set the rate gurantee per individual product.
   *
   * @exports Modals/StartOrUpdateRenewalModal/SetRateGuarantee
   */
  export default {
    name: 'SetRateGuarantee',
    mixins: [project],
    props: {
      btnSaving: {
        type: Boolean,
        default: false,
      },
      products: {
        type: Array,
        default: () => [],
      },
      selectedProducts: {
        type: Array,
        default: () => [],
      },
      uploadEnhancementsEnabled: {
        type: Boolean,
        default: true,
      },
    },
    data: () => ({
      fetchError: false,
      rateGuaranteeOptions: [
        {
          normalized_value: '12',
        },
        {
          normalized_value: '24',
        },
        {
          normalized_value: '36',
        },
        {
          normalized_value: '48',
        },
      ],
    }),
    computed: {
      ...mapState(useCarrierInfoStore, { carrierId: 'id' }),
      ...mapState(useAccountStore, ['userInfo']),
      ...mapState(useProductStore, ['isUploadRenewalRatePass']),
      ...mapWritableState(useProductTableStore, ['proposalRateGuarantees']),
      ...mapWritableState(useNotificationsStore, ['allProductsSubmittedDialogVisible']),

      /**
       * Not saving and all rate guarantees are valid.
       *
       * @returns {boolean}
       */
      canConfirm() {
        return !this.btnSaving
          && this.selectedProducts
            .every((id) => !this.proposalRateGuarantees[id].error && this.proposalRateGuarantees[id].rate_guarantee !== '');
      },
    },
    methods: {
      /**
       * Validates product rate gurantee input.
       *
       * @param {string} value
       * @param {string} id
       */
      inputValidator(value, id) {
        const invalid = +value <= 0 || +value >= 100;

        this.proposalRateGuarantees[id].error = invalid;
      },
      /**
       * Saves the users changed plan design values as their defaults
       * for future projects of the same type.
       */
      async ratePassRenew() {
        this.$emit('updateBtnSaving', true);
        const selectedRateGuarantees = {
          rate_guarantees: this.selectedProducts.map((id) => ({
            id,
            rate_guarantee: this.proposalRateGuarantees[id].rate_guarantee,
          })),
        };
        const showSuccessMessage = () => {
          this.$message({
            duration: 5000,
            showClose: true,
            message: 'You’ve successfully submitted selected products for renewal. Start a conversation with the broker in Comments.',
            type: 'success',
          });
        };
        const closeModal = () => {
          this.$emit('updateBtnSaving', false);
          this.$emit('refreshAndClose');
        };

        try {
          await ProductService.ratePassRenewals(selectedRateGuarantees);

          // Send out a segment event in order to track a rate pass renewal
          trackSegmentEvent('Submit rate pass renewal', {
            product_ids: this.selectedProducts,
            project_id: +this.$route.params.projectId,
            carrier_id: this.carrierId,
            user_carrier_id: this.userInfo.id,
            user_email: this.userInfo.email,
          });

          if (this.isUploadRenewalRatePass) {
            const allAltsSubmittedOrDeclined = this.products
              .filter(({ project_product: { alternative } }) => alternative)
              .every(({ state }) => state === 'completed' || state === 'declined');

            if (allAltsSubmittedOrDeclined) {
              this.allProductsSubmittedDialogVisible = true;
            }
            closeModal();
          } else if (this.uploadEnhancementsEnabled) {
            if (this.selectedProducts.length === this.products.length) {
              showSuccessMessage();
              closeModal();
            } else {
              // selected products less than all products and need advance to step three
              this.$emit('updateBtnSaving', false);
              this.$emit('updateStep', 'three');
            }
          } else if (!this.uploadEnhancementsEnabled) {
            showSuccessMessage();
            closeModal();
          }
        } catch {
          this.$message({
            duration: 5000,
            showClose: true,
            message: 'Not all of the products you selected for renewal were successfully started.',
            type: 'error',
          });
          this.$emit('refreshAndClose');
        } finally {
          if (!this.uploadEnhancementsEnabled) {
            this.$emit('refreshAndClose');
          }
        }
      },
    },
  };
</script>
<style scoped lang="scss">
#rate-pass-edit {
  margin-bottom: 24px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

#rate-guarantees {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  div {
    width: 247px;
    margin-bottom: 20px;

    :deep(.el-input) {
      margin-bottom: 2px;
    }

    div {
      font-size: 12px;
      color: #999;
      text-align: left;
      margin: 0;
    }
  }

  .error {
    :deep(.el-textarea__inner) {
      border: 1px solid var(--tf-danger);
    }

    div {
      color: var(--tf-danger);
    }
  }

  :deep(#rate-guarantee-edit) {
    margin-bottom: 16px;

    h3 {
      font-size: 18px;
      text-align: left;
      font-weight: 700;
      margin: 0;
    }

    .fetch-error {
      margin: 0;
      color: var(--tf-danger);
    }
  }
}
</style>
