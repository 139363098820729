import axios from 'axios';
import { AxiosConfig } from '@watchtowerbenefits/shared-components';
import { config } from '@/utils/config.js';

const apiUrl = config.VUE_APP_API_URL;
const cookieNameSpace = config.VUE_APP_COOKIE_NAMESPACE;

/**
 * User API calls
 *
 * @exports services/user
 */
export default {
  /**
   * Add a new user
   *
   * @param {string} carrierId
   * @param {object} data - Data to create the new user
   * @returns {Promise}
   */
  addNewUser(carrierId, data) {
    return new Promise((resolve, reject) => {
      const axiosConfig = AxiosConfig.getConfig(cookieNameSpace);
      const url = `${apiUrl}/v1/carrier_portal/admin/carriers/${carrierId}/users`;

      axios
        .post(url, data, axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Get user details
   *
   * @param {number} carrierId
   * @param {number} userId
   * @returns {Promise}
   */
  getUserInfo(carrierId, userId) {
    return new Promise((resolve, reject) => {
      const axiosConfig = AxiosConfig.getConfig(cookieNameSpace);
      const url = `${apiUrl}/v1/carrier_portal/admin/carriers/${carrierId}/users/${userId}`;

      axios
        .get(url, axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Update an existing user
   *
   * @param {number} carrierId
   * @param {object} userId - Id of the user that we're updating
   * @param {object} data - Data to update the user
   * @returns {Promise}
   */
  updateUser(carrierId, userId, data) {
    return new Promise((resolve, reject) => {
      const axiosConfig = AxiosConfig.getConfig(cookieNameSpace);
      const url = `${apiUrl}/v1/carrier_portal/admin/carriers/${carrierId}/users/${userId}`;

      axios
        .patch(url, data, axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
