/**
 * formats a string to be lowercase with exceptions for acronyms like: "AD&D", "ASO", "50K"
 *
 * @param {string} string
 * @returns {string}
 */
export const lowercaseExceptAcronyms = (string) => string.split(/\s/).map((word) => {
  if (word === word.toUpperCase()) {
    return word;
  }

  return word.toLowerCase();
}).join(' ');

/**
 * Utility file for regex functions
 *
 * @exports Utils/Regex
 */
export default {
  lowercaseExceptAcronyms,
};
