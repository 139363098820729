<template>
  <ElDialog
    append-to-body
    title="Non-PDF file type uploaded"
    :visible="visible"
    data-test="non pdf uploaded modal"
    top="70px"
    width="500px"
    @close="closeDialog"
  >
    <h4 class="header">
      ThreeFlow only accepts PDF documents for automatic rate entry at this time.
    </h4>
    <p>
      Upload PDFs for automatic rate entry or enter rates manually. The files
      you just uploaded will be added to the project’s supplemental documents.
    </p>
    <AppButton
      data-test="upload pdf help link"
      size="text"
      tag="a"
      text="How to save my .doc or .xls as a PDF."
      href="https://support.threeflow.com/en/articles/9179732-how-do-i-save-a-document-as-a-pdf"
      target="_blank"
    />
    <div class="btn-group">
      <AppButton
        class="enter-rates-button"
        data-test="enter rates manually"
        size="large"
        text="Enter Rates Manually"
        type="secondary"
        @click="handleEnterRatesManually"
      />
      <AppButton
        data-test="upload pdf"
        size="large"
        text="Upload PDF"
        type="primary"
        @click="closeDialog"
      />
    </div>
  </ElDialog>
</template>

<script>
  /**
   * When a user uploads a non-PDF file for renewal process, this modal is displayed.
   *
   * @exports Modals/NonPdfModal
   */
  export default {
    name: 'NonPdfModal',
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      closeDialog() {
        this.$emit('update:visible', false);
      },
      handleEnterRatesManually() {
        this.$emit('submitRenewal');
        this.closeDialog();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .header {
    margin: 10px 0;
  }

  :deep(.el-icon-close:before) {
    color: var(--tf-blue);
  }

  :deep(.el-dialog__body) {
    padding: 0 40px;
  }

  :deep(.btn-group) {
    margin: 16px 0 24px;
  }
</style>
