<template>
  <AppButton
    v-if="showButton"
    class="back-to-top"
    size="large"
    icon="fa-solid fa-chevron-up"
    text="To the top"
    prepend
    @click="goToTop"
  />
</template>
<script>
/**
 * Back To Top Button
 *
 * @exports Insights/BackToTop
 */
  export default {
    name: 'BackToTop',
    data: () => ({
      timer: null,
      scrollPosition: 0,
    }),
    computed: {
      /**
       * Calculates display prop of button
       *
       * @returns {boolean}
       */
      showButton() {
        return this.scrollPosition > 250;
      },
    },
    /**
     * Adds scroll listener
     */
    created() {
      window.addEventListener('scroll', this.handleScroll);
    },
    /**
     * Removes scroll listener and clears timeout
     */
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll, false);
      clearTimeout(this.timer);
    },
    methods: {
      /**
       * scroll event handler
       */
      handleScroll() {
        if (Number.isInteger(this.timer)) return;
        this.timer = setTimeout(() => {
          this.scrollPosition = window.scrollY;
          clearTimeout(this.timer);
          this.timer = null;
        }, 100);
      },
      /**
       * Scrolls to top of page on click
       */
      goToTop() {
        const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
        const behavior = mediaQuery?.matches
          ? 'auto'
          : 'smooth';

        window.scrollTo({
          top: 0,
          behavior,
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
.back-to-top {
  position: fixed;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}
</style>
