<template>
  <div>
    <DashboardTable
      dashboard-type="all"
      data-test="All opportunities table"
      data-return-key="all_projects"
      title="All opportunities"
    />
  </div>
</template>

<script>
  import DashboardTable from '@/components/DashboardTable/index.vue';

  /**
   * Table to display all the Proposal document for this carrier organization that is still active.
   *
   * @exports Dashboards/AllOpportunities
   */
  export default {
    name: 'AllOpportunities',
    components: { DashboardTable },
  };
</script>
