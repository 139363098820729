import Vue from 'vue';
import axios from 'axios';
import { useAccountStore } from '@/stores/account.js';
import { setCookie } from '@watchtowerbenefits/es-utils-public';
import { AxiosConfig } from '@watchtowerbenefits/shared-components';
import { config } from '@/utils/config.js';
import { workOsCarrierUi } from '@/utils/featureFlags.js';

const normalizedEndpoint = `${config.VUE_APP_API_URL}/v1/carrier_portal`;
const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;
const axiosConfig = () => AxiosConfig.getConfig(cookieNamespace);

/**
 * All account related ajax calls
 *
 * @exports Services/Account
 */
export default {
  /**
   * Check if a specific user was invited a project
   *
   * @param {string} email
   * @param {number} projectId
   * @returns {Promise}
   */
  checkInvitedToProject(email, projectId) {
    const encodedEmail = encodeURIComponent(email);
    const url = `${normalizedEndpoint}/users/invited_to_project?email=${encodedEmail}&project_id=${projectId}`;

    return axios.get(url, axiosConfig())
      .then(({ data }) => data);
  },

  /**
   * Pass in the user credentials and validate them against the api server
   *
   * @param {object} data
   * @param {boolean} rememberMe
   * @returns {Promise}
   */
  authenticate(data, rememberMe) {
    const { email } = data;
    const url = `${normalizedEndpoint}/sign_in`;

    return axios
      .post(url, data)
      .then(({ data: authData }) => {
        if (rememberMe) {
          window.localStorage.setItem('email', email);
        } else {
          window.localStorage.removeItem('email');
        }
        this.setSignedIn(authData);

        return authData;
      });
  },

  /**
   * Sign the user out.
   *
   * @returns {Promise}
   */
  signOut() {
    const url = `${normalizedEndpoint}/sign_out`;

    return axios.post(url, {}, axiosConfig())
      .then(({ data }) => data);
  },

  /**
   * Gets current user's information
   *
   * @param {string} authToken (optional) - if not passed, will use the cookie
   * @returns {Promise}
   */
  getCurrentUser(authToken) {
    const headers = authToken
      ? { headers: { 'X-API-AUTHTOKEN': `${authToken}` } }
      : axiosConfig();
    const url = `${config.VUE_APP_API_URL}/v1/users/me`;

    return axios.get(url, headers)
      .then(({ data }) => data);
  },

  /**
   * Registering a brand new user
   *
   * @param {object} data
   * @param {string} data.department
   * @param {string} data.email
   * @param {string} data.firstName
   * @param {string} data.lastName
   * @param {string} data.password
   * @param {string} data.projectId
   * @returns {Promise}
   */
  registerUser({
    department,
    email,
    firstName,
    lastName,
    password,
    projectId,
  }) {
    const url = `${normalizedEndpoint}/users`;

    return axios.post(url, {
      department,
      email,
      first_name: firstName,
      last_name: lastName,
      password,
      project_id: projectId,
    })
      .then(({ data }) => {
        this.setSignedIn(data);

        return data;
      });
  },

  /**
   * Resend the confirmation email for a user.
   *
   * @param {object} data
   * @param {string} data.email
   * @param {number} data.projectId
   * @returns {Promise}
   */
  resendConfirmation({ email, projectId }) {
    const url = `${normalizedEndpoint}/send_confirmation`;

    return axios
      .post(
        url,
        { email, project_id: projectId },
      )
      .then(({ data }) => data);
  },

  /**
   * Send the user a new temp password because they forgot theirs.
   *
   * @param {object} data
   * @param {string} data.email
   * @returns {Promise}
   */
  passwordReset({ email }) {
    const url = `${normalizedEndpoint}/forgot_password`;

    return axios
      .post(
        url,
        { email },
      )
      .then(({ data }) => data)
      .catch(() => { });
  },

  /**
   * Changing the users password
   *
   * @param {object} data
   * @returns {Promise}
   */
  changePassword(data) {
    const url = `${normalizedEndpoint}/reset_password`;

    return axios
      .put(url, data, axiosConfig())
      .then(({ data: passwordData }) => passwordData);
  },

  /**
   * Confirming a user that comes from the confirmation email
   *
   * @param {object} data
   * @returns {Promise}
   */
  confirmEmail(data) {
    const url = `${normalizedEndpoint}/confirm`;

    return axios.post(url, {
      project_id: data.projectId,
      confirmation_token: data.token,
    })
      .then(({ data: emailData }) => {
        this.setSignedIn(emailData);

        return emailData;
      });
  },

  /**
   * Setting all the appropriate info for signing in a user: localStorage/cookies and store states
   *
   * @param {object} data
   * @param {object} data.user
   * boolean
   */
  setSignedIn({ user }) {
    const accountStore = useAccountStore();

    accountStore.setUserInfo(user);
    window.localStorage.setItem('auth', true);
    window.localStorage.setItem('confirmed', user.confirmed);

    if (user.auth_token) {
      /* Set Cookie of Bearer Token and UserID */
      const expirationTime = new Date(user.auth_token_expires_at);

      setCookie(
        `${cookieNamespace}-auth-token`,
        user.auth_token,
        expirationTime,
      );
      setCookie(
        `${cookieNamespace}-user-roles`,
        user.roles.map((role) => role.name),
        expirationTime,
      );
    }
  },
  /**
   * Sign the user in based on a Google OAuth session code, set localStorage, and load the user info.
   *
   * @param {string} code
   * @returns {Promise}
   */
  signInWithGoogle(code) {
    const url = `${config.VUE_APP_API_URL}/v1/omniauth/google/callback`;

    return axios.post(url, { code }, axiosConfig())
      .then(({ data: user }) => this.setSignedIn(user));
  },
  /**
   * Update a users' Default Plan Design Values (pdv)
   *
   * @param {number} productTypeId
   * @param {object} data - Data to update the user's PDVs
   * @returns {Promise}
   */
  saveUserPredefinedPlanDesignValues(productTypeId, data) {
    const url = `${normalizedEndpoint}/users/preferred_plan_design_values`;
    const payload = {
      product_type_id: productTypeId,
      preferences: Object.values(data),
    };

    return axios.post(url, payload, axiosConfig())
      .then((response) => response);
  },
  /**
   * Gets a list of a users' product_type_ids with Default Plan Design Values (pdv)
   *
   * @returns {Promise}
   */
  getProductsWithPreferredPlanDesignValues() {
    // # Get /v1/carrier_portal/users/products_with_preferred_plan_design_values
    const url = `${normalizedEndpoint}/users/products_with_preferred_plan_design_values`;

    return axios.get(url, axiosConfig())
      .then(({ data }) => data.product_type_ids);
  },
  /**
   * Get the provider for the provided email address.
   *
   * @param {string} email
   * @returns {object}
   */
  async getUserProvider(email) {
    // rff: workOsCarrierUi https://watchtower.atlassian.net/browse/LC-2036
    const url = `${config.VUE_APP_API_URL}/v1/users/provider?email=${email}${Vue.prototype.$ld.checkFlags(workOsCarrierUi) ? `&url=${window.location.origin}/sso` : ''}`;

    return axios.get(url, axiosConfig())
      .then(({ data }) => data);
  },
};
