import { defineStore } from 'pinia';
import QuoteEditsService from '@/services/quoteEdits.js';

export const useQuoteEditsStore = defineStore('quoteEdits', {
  state: () => ({
    quoteEditsError: false,
    quoteEditsFetched: false,
    quoteEditsPanel: {
      visible: false,
      open: false,
    },
    dates: null,
    productFiltersSelected: [],
    authorFiltersSelected: [],
  }),

  getters: {},

  actions: {
    /**
     * reset to initial state
     */
    resetQuoteEditState() {
      this.$reset();
    },
    /**
     * retrieves edits and sets them
     *
     * @param {number} proposalId
     */
    async getQuoteEdits(proposalId) {
      try {
        const { document } = await QuoteEditsService.getQuoteHistory(proposalId);

        this.setQuoteEdits(document.edit_history);
        this.setQuoteEditsError();
        this.setQuoteEditStatus(true);
      } catch {
        this.setQuoteEditsError(true);
      }
    },
    /**
     * Sorts the edits
     *
     * @param {Array} edits
     */
    setQuoteEdits(edits = []) {
      edits.sort((a, b) => {
        const A = new Date(a.last_created_at);
        const B = new Date(b.last_created_at);

        if (A < B) {
          return -1;
        }
        if (A > B) {
          return 1;
        }

        return 0;
      });
      // created_at
      this.quoteEdits = edits;
      const quoteDates = {};

      this.dates = [];

      edits.forEach((edit) => {
        const date = new Date(edit.last_created_at);

        date.setTime(date.getTime() - date.getTimezoneOffset() * 60 * 1000);

        const label = `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`;

        if (!quoteDates[label]) {
          quoteDates[label] = [];
        }

        quoteDates[label].unshift(edit);
      });

      Object.keys(quoteDates).forEach((date) => {
        this.dates.unshift(quoteDates[date]);
      });
    },
    /**
     * Sets the visible and open booleans for panel by setting one first then waiting
     * for the open/close animation to finish (500ms) before setting the other. We delay
     * in order to add it to the dom and then trigger a class change and a transition
     *
     * @param {boolean} open - the boolean to set the states to
     */
    toggleQuoteEditsPanel(open) {
      if (open) {
        this.quoteEditsPanel.visible = open;
        setTimeout(() => { this.quoteEditsPanel.open = open; }, 500);
      } else {
        this.quoteEditsPanel.open = open;
        setTimeout(() => { this.quoteEditsPanel.visible = open; }, 500);
      }
    },
    /**
     * Sets the quoteEditsError state
     *
     * @param {boolean} error
     */
    setQuoteEditsError(error = false) {
      this.quoteEditsError = error;
    },
    /**
     * Sets the setQuoteEditStatus state
     *
     * @param {boolean} status
     */
    setQuoteEditStatus(status = false) {
      this.quoteEditsFetched = status;
    },
    /**
     * sets the filter for the chosen authors
     *
     * @param {Array} filters
     */
    setAuthorsFiltersSelected(filters = []) {
      this.authorFiltersSelected = filters;
    },
    /**
     * sets the filter for the chosen products
     *
     * @param {Array} filters
     */
    setProductsFiltersSelected(filters = []) {
      this.productFiltersSelected = filters;
    },
  },
});

export default {
  useQuoteEditsStore,
};
