<template>
  <header>
    <h3>Comments</h3>
    <p>Post updates or questions to the broker team. You’ll see their responses here.</p>
    <div
      v-show="!showTopCommentBox"
      class="btn-container"
    >
      <AppButton
        data-test="create new comment"
        icon="fa-solid fa-comment"
        size="large"
        text="Send comment"
        @click="toggleTopComment"
      />
    </div>

    <TopComment
      v-show="showTopCommentBox"
      :autosave-data="autosaveData"
      data-test="new comment section"
      @close="onClose"
    />
  </header>
</template>

<script>
  import { mapState } from 'pinia';
  import { useProjectStore } from '@/stores/project.js';
  import TopComment from './TopComment.vue';
  /**
   * Comments Header
   *
   * @exports Comments/CommentsHeader
   */
  export default {
    name: 'CommentsHeader',
    components: { TopComment },
    data: () => ({
      autosaveData: {},
      showTopCommentBox: false,
    }),
    computed: {
      ...mapState(useProjectStore, ['projectId']),
    },
    /**
     * get session storage if any exists
     */
    created() {
      this.getSessionStorage();
    },
    methods: {
      /**
       * Get any top level comments, set them in the store and toggle the comment open.
       */
      getSessionStorage() {
        const savedComment = window.sessionStorage.getItem('commentTopLevel');

        if (savedComment) {
          const commentObject = JSON.parse(savedComment);
          const isDifferentProject = +commentObject.projectId !== +this.projectId;

          if (isDifferentProject) {
            window.sessionStorage.removeItem('commentTopLevel');

            return;
          }

          this.$set(this, 'autosaveData', commentObject);
          this.toggleTopComment();
        }
      },
      /**
       * Clear out the local autosave data and toggles the top comment component.
       */
      onClose() {
        this.autosaveData.message = null;
        this.autosaveData.timestamp = null;
        this.toggleTopComment();
      },
      /**
       * Toggles the top comment component
       */
      toggleTopComment() {
        this.showTopCommentBox = !this.showTopCommentBox;
      },
    },
  };
</script>

<style lang="scss" scoped>
  header {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px $tf-extra-light-gray-1;

    .is-panel & {
      margin-bottom: 21px;
      padding-bottom: 19px;
    }
  }

  h3 {
    margin-bottom: 10px;

    .is-panel & {
      text-align: center;
      margin-top: 17px;
      font-size: 24px;
    }
  }

  p {
    margin-bottom: 20px;
    color: $tf-medium-gray;
    padding-bottom: 20px;
    border-bottom: 1px solid $tf-extra-light-gray-1;
  }

  .btn-container {
    text-align: center;
  }
</style>
