import Vue from 'vue';

/**
 * Utility file for vue filters
 *
 * @exports Utils/Filters
 */
export default {
  ageBanded: Vue.filter('ageBanded', (value) => {
    if (value === 'age_80_plus') {
      return '80+';
    }

    return value.replace('age_', '').replace('_', '-');
  }),
  capitalize: Vue.filter('capitalize', (value) => value.charAt(0).toUpperCase() + value.slice(1)),
  tierGroupName: Vue.filter('tierGroupName', (value) => {
    let name = value;

    switch (value) {
      case 'CompositeRateValue':
        name = 'Composite';
        break;
      case 'AgeBandedRateValue':
        name = 'Age banded';
        break;
      case 'CustomRateValue':
        name = 'Custom';
        break;
      default:
        name = value;
    }

    return name;
  }),
};
