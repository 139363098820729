<template>
  <div class="management-content edit-user">
    <ManagementHeader
      v-if="showBreadcrumb"
      :breadcrumb="{
        name: 'EditCarrier',
        params: { carrierId: orgId }
      }"
      :breadcrumb-text="`Back to ${org.name}`"
    >
      <template v-if="userId">
        <!-- User Info/name -->
        <template v-if="displayName">
          User info &ndash; {{ displayName }}
        </template>
      </template>
      <template v-else>
        Add new
        <span>
          <span v-text="userType" />
          user &ndash; {{ org.name }}
        </span>
      </template>
    </ManagementHeader>
    <AppAlert
      v-if="loadingError"
      :description="loadingError"
      data-test="loading edit user error"
      show-icon
      type="danger"
    />
    <template v-else>
      <TfBadge
        v-if="userId && !localUser.active && !userIsLoading"
        class="deactivated-badge"
        data-test="deactivated badge"
        size="small"
        status="warn"
        value="Deactivated"
      />
      <ElForm
        ref="userForm"
        :rules="rules"
        :model="localUser"
        label-position="top"
        @submit.native.prevent="saveUserInfo"
      >
        <ElFormItem
          for="first-name-input"
          prop="first_name"
          class="first-name-container"
          label="First name"
          size="medium"
        >
          <ElInput
            v-model="localUser.first_name"
            :disabled="formSubmitting"
            name="first-name-input"
            placeholder="Enter first name"
            data-test="edit first name"
          />
        </ElFormItem>
        <ElFormItem
          for="last-name-input"
          prop="last_name"
          class="last-name-container"
          label="Last name"
          size="medium"
        >
          <ElInput
            v-model="localUser.last_name"
            :disabled="formSubmitting"
            name="last-name-input"
            placeholder="Enter last name"
            data-test="edit last name"
          />
        </ElFormItem>
        <ElFormItem
          for="email-input"
          prop="email"
          class="email-container"
          label="Email"
          size="medium"
        >
          <ElInput
            v-model="localUser.email"
            :disabled="formSubmitting"
            name="email-input"
            placeholder="Enter email"
            data-test="edit email"
          />
        </ElFormItem>
        <ElFormItem
          for="department-select"
          prop="department"
          class="department-container"
          label="Roles"
          size="medium"
        >
          <ElSelect
            v-model="localUser.department"
            :disabled="formSubmitting"
            placeholder="Select role"
            data-test="select department"
          >
            <ElOption
              v-for="role in roles"
              :key="role"
              :label="role"
              :value="role"
              :data-test="`select ${role.toLowerCase()} role`"
            />
          </ElSelect>
        </ElFormItem>
        <ElFormItem
          v-if="!userId"
          data-test="temp password"
          for="password-input"
          prop="tempPassword"
          class="password-container"
          label="Temporary password"
          size="medium"
        >
          <p>{{ tempPassword }}</p>
        </ElFormItem>
        <ElFormItem
          prop="isCarrierExecutive"
          class="org-admin-container"
          label="Carrier executive"
        >
          <ElSwitch
            v-model="isCarrierExecutive"
            :disabled="formSubmitting"
            data-test="toggle carrier executive"
          />
        </ElFormItem>
        <div class="btn-group align-start form-actions">
          <AppButton
            :is-loading="formSubmitting"
            data-test="submit update user"
            icon="fa-solid fa-check"
            native-type="submit"
            :text="userId ? 'Save user' : 'Add user'"
          />
        </div>
      </ElForm>
    </template>
  </div>
</template>

<script>
  import { useCarriersStore } from '@/stores/carriers.js';
  import { mapState } from 'pinia';
  import ManagementHeader from '@/components/Management/Header.vue';
  import UserService from '@/services/user.js';
  import { generatePassword } from '@/utils/general.js';

  /**
   * When signed in as an admin on a carrier info page, the user can edit/add existing users.
   *
   * @exports EditUser
   */
  export default {
    name: 'EditUser',
    components: { ManagementHeader },
    props: {
      orgId: {
        type: [Number, String],
        required: true,
      },
      showBreadcrumb: {
        type: Boolean,
        default: true,
      },
      userId: {
        type: [Number, String],
        default: null,
      },
      userType: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        loadingError: null,
        formSubmitting: false,
        localUser: {},
        localUserRoles: [],
        roles: [
          'Executive or Corporate Sponsor',
          'Regional Leader',
          'Sales Executive',
          'Sales Coordinator',
          'Underwriter',
          'Account Manager',
        ],
        rules: {
          first_name: {
            required: true, type: 'string', message: 'Please enter the user\'s first name.', trigger: 'none',
          },
          last_name: {
            required: true, type: 'string', message: 'Please enter the user\'s last name.', trigger: 'none',
          },
          email: [
            {
              required: true, type: 'email', message: 'Please enter a valid email.', trigger: 'none',
            },
          ],
          department: {
            required: true, type: 'string', message: 'Roles is required.', trigger: 'manual',
          },
        },
        tempPassword: generatePassword(),
        userIsLoading: true,
      };
    },
    computed: {
      ...mapState(useCarriersStore, ['carriers']),
      /**
       * Create a v-model-able boolean representation of the carrier executive role.
       *
       * @returns {boolean}
       */
      isCarrierExecutive: {
        /**
         * returns the carrier_executive role
         *
         * @returns {boolean}
         */
        get() {
          return this.localUserRoles.includes('carrier_executive');
        },
        /**
         * returns the carrier_executive role
         *
         * @param {string} value
         */
        set(value) {
          if (value && !this.isCarrierExecutive) {
            this.localUserRoles.push('carrier_executive');
          } else {
            const index = this.localUserRoles.indexOf('carrier_executive');

            this.localUserRoles.splice(index, 1);
          }
        },
      },
      /**
       * Create display name based on user first/last name.
       *
       * @returns {string}
       */
      displayName() {
        return this.localUser.id
          ? `${this.localUser.first_name} ${this.localUser.last_name}`
          : null;
      },
      /**
       * When we load the current carrier
       *
       * @returns {object|null} returns a carrier
       */
      org() {
        return this.carriers.find(({ id }) => id === Number(this.orgId));
      },
    },
    /**
     * Get the user info and set the data correctly.
     */
    async created() {
      if (this.userId) {
        try {
          const { user } = await UserService.getUserInfo(+this.currentCarrierId, this.userId);

          this.$set(this, 'localUser', user);
          this.$set(this, 'localUserRoles', this.localUser.roles.map(({ name }) => name));
        } catch {
          this.loadingError = 'There was an error getting the user info.';
        } finally {
          this.userIsLoading = false;
        }
      } else {
        this.$set(
          this,
          'localUser',
          {
            email: '',
            first_name: '',
            last_name: '',
            department: '',
          },
        );
      }
    },
    methods: {
      /**
       * Save the info for this user
       *
       */
      saveUserInfo() {
        if (this.formSubmitting) {
          return;
        }

        this.$refs.userForm.validate((valid) => {
          if (valid) {
            this.formSubmitting = true;
            const userData = {
              email: this.localUser.email,
              first_name: this.localUser.first_name,
              last_name: this.localUser.last_name,
              user_type: 'carrier',
              roles: [...this.localUserRoles],
              carrier_id: this.orgId,
              department: this.localUser.department,
            };

            if (!userData.roles.includes('carrier')) {
              userData.roles.push('carrier');
            }

            // if this is a new user
            if (!this.userId) {
              userData.confirmed = true;
              userData.password = this.tempPassword;

              UserService
                .addNewUser(+this.orgId, userData)
                .then(({ user }) => {
                  this.formSubmitting = false;
                  this.$set(this, 'localUser', { active: true });
                  this.$message({
                    message: 'User info has been saved.',
                    type: 'success',
                  });

                  this.$router.replace({
                    name: 'EditCarrierUser',
                    params: { orgId: this.orgId, userId: user.id },
                  });
                })
                .catch(() => {
                  this.$message({
                    message: 'There was an error saving the user. Please try again.',
                    type: 'error',
                  });
                  this.formSubmitting = false;
                });
            } else {
              UserService
                .updateUser(+this.orgId, this.userId, userData)
                .then(({ user }) => {
                  this.$set(this, 'localUser', user);
                  this.formSubmitting = false;
                  this.$message({
                    message: 'User info has been saved.',
                    type: 'success',
                  });
                })
                .catch(() => {
                  this.$message({
                    message: 'There was an error saving the user. Please try again.',
                    type: 'error',
                  });
                  this.formSubmitting = false;
                });
            }
          }
        });
      },
    },
  };
</script>

<style scoped>
.edit-user {
  margin-top: -5px;
  padding: 0 0 20px;
}

.deactivated-badge {
  margin-bottom: 10px;
}

.el-form {
  max-width: 500px;
}

.el-select {
  display: block;
}

:deep(.is-required .el-form-item__label:before) {
  display: none;
}

:deep(.el-form-item__error) {
  /* stylelint-disable declaration-no-important  */
  padding-top: 4px !important;
  /* stylelint-enable declaration-no-important  */
}
</style>
