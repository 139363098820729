<template>
  <div class="comments-tab">
    <CommentsContainer />
  </div>
</template>

<script>
  import { mapState, mapActions } from 'pinia';
  import { useCommentsStore } from '@/stores/comments.js';
  import CommentsContainer from '@/components/Comments/index.vue';
  import { useProjectStore } from '@/stores/project.js';

  /**
   * RFP Overview Comments
   *
   * @exports RfpOverview/ProjectOverviewComments
   */
  export default {
    name: 'ProjectOverviewComments',
    components: { CommentsContainer },
    computed: {
      ...mapState(useCommentsStore, ['showNewBadge', 'unreadComments']),
      ...mapState(useProjectStore, ['projectId']),
    },

    /**
     * clear comment notifications and new badges
     */
    beforeDestroy() {
      if (this.unreadComments) {
        this.clearCommentNotifications(this.projectId);
        this.setShowNewBadge();
      }
    },

    methods: {
      ...mapActions(useCommentsStore, ['setShowNewBadge', 'clearCommentNotifications']),
    },
  };
</script>

<style lang="scss" scoped>
  section {
    padding: 0 20px;
  }
</style>
