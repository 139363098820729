<template>
  <div>
    <div class="dropdown-column">
      <div class="dropdown-header">
        Views
      </div>
      <ElSelect
        v-model="selectedChart"
        placeholder="Select a view"
        @change="chartChange"
      >
        <ElOption
          v-for="{id, name} in charts"
          :key="id"
          :label="name"
          :value="id"
        />
      </ElSelect>
    </div>

    <AppAlert
      v-if="errorMessage"
      :closable="false"
      data-test="insights error disclaimer"
      type="danger"
      show-icon
    >
      <span class="error-disclaimer">{{ errorMessage }}&nbsp;<TfCtaEmailSupport />.</span>
    </AppAlert>

    <div
      v-loading.body="isLoading && !Boolean(errorMessage)"
      :element-loading-text="`Loading ${splitChartName}...`"
      data-test="Insight View Dashboard"
    >
      <iframe
        v-show="!isLoading && !Boolean(errorMessage)"
        id="looker"
        data-test="looker information"
        scrolling="no"
        :style="{ height: iframeHeight }"
        :src="embedUrl"
      />
    </div>
    <BackToTop />
  </div>
</template>

<script>
  import BackToTop from '@/components/Insights/BackToTop.vue';
  import { getLookerUrl } from '@/services/insights.js';
  import { segmentData } from '@/utils/analytics.js';
  import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';

  /**
   * Dashboard for Carrier Insights.
   *
   * @exports Dashboard/Insights
   */
  export default {
    name: 'CarrierInsights',
    components: { BackToTop },
    data: (vm) => ({
      lookerEventOrigin: '',
      charts: [
        {
          id: 'carrier_business_overview',
          name: 'Book of business',
        },
        {
          id: 'carrier_new_business',
          name: 'New business',
        },
        {
          id: 'carrier_renewal_business',
          name: 'Renewal business',
        },
      ],
      embedUrl: '',
      embedDomain: window.location.origin,
      errorMessage: '',
      isLoading: true,
      pageChange: null,
      selectedChart: vm.$route.query.view || 'carrier_business_overview',
    }),
    computed: {
      /**
       * Sets iframe height based on chart selection
       *
       * @returns {string}
       */
      iframeHeight() {
        return `${this.pageChange}px`;
      },
      /**
       * splits selectedChart
       *
       * @returns {string}
       */
      splitChartName() {
        return this.selectedChart.replace(/_/g, ' ');
      },
    },
    /**
     * Fetches looker url, and sets listener for looker events
     */
    created() {
      window.addEventListener('message', this.lookerEvent);
      this.changeRouteParams({ view: this.selectedChart });
      this.fetchUrl();
    },
    /**
     * Remove listener for looker events, and timer to null
     */
    beforeDestroy() {
      window.removeEventListener('message', this.lookerEvent, false);
      this.resetTimers();
    },
    methods: {
      /**
       * Push the passed param to the router.
       *
       * @param {object} params
       */
      changeRouteParams(params) {
        const query = { ...this.$route.query, ...params };

        // this is to prevent a redundant route push that VueRouter now warns us about
        if (params.view === this.$route.query.view) return;
        this.$router.replace({ query });
      },
      /**
       * Sets selectedChart based on change event then fetches new url
       *
       * @param {string} view
       */
      chartChange(view) {
        this.resetTimers();
        this.selectedChart = view;
        this.changeRouteParams({ view: this.selectedChart });
        this.fetchUrl();
      },
      /**
       * Sets loading state and fetches looker url based on dropdown value
       */
      fetchUrl() {
        performance.mark('start');
        this.errorMessage = '';
        this.isLoading = true;
        getLookerUrl({ dashboardType: this.selectedChart, embedDomain: this.embedDomain })
          .then((embedUrl) => {
            this.embedUrl = embedUrl;
            this.lookerEventOrigin = new URL(embedUrl).origin;
          })
          .catch((error) => {
            this.errorMessage = `${error.message} please refresh page or `;
            this.isLoading = false;
          });
      },
      /**
       * returns duration in seconds from start mark to mark parameter
       *
       * @param {string} mark - name of mark
       * @returns {string} results in seconds or no duration message
       */
      getDuration(mark) {
        const { duration } = performance.measure(mark, 'start', mark);

        return duration ? Math.round(duration / 10) / 100 : null; // rounded to the nearest 100th second (2438.9403854ms -> 2.44s)
      },
      /**
       * Called by window message listener to set iframe height
       *
       * @param {Event} event
       */
      lookerEvent(event) {
        // list of looker embed events: https://docs.looker.com/reference/embedding/embed-javascript-events
        if (event.origin === this.lookerEventOrigin) {
          const data = JSON.parse(event.data);
          const dashboardId = data.dashboard?.id.replace(/.*external_/g, '');

          switch (data.type) {
          case 'page:properties:changed':
            this.pageChange = data.height;
            break;

          case 'dashboard:run:start':
            this.isLoading = false;
            // events from a previous chart can still be sent through the listener
            // but we don't want to capture those analytics if we've moved into a
            // different chart
            if (this.selectedChart !== dashboardId) return;

            performance.mark('firstSeen');
            trackSegmentEvent('Insights iframe appeared', segmentData({
              view: this.selectedChart,
              filters: data.dashboard.dashboard_filters,
              seconds: this.getDuration('firstSeen'),
            }));
            break;

          case 'dashboard:download':
            trackSegmentEvent('Insights PDF download', segmentData({
              view: this.selectedChart,
              title: data.dashboard.title,
            }));
            break;

          case 'dashboard:run:complete':
            // events from a previous chart can still be sent through the listener
            // but we don't want to capture those analytics if we've moved into a
            // different chart
            if (this.selectedChart !== dashboardId) return;

            performance.mark('loaded');
            trackSegmentEvent('Insights iframe finished loading', segmentData({
              view: this.selectedChart,
              filters: data.dashboard.dashboard_filters,
              seconds: this.getDuration('loaded'),
            }));
            break;

          default:
          }
        }
      },
      /**
       * Set all timer info to null
       */
      resetTimers() {
        performance.clearMarks();
        performance.clearMeasures();
        performance.clearResourceTimings();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .el-spinner {
    margin: 0 auto;
    display: table;
  }

  iframe {
    width: 100%;
    border: none;
    overflow: hidden;
  }

  .dropdown-column {
    width: 200px;
    margin: 30px 0;
    display: flex;
    flex-direction: column;

    .dropdown-header {
      font-weight: bold;
    }
  }

  .error-disclaimer {
    display: flex;
  }
</style>
