export default {
  admin: {
    name: 'CarrierManagement',
  },
  carrier_executive: {
    name: 'Dashboard',
  },
  carrier: {
    name: 'Dashboard',
  },
};
