<template>
  <ElMenu
    ref="main-menu"
    :default-active="$route.meta.pageType === 'dataEntry'
      ? $route.fullPath
      : $route.name
    "
    :router="true"
    class="main-header-menu"
    mode="horizontal"
    menu-trigger="click"
  >
    <TfHamburgerMenu
      id="global-hamburger-menu"
      tabindex="0"
      :badge-count="notificationCount"
      data-test="hamburger menu"
      @hide="closeHamburgerMenu"
    >
      <template v-if="$route.params.projectId">
        <TfHamburgerMenuItem
          v-for="({ badgeCount, name, route, title }) in navigationItems"
          :key="name"
          :data-test="`to ${name}`"
          v-bind="{
            badgeCount,
            title,
            to: route,
          }"
          type="parent"
          tabindex="0"
          @click.native="sendAnalytics(title)"
        />
      </template>
      <TfHamburgerMenuItem
        v-if="isAdminAndMore"
        tabindex="0"
        :to="{ name: 'CarrierManagement' }"
        title="Carrier management"
        type="parent"
      />
      <TfHamburgerMenuItem
        v-if="!isDashboard"
        tabindex="0"
        :to="{ name: 'Dashboard' }"
        title="Home"
        type="parent"
        data-test="to home"
      />
      <TfHamburgerMenuItem
        tabindex="0"
        :to="{ name: 'Support' }"
        title="Support"
        type="parent"
        data-test="to threeflow support"
        @click.native="sendAnalytics('Support')"
      />
      <TfHamburgerMenuItem
        :index="signOutRoute.name"
        tabindex="0"
        class="signOut"
        :to="signOutRoute"
        title="Sign out"
        type="parent"
        data-test="sign user out"
      />
    </TfHamburgerMenu>
  </ElMenu>
</template>

<script>
  // pinia
  import { useNotificationsStore } from '@/stores/notifications.js';
  import { useBrokerUpdatesStore } from '@/stores/brokerUpdates.js';
  import { useCommentsStore } from '@/stores/comments.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useAccountStore } from '@/stores/account.js';
  import { mapState } from 'pinia';
  // utils
  import { segmentData } from '@/utils/analytics.js';
  import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
  // mixins
  import rfpOverview from '@/mixins/rfpOverview.js';

  /**
   * Global Navigation
   *
   * @exports GlobalNavigation/GlobalHamburgerMenu
   */
  export default {
    name: 'GlobalHamburgerMenu',
    mixins: [rfpOverview],
    /**
     * send entire vue component instance
     *
     * @returns {object} the menu
     */
    provide() {
      return {
        menu: this,
      };
    },
    computed: {
      ...mapState(useBrokerUpdatesStore, { unreadBrokerUpdates: 'notifications' }),
      ...mapState(useNotificationsStore, ['unreadEdits']),
      ...mapState(useCommentsStore, ['unreadComments']),
      ...mapState(useProjectStore, ['projectId', 'employerName', 'proposalDocumentId']),
      ...mapState(useAccountStore, ['roleNames']),
      /**
       * determines if user has admin and more roles
       *
       * @returns {boolean}
       */
      isAdminAndMore() {
        return this.roleNames.length > 1 && this.roleNames.includes('admin');
      },
      /**
       * Adds the unread notifications for non-dashboard pages.
       *
       * @returns {number}
       */
      notificationCount() {
        return !this.isDashboard && !this.isManagement
          ? this.unreadBrokerUpdates
            + this.unreadComments
            + this.unreadEdits
          : 0;
      },

      /**
       * Evaluate the $route.meta.pageType to see if its a management page.
       *
       * @returns {boolean}
       */
      isManagement() {
        return this.$route.meta.pageType === 'management';
      },
      /**
       * Evaluate the $route.meta.pageType to see if its a dashboard.
       *
       * @returns {boolean}
       */
      isDashboard() {
        return this.$route.meta.pageType === 'dashboard';
      },

      /**
       * Check if the page type is a "Dashboard" or "Management" to determine what the sign out route should be.
       *
       * @returns {object}
       */
      signOutRoute() {
        let signOutRoute = { name: 'SignOut' };

        if (!this.isDashboard && !this.isManagement) {
          signOutRoute = {
            name: 'ProjectSignOut',
            params: { projectId: this.projectId },
          };
        }

        return signOutRoute;
      },
      /**
       * Evaluates the $route.meta.pageType to create an array of
       * navigation items to loop through.
       *
       * @returns {Array}
       */
      navigationItems() {
        const { fullPath } = this.$route;
        const dataEntryItems = [
          {
            badgeCount: 0,
            route: {
              name: 'YourQuotes',
            },
            title: 'RFP Overview',
          },
          {
            badgeCount: this.unreadBrokerUpdates,
            name: 'RfpInfo',
            route: {
              path: `${fullPath}/broker-updates`,
            },
            title: 'RFP Updates',
          },
          {
            badgeCount: this.unreadEdits,
            name: 'QuoteEdits',
            route: {
              path: `${fullPath}/quote-edits`,
            },
            title: 'Quote edits',
          },
          {
            badgeCount: this.unreadComments,
            name: 'Comments',
            route: {
              path: `${fullPath}/comments`,
            },
            title: 'Comments',
          },
        ];

        return this.$route.meta.pageType === 'dataEntry'
          ? dataEntryItems.map((item) => ({
            ...item,
            index: item.route.path || item.route.name,
          }))
          : this.rfpOverviewTabs.map((tab) => ({
            ...tab,
            index: tab.name,
            route: { name: tab.name },
          }));
      },
    },
    methods: {
      /**
       * Close hamburger menu
       */
      closeHamburgerMenu() {
        this.$refs['main-menu'].closeMenu('1');
      },
      /**
       * Sends event to segment
       *
       * @param {string} title
       */
      sendAnalytics(title) {
        const menuItemsForAnalytics = ['Your quotes', 'Support', 'RFP info', 'Comments'];

        if (menuItemsForAnalytics.includes(title)) {
          trackSegmentEvent(`${title} in main menu clicked`, segmentData());
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  button.rfp-updates {
    svg.fa-icon.exclamation-yellow {
      position: relative;
      top: -2px;
      margin-left: 2px;
    }
  }
</style>
