<template>
  <ElDialog
    :visible="visible"
    data-test="terms modal"
    title="Terms"
    top="70px"
    append-to-body
    @close="$emit('closeDialog')"
  >
    <h3>Quote submission</h3>
    <p>
      Quote submission through the ThreeFlow platform must comply with the requirements specified in the Request for
      Proposal (“RFP”) as it applies to ThreeFlow supported insurance coverages.
    </p>
    <p>
      Vendor must provide all requisite information under the RFP and clearly and concisely respond to all points
      (plan design, price and terms) set out during the quote submission process. Any quote submission, which does
      not fully and comprehensively address the RFP, may be rejected. Prices must be stated in USD and any
      extraneous fees or taxes must be noted separately.
    </p>
    <p>
      Following submission of the quote submission ThreeFlow reserves the right to retain all information
      contained within the quote submission indefinitely for use, provided that any disclosure of information
      to third parties shall be anonymized.
    </p>
    <h3>Accuracy of quote submission by vendor</h3>
    <p>
      Vendor agrees that the quote submission (plan design, price and terms) will serve as the record of
      offering for the requested insurance coverages. Proposal document(s) provided should also be
      considered part of the record, if not otherwise detailed within the quote submission. Such
      documents will be made available for download. However, if there is conflicting information between the
      quote submission and the proposal document(s), the quote submission will govern.
    </p>
    <p>
      Quote submission shall be completed accurately and in good faith. In the event that ThreeFlow discovers
      that any proposal submission is submitted in bad faith, the submitter of such proposal may be removed
      or banned from access to the ThreeFlow platform for such quote and the future.
    </p>
    <h3>Accuracy of quote submission by ThreeFlow</h3>
    <p>
      ThreeFlow may enter the renewal as a service to the incumbent carrier, and the carrier has the ability
      to verify accuracy of the submission. ThreeFlow's submission does not replace any insurance company
      document, and is not meant to modify any aspect of the renewal offer. If there is any conflict, then
      the controlling document will be that provided by the insurance company.
    </p>
    <h3>No commitment</h3>
    <p>
      The RFP does not commit any party to award a contract or to pay any costs incurred in the preparations
      or submission of proposals, or costs incurred in making necessary studies for the preparation thereof
      or to procure or contract for services or supplies.
    </p>
    <p>
      The RFP contains no contractual proposal of any kind, any quote submission will be regarded as a proposal
      by the vendor and not as an acceptance by the vendor of any proposal by any party.
    </p>
    <h3>Withdrawals and modification of quote submission</h3>
    <p>
      Quote submissions may be modified or withdrawn in writing, prior to the proposal closing time specified
      therein.
    </p>
    <p>
      Vendor quote submissions are based on all information submitted within the RFP. Submitting vendors reserve
      the right to modify or withdrawal the offer in the event that the risk characteristics are materially
      different from those assumed by the vendor during the quote submission.
    </p>
    <h3>Errors in proposal</h3>
    <p>
      Vendors or their authorized agents are expected to examine any quote submissions and proposal
      documents. In the event that an error is identified by a vendor after the closing timequote submission, any
      corrections may be made by the vendor in good faith, but such corrections may not necessarily accepted by
      impacted stakeholders. In the event of such occurrence, ThreeFlow shall not be liable for any damages incurred
      as a result of such error(s).
    </p>
    <h3>Confidentiality</h3>
    <p>
      It is understood that Requests for Proposal are confidential, may contain privileged information, and are
      communicated to and received by quoters on the condition that no part thereof or any information contained
      may be copied, exhibited or furnished to others without the prior written consent of ThreeFlow except that
      vendors may exhibit the specifications to prospective sub-contractors for the sole purpose of obtaining
      offers from them. Notwithstanding the other provisions of the Request for Proposal, vendors will be bound
      by the contents of this paragraph whether or not their company submits a quote or responds in any other
      way to this Request for Proposal.
    </p>
    <h3>Contract</h3>
    <p>Any contract resulting from this Request for Proposal will include the General Conditions of Contract.</p>
  </ElDialog>
</template>

<script>

  /**
   * Start or update renewal modal dialog. Conditionally displays Rate Pass/Rate Guarantee form or Upload Renewal documents form.
   *
   * @exports Modals/TermsModal
   */
  export default {
    name: 'TermsModal',
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
