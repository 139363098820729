<template>
  <div>
    <DashboardTable
      dashboard-type="opportunities"
      data-test="Your opportunities table"
      data-return-key="opportunities_projects"
      title="Your opportunities"
    />
  </div>
</template>

<script>
  import DashboardTable from '@/components/DashboardTable/index.vue';

  /**
   * Table to display all the Proposal document for this carrier user.
   *
   * @exports Dashboards/YourOpportunities
   */
  export default {
    name: 'YourOpportunities',
    components: { DashboardTable },
  };
</script>
