<template>
  <TfTableParentRow data-test="opportunity row">
    <TfTableTd>
      <RouterLink
        :data-test="`to ${rowData.employer_name} opportunity`"
        :to="{
          name: 'RfpOverview',
          params: { projectId: rowData.id }
        }"
      >
        <AppButton
          :text="rowData.eventType"
          size="text"
        />
      </RouterLink>
    </TfTableTd>
    <TfTableTd>
      <span data-test="opportunity broker name">{{ rowData.broker_name }}</span>
    </TfTableTd>
    <TfTableTd>
      <div class="column-tooltip-container">
        <span data-test="opportunity employer name">
          {{ rowData.employer_name }}
        </span>
        <TfTooltip
          :options="{
            placement: 'right-start',
            modifiers: {
              offset: { offset: '0,10px' }
            }
          }"
        >
          <ul>
            <li
              v-for="productType in rowData.product_types"
              :key="productType"
              v-text="productType"
            />
          </ul>
          <template #reference>
            <AppIcon
              data-test="toggle opportunity products"
              type="success"
              icon="fa-regular fa-circle-info"
            />
          </template>
        </TfTooltip>
      </div>
    </TfTableTd>
    <TfTableTd>
      <span data-test="opportunity effective date">
        {{ formatSimpleDate(rowData.effective_date, 'MM/DD/YYYY') || '&mdash;' }}
      </span>
    </TfTableTd>
    <TfTableTd :class="{ 'text-danger': rowData.pastDue }">
      <span data-test="opportunity status">{{ rowData.status }}</span>
    </TfTableTd>
    <TfTableTd>
      <span data-test="opportunity due date">
        {{ formatSimpleDate(rowData.due_date, 'MM/DD/YYYY') || '&mdash;' }}
      </span>
    </TfTableTd>
    <TfTableTd>
      <span data-test="opportunity employee count">
        {{ employeeCount }}
      </span>
    </TfTableTd>
  </TfTableParentRow>
</template>

<script>
  import { formatNumber, formatSimpleDate } from '@watchtowerbenefits/es-utils-public';

  /**
   * Row template component for Dashboard table
   *
   * @exports Dashboard/DashboardRow
   */
  export default {
    name: 'DashboardRow',
    props: {
      rowData: {
        type: Object,
        required: true,
      },
    },
    computed: {
      employeeCount() {
        return formatNumber({
          amount: this.rowData.employer_total_lives,
          showZeroes: false,
        }) || '&mdash;';
      },
    },
    methods: {
      formatSimpleDate,
    },
  };
</script>

<style scoped>
.column-tooltip-container {
  display: flex;
  gap: 5px;
}

ul {
  text-align: left;
  width: 140px;
}
</style>
