<template>
  <ElDialog
    :visible="visible"
    :before-close="onBeforeClose"
    class="user-default-dialog"
    top="70px"
    width="474px"
    append-to-body
    data-test="user-default-modal"
  >
    <div
      :style="{
        backgroundImage: `url(${highFive})`
      }"
      class="header-image"
      alt="High five"
    />
    <p>
      You are saving your plan design preferences for {{ productName }}.
    </p>
    <p id="user-default-info">
      These will be your default provisional values for this product, including call outs.
      You can edit and save over your plan design preferences at any time.
    </p>
    <div class="btn-group">
      <AppButton
        size="text"
        text="Cancel"
        data-test="cancel user defaults"
        @click="closeDialog"
      />
      <AppButton
        :is-loading="btnSaving"
        data-test="save user defaults"
        icon="fa-solid fa-check-circle"
        size="large"
        text="Save"
        type="success"
        @click="submitPreferredPlanDesignValues"
      />
    </div>
  </ElDialog>
</template>

<script>
  import { mapActions } from 'pinia';
  import { useUserPreferredPdvsStore } from '@/stores/userPreferredPlanDesignValues.js';
  import highFive from '@/assets/high-five.png';

  /**
   * Decline Product
   *
   * @exports Modals/UserDefaultModal
   */
  export default {
    name: 'UserDefaultModal',
    props: {
      productId: {
        type: Number,
        default: null,
      },
      productName: {
        type: String,
        default: null,
      },
      visible: {
        type: Boolean,
        default: false,
      },
      userPreferredValues: {
        type: Object,
        default: () => null,
      },
    },
    data: () => ({
      btnSaving: false,
      highFive,
    }),
    methods: {
      ...mapActions(useUserPreferredPdvsStore, ['savePreferredPlanDesignValues']),
      /**
       * Emit an event to close this dialog
       */
      closeDialog() {
        this.btnSaving = false;
        this.$emit('closeDialog');
      },
      /**
       * If you click the 'x' make sure the dialog closes
       *
       * @param {Function} done
       */
      onBeforeClose(done) {
        this.btnSaving = false;
        done();
        this.$emit('closeDialog');
      },
      /**
       * Saves the users changed plan design values as their defaults
       * for future projects of the same type
       */
      async submitPreferredPlanDesignValues() {
        this.btnSaving = true;

        try {
          await this.savePreferredPlanDesignValues(this.productId, this.userPreferredValues);
          this.$message({
            showClose: true,
            message: 'You’ve successfully saved your plan design preferences for this product. You can edit and save over these values at any time.',
            type: 'success',
          });
        } catch ({ message }) {
          this.$message({
            showClose: true,
            message: `Error: ${message}. Please try again`,
            type: 'error',
          });
          this.btnSaving = false;
        } finally {
          this.closeDialog();
        }
      },
    },
  };
</script>

<style scoped>
  div {
    text-align: center;
  }

  .header-image {
    width: 474px;
    height: 256px;
    margin-left: -47px;
    margin-top: -50px;
    background-size: cover;
  }

  p {
    margin: 10px 0;
    font-size: 22px;
    font-weight: 600;
    line-height: normal;
    color: var(--tf-gray-dark);
  }

  #user-default-info {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    padding: 0 10px;
  }

  :deep(.el-dialog__body) {
    padding: 0 47px;
  }

  .btn-group {
    margin: 23px 0 45px;
  }
</style>
