<template>
  <AppTermsOfUse />
</template>

<script>

/**
 * Terms of Use
 *
 * @exports TermsOfUse
 */
  export default {
    name: 'TermsOfUse',
  };
</script>

<style lang="scss" scoped>
  :deep(ul) {
    margin-left: 15px;
    list-style: initial;
  }
</style>
