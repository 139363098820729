<template>
  <section
    v-loading="isAuthenticating"
    element-loading-text="Authenticating SSO user..."
  />
</template>

<script>
  import AccountService from '@/services/account.js';
  import { deleteCookie, setCookie } from '@watchtowerbenefits/es-utils-public';
  import { config } from '@/utils/config.js';

  /**
   * SSO Authentication
   *
   * @exports Authentication/SsoSignIn
   */
  export default {
    name: 'SsoSignIn',

    data: () => ({ isAuthenticating: true }),

    async created() {
      const { query: { auth_token: authToken, error } } = this.$route;

      if (!authToken || error) {
        this.failedToAuthenticate(error);

        return;
      }

      try {
        const signInData = await AccountService.getCurrentUser(authToken);
        const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;
        const expirationTime = new Date();

        expirationTime.setHours(expirationTime.getHours() + 8);

        setCookie(
          `${cookieNamespace}-auth-token`,
          authToken,
          expirationTime,
        );

        setCookie(
          `${cookieNamespace}-user-roles`,
          signInData.user.roles.map((role) => role.name),
          expirationTime,
        );

        await AccountService.setSignedIn(signInData);

        this.isAuthenticating = false;

        this.$router.replace({ name: 'Dashboard' });
      } catch {
        this.failedToAuthenticate();
      }
    },
    methods: {
      failedToAuthenticate(error) {
        const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;
        const { projectId } = this.$route.params;

        this.isAuthenticating = false;

        if (error) {
          deleteCookie(`${cookieNamespace}-auth-token`);
          deleteCookie(`${cookieNamespace}-user-roles`);
        }

        this.$router.replace({
          name: projectId ? 'ProjectSignIn' : 'SignIn',
          params: { projectId },
          query: { error: 'auth' },
        });
      },
    },
  };
</script>
