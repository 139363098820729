<template>
  <!--
    Only show this row if the policy or proposal has a tier_group.
    Endpoint needs to return this attribute for the Broker App.
    https://watchtower.atlassian.net/browse/CPV-415 is for if we want to handle this differently.
  -->
  <tr
    v-if="policyValues.tier_group || proposalValues.tier_group"
    data-test="plan design attribute row"
  >
    <!-- First cell should always appear with plans or first grouping idx. -->
    <td
      v-if="planDesignIsPlan || idx === 0"
      class="cell-attribute-name"
      data-test="plan design attribute name"
      :rowspan="planDesignIsPlan ? 1 : groupings"
      v-text="attributeName"
    />
    <template v-if="isPlanSummary">
      <td
        v-if="!planDesignIsPlan"
        class="cell-label"
      >
        {{ attributeClassLabel }}
      </td>
      <PlanDesignTdValue
        v-for="subtype in policyValues.tier_group.tier_subtypes"
        :key="`policy-value-${subtype.name}`"
        :cell-data="subtype"
        :readonly="true"
        :rolled-out="attribute.rolledOut"
        :subtype-length="policyValues.tier_group.tier_subtypes.length"
        subtype-type="policy"
        :form-key="formKey"
        @validateForm="validateForm"
      />
    </template>
    <template v-else>
      <!-- Policy values -->
      <template v-if="!isRenewalProduct">
        <template v-if="policyValues.tier_group">
          <PlanDesignTdValue
            v-for="subtype in policyValues.tier_group.tier_subtypes"
            :key="`policy-value-${subtype.name}`"
            :attribute-id="attributeId"
            :category-id="categoryId"
            :cell-data="subtype"
            :readonly="true"
            :rolled-out="attribute.rolledOut"
            :subtype-length="policyValues.tier_group.tier_subtypes.length"
            :tier-group-id="policyValues.tier_group.id"
            :tier-subtype-id="subtype.id"
            subtype-type="policy"
            :form-key="`${formKey}_${policyValues.tier_group.id}_${subtype.id}`"
            @validateForm="validateForm"
          />
        </template>
        <td
          v-else-if="!isRenewalProduct && !isNewCoverage"
          :colspan="emptyColspan"
          v-html="'&nbsp;'"
        />
      </template>
      <!-- `Classes label` only shows on Class Based -->
      <td
        v-if="!planDesignIsPlan"
        class="cell-label class-cell"
      >
        <AppButton
          v-if="attribute.ids.length > 1"
          :is-disabled="disableRollout"
          :text="attributeClassLabel"
          type="secondary"
          size="small"
          @click="rollOutClasses"
        />
        <template v-else>
          {{ attributeClassLabel }}
        </template>
      </td>
      <!-- Proposal/Renewal values -->
      <template v-if="proposalValues.tier_group">
        <PlanDesignTdValue
          v-for="subtype in proposalValues.tier_group.tier_subtypes"
          :key="`proposal-value-${subtype.name}`"
          :attribute-id="attributeId"
          :attribute-name="attributeName"
          :category-id="categoryId"
          :cell-data="subtype"
          :readonly="$route.meta.readonly"
          :rolled-out="attribute.rolledOut"
          :subtype-length="proposalValues.tier_group.tier_subtypes.length"
          :tier-group-id="proposalValues.tier_group.id"
          :tier-subtype-id="subtype.id"
          subtype-type="proposal"
          :form-key="proposalRenewalFormKey(proposalValues.tier_group.id, subtype.id)"
          @validateForm="validateForm"
        />
      </template>
    </template>
  </tr>
</template>

<script>
  import { mapActions, mapState, mapWritableState } from 'pinia';
  import { useProductStore } from '@/stores/product.js';
  import { usePlanDesignStore } from '@/stores/planDesign.js';
  import PlanDesignTdValue from './PlanDesignTdValue.vue';

  /**
   * Plan Design Attribute
   *
   * @exports PlanDesign/PlanDesignAttribute
   */
  export default {
    name: 'PlanDesignAttribute',
    components: { PlanDesignTdValue },
    inject: [
      'containerId',
      'isPlanSummary',
      'subtypeColspan',
    ],
    props: {
      /**
       * Attribute info for the table row.
       */
      attribute: {
        type: Object,
        required: true,
        default: () => {},
      },
      /**
       * attributeID passed to the cell value. Due to the parent PlanDesignTable data, Plan/Class based product have slightly different data and attribute.id !== attributeId.
       */
      attributeId: {
        type: Number,
        default: 0,
      },
      /**
       * Name of the attribute passed down from the table. This needs to be a prop with the same reasoning behind the `attributeId` logic.
       */
      attributeName: {
        type: String,
        required: true,
        default: '',
      },
      /**
       * categoryId passed to the cell value.
       * Can't provide/inject this since PlanDesignTable loops differently based on Plan/Class based
       */
      categoryId: {
        type: Number,
        default: 0,
      },
      /**
       * groupings is the length of groupings in Class Based to determine the `rowspan`
       */
      groupings: {
        type: Number,
        default: 1,
      },
      /**
       * idx is used to determine whether or not to show the `cell-attribute-name` for plans or for the first row in a grouping.
       */
      idx: {
        type: Number,
        required: true,
        default: 0,
      },
    },
    data() {
      return {
        disableRollout: false,
      };
    },
    computed: {
      ...mapState(usePlanDesignStore, ['planDesignIsPlan']),
      ...mapState(useProductStore, [
        'isNewCoverage',
        'isRenewalProduct',
        'productId',
      ]),
      ...mapWritableState(usePlanDesignStore, ['planDesignErrors']),
      /**
       * removing repeated words and unnecessary spacing to clean up text for the button in the template
       *
       * @returns {string}
       */
      attributeClassLabel() {
        return this.attribute.label.replace(/classes|class| /gi, '');
      },
      /**
       * Number of empty colspan amount when attribute is neither a renewal nor a new coverage
       *
       * @returns {number}
       */
      emptyColspan() {
        const divisibleBy = this.proposalValues.tier_group
          ? this.proposalValues.tier_group.tier_subtypes.length
          : 1;

        return this.subtypeColspan.policy / divisibleBy;
      },
      /**
       * Form key for form model
       *
       * @returns {string}
       */
      formKey() {
        const id = Array.isArray(this.attribute.ids) ? `${this.attribute.ids.join('_')}` : `${this.containerId}`;

        return `${id}_${this.categoryId}_${this.attributeId}`;
      },
      /**
       * Policy info for the attribute.
       *
       * @returns {object}
       */
      policyValues() {
        return this.attribute.products.find(
          (product) => product.document_type.toLowerCase() === 'policy',
        );
      },
      /**
       * Proposal info for the attribute.
       *
       * @returns {object}
       */
      proposalValues() {
        return this.attribute.products.find(
          (product) => product.document_type.toLowerCase() !== 'policy',
        );
      },
    },
    methods: {
      ...mapActions(usePlanDesignStore, ['rollOutPlanDesign']),
      /**
       * Some fields have a tier group and subtype, some don't.
       *
       * @param {string} tierGroup
       * @param {string} subtype
       * @returns {string}
       */
      proposalRenewalFormKey(tierGroup, subtype) {
        return `${this.formKey}${tierGroup ? `_${tierGroup}` : ''}${subtype ? `_${subtype}` : ''}`;
      },
      /**
       * roll out the classes for plan design
       */
      rollOutClasses() {
        this.disableRollout = true;

        this.rollOutPlanDesign({
          attribute: this.attribute,
          attributeId: this.attributeId,
          categoryId: this.categoryId,
          productId: this.productId,
        });

        this.disableRollout = false;
        this.validateForm();
      },
      /**
       * When the user updates a rate value we need to potentially sync planDesignErrors
       */
      validateForm() {
        this.planDesignErrors.splice(0, this.planDesignErrors.length);
        this.$emit('planDesignErrorsUpdated');
      },
    },
  };
</script>

<style scoped>
.cell-attribute-name {
  text-align: left;
  vertical-align: top;
}

.cell-label {
  width: 20px;
  white-space: nowrap;
}

td.class-cell {
  vertical-align: middle;
  text-align: center;
  margin: auto;
  padding: 0;
}

td {
  border-color: var(--tf-gray-light-medium);
}
</style>
