<template>
  <section class="auth-form">
    <template v-if="expiredEmail">
      <AuthPageHeader title="Uh-oh, looks like your confirmation email expired." />
      <p>
        We’ll send a fresh one to {{ $route.query.email }}. <br>
        Be sure to use this one.
        <br>
        <br>
        <AppButton
          text="Resend confirmation email"
          @click="resendConfirmation"
        />
      </p>
    </template>
  </section>
</template>

<script>
  import { mapActions } from 'pinia';
  import { useAccountStore } from '@/stores/account.js';
  import AccountService from '@/services/account.js';

  /**
   * Email Confirmation
   *
   * @exports Authentication/EmailConfirmation
   */
  export default {
    name: 'EmailConfirmation',
    data: () => ({ expiredEmail: false }),
    /**
     * We then for your token (from your confirmation email) and call the accountService/confirmEmail method.
     * If the call works, we send you on to the project overview or dashboard depending on where you're coming from.
     * Otherwise we check if your token expired and show you the expiration message with a button to resend.
     */
    async created() {
      const {
        params: { projectId, token },
        query,
      } = this.$route;
      let route = query.redirect;

      if (!route) {
        route = projectId
          ? { name: 'RfpOverview', params: { projectId } }
          : { name: 'Dashboard' };
      }

      try {
        await AccountService.confirmEmail({ token });
        this.setUserEmail(query.email);
        this.$router.replace(route);
      } catch {
        this.expiredEmail = true;
      }
    },

    methods: {
      ...mapActions(useAccountStore, ['setUserEmail']),
      /**
       * Call service to send a new confirmation email.
       */
      async resendConfirmation() {
        const {
          params: { projectId },
          query: { redirect, email },
        } = this.$route;

        await AccountService.resendConfirmation({ email, projectId });
        let route = redirect;

        if (!route) {
          route = projectId
            ? { name: 'ProjectRegisterConfirmation', params: { projectId } }
            : { name: 'RegisterConfirmation' };
        }

        this.$router.replace(route);
      },
    },
  };
</script>
