<template>
  <section
    v-if="projectIsLoaded"
    class="rfp-overview-container"
  >
    <RfpStatus
      data-test="rfp status"
      @viewSalesPitch="$router.push(`${$route.path}/sales-pitch`)"
    />
    <div class="rfp-overview-tabs-container">
      <TfTabs
        :value="$route.name"
        @tab-click="onTabClicked"
      >
        <ElTabPane
          v-for="{ name, title, badgeCount } in rfpOverviewTabs"
          :key="name"
          :class="{
            'full-width-tab-pane': ['YourQuotes', 'RfpInfo'].includes(name),
            'is-updates-tab': name === 'UpdatesEdits'
          }"
          :lazy="true"
          :name="name"
        >
          <template slot="label">
            {{ title }}
            <sup v-if="badgeCount">
              <TfBadge
                :value="badgeCount"
                status="error"
                is-counter
              />
            </sup>
          </template>
          <RouterView v-if="name === $route.name" />
        </ElTabPane>
      </TfTabs>
      <!-- We only want the footer to be stick to the bottom on non-products/rfp-info tabs -->
      <RfpOverviewFooter v-if="!['YourQuotes', 'RfpInfo'].includes($route.name)" />
    </div>
    <SalesPitchModal
      v-if="$route.path.endsWith('sales-pitch')"
      visible
      data-test="sales pitch editor"
      @close="$router.push($route.path.replace('/sales-pitch', ''))"
    />
  </section>
</template>

<script>
  import { mapState } from 'pinia';
  import project from '@/mixins/project.js';
  import rfpOverview from '@/mixins/rfpOverview.js';
  // utils
  import { segmentData } from '@/utils/analytics.js';
  import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
  // components
  import RfpOverviewFooter from '@/components/RfpOverview/RfpFooter.vue';
  import RfpStatus from '@/components/RfpOverview/RfpStatus.vue';
  import { useProjectStore } from '@/stores/project.js';

  /**
   * Project Overview
   *
   * @exports RfpOverview
   */
  export default {
    name: 'RfpOverview',
    components: {
      RfpOverviewFooter,
      RfpStatus,
      SalesPitchModal: () => import('@/components/Modals/SalesPitchModal.vue'),
    },
    mixins: [project, rfpOverview],
    computed: {
      ...mapState(useProjectStore, ['employerName', 'project']),
    },
    watch: {
      /**
       * Determine if project is closed.
       *
       * @param {string} stateValue
       */
      'project.state': {
        handler(stateValue) {
          if (!stateValue) return;

          if (this.project.merged_into) {
            this.$router.replace({ name: 'RfpOverview', params: { projectId: this.project.merged_into } });
          } else if (stateValue === 'rfp_closed') {
            this.projectClosedRedirect();
          } else {
            this.checkInvited();
          }
        },
        immediate: true,
      },
    },
    /**
     * get all project data if project hasn't loaded or is loading
     */
    async mounted() {
      // The project data might already be loading from a parent component so escape early.
      if (this.projectIsLoading || this.projectIsLoaded) {
        return;
      }
      await this.getAllProjectData(this.$route.params.projectId);
    },
    methods: {
      /**
       * Send analytics and push to appropriate route name.
       *
       * @param {string|object} tab - name of the tab or the tab object (depending on ff)
       */
      onTabClicked(tab) {
        // depending on the feature flag state, the tab is string or object
        const routeName = typeof tab === 'string' ? tab : tab.name;
        const { name } = this.$route;

        // Do nothing if clicking on current tab
        if (routeName === name) return;

        const tabsForAnalytics = ['YourQuotes', 'MarketActivity', 'RfpInfo', 'Comments'];

        if (tabsForAnalytics.includes(routeName)) {
          trackSegmentEvent(`${routeName} clicked`, segmentData());
        }

        this.$router.push({ name: routeName });
      },
      /**
       * If the project is rfp_closed, redirect users with a toast.
       */
      projectClosedRedirect() {
        this.$message({
          showClose: true,
          message: 'The opportunity you were trying to access is closed and no longer available.',
          type: 'error',
          duration: 5000,
        });
        this.$router.push({ name: 'Dashboard' });
      },
    },
  };
</script>

<style lang="scss" scoped>
.rfp-overview-container {
  body:not(.is-ie) & {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

:deep(footer) {
  grid-area: footer;
}

.rfp-overview-tabs-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}

:deep() .el-tabs {
  &,
  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  &__nav-wrap {
    background: $tf-extra-light-gray-2;
    padding-left: 32px;
  }

  &__nav-scroll {
    background: $tf-extra-light-gray-2;
  }
}

.el-tab-pane {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 38px;

  // All tabs but RFP Updates/Quote Edits tab should be centered
  &:not(.full-width-tab-pane) {
    max-width: $max-width;
    margin: 0 auto;
  }

  &.is-updates-tab {
    padding-top: 0;
  }
}
</style>
