import Vue from 'vue';
import { defineStore } from 'pinia';
import { NotificationsService } from '@watchtowerbenefits/shared-components';

/* eslint-disable import/no-cycle */
import { useProjectStore } from '@/stores/project.js';
// eslint-disable-next-line import/no-cycle

import { config } from '@/utils/config.js';

const apiConfig = {
  baseUrl: config.VUE_APP_API_URL,
  cookieNamespace: config.VUE_APP_COOKIE_NAMESPACE,
};

export const useNotificationsStore = defineStore('notifications', {
  state: () => ({
    unreadEdits: null,
    reviewAlertDismissed: false,
    allProductsSubmittedDialogVisible: false,
  }),

  actions: {
    async getEditsNotifications(projectId) {
      const data = await NotificationsService.getEditsNotifications(projectId, 'carrier_portal', apiConfig);

      this.unreadEdits = data.unread_notifications;
    },
    // `product` is passed only when declining/submitting a product.
    // Otherwise, text && type need to be passed.
    setAlertQuotesTab({
      actionType = 'submitted',
      products = [],
      text,
      type,
    }) {
      let messageText = text;
      let messageType = type;

      if (products.length) {
        const projectStore = useProjectStore();
        const productNames = products
          .map(({ product_type_name: productName }) => productName)
          .join(', ')
          .replace(/,([^,]*)$/, ' and$1');

        messageText = `Your ${productNames} quote has been submitted to ${projectStore.broker.name}.`;
        messageType = 'success';

        if (actionType === 'declined') {
          messageText = `You have successfully declined to quote ${productNames}.`;
        }
      }

      this.showMessage(messageText, messageType);
    },
    /**
     * Call the Element message function to display a toast message with specified parameters
     *
     * @param {string} message
     * @param {string} type
     */
    showMessage(message, type) {
      Vue.prototype.$message({
        duration: 6000,
        message,
        showClose: true,
        type,
      });
    },
  },
});
