<template>
  <section>
    <AppRegisterForm
      v-bind="{
        ...formProps,
        accountService,
        showProjectInfo: false,
        userInfo,
      }"
      @setUserEmail="setUserEmail"
      @setUserInfo="setUserInfo"
    />
  </section>
</template>

<script>
  import { mapActions, mapState } from 'pinia';
  import { useAccountStore } from '@/stores/account.js';
  import accountService from '@/services/account.js';

  /**
   * Register
   *
   * @exports Authentication/Register
   */
  export default {
    name: 'UserRegistration',
    data: () => ({ accountService }),
    computed: {
      ...mapState(useAccountStore, ['userInfo']),
      /**
       * Evaluate the route params and returns an object with all of the correct data.
       *
       * @returns {object}
       */
      formProps() {
        const { projectId } = this.$route.params;
        const formProps = {
          linkForgot: { name: 'ForgotPassword' },
          linkLogin: { name: 'SignIn' },
          linkRegisterConfirmation: { name: 'RegisterConfirmation' },
        };

        if (projectId) {
          Object.keys(formProps).forEach((prop) => {
            formProps[prop] = {
              name: `Project${formProps[prop].name}`,
              params: { projectId },
            };
          });

          formProps.projectId = projectId;
        }

        return formProps;
      },
    },

    methods: {
      ...mapActions(useAccountStore, ['setUserEmail', 'setUserInfo']),
    },
  };
</script>
