<template>
  <div class="container-attributes">
    <TableHeader :readonly="$route.meta.readonly">
      <template slot="title">
        {{ rateAttribute.name }}
      </template>
      <!-- Edit Plan Design Slot (when reviewing) -->
      <template #btnEdit>
        <RouterLink
          v-if="!isPlanSummary"
          :to="{ name: 'RateEntry' }"
        >
          <AppButton
            icon="fa-solid fa-pencil"
            size="text"
            text="Edit rates"
          />
        </RouterLink>
      </template>
      <!-- Rate Options/Rate Basis -->
      <template #formElements>
        <!-- Layout of Rate Entry page -->
        <template v-if="!isPlanSummary">
          <ElFormItem
            label="Rate options:"
            size="medium"
          >
            <ElSelect
              v-model="attributeType"
              :disabled="readonly || drainingUpdateRequests"
              size="medium"
              placeholder="Select rate option"
              data-test="rate-option-select"
            >
              <ElOption
                value="CompositeRateValue"
                :label="$options.filters.tierGroupName('CompositeRateValue')"
                :data-test="`rate-option-composite`"
              />
              <ElOption
                v-if="showAgeBandedValueOption"
                value="AgeBandedRateValue"
                :label="$options.filters.tierGroupName('AgeBandedRateValue')"
                :data-test="`rate-option-age-banded`"
              />
              <ElOption
                v-if="attributeInfo.policyAttributeType === 'CustomRateValue'"
                disabled
                value="CustomRateValue"
                :label="$options.filters.tierGroupName('CustomRateValue')"
              />
              <ElOption
                v-for="option in rateTierGroups"
                :key="`${rateAttribute.name}-${option.tier_group_id}`"
                :value="option.tier_group_id"
                :label="option.tier_group_name"
                :data-test="`rate-option-${option.tier_group_name}`"
              />
            </ELSelect>
          </ElFormItem>
          <ElFormItem
            :label="`Rate basis${rateBasisOptions.length === 1 ? ':' : ''}`"
            size="medium"
          >
            <ElSelect
              v-if="rateBasisOptions.length > 1"
              v-model="rateBasis"
              :disabled="readonly"
              size="medium"
              placeholder="Select rate basis"
            >
              <ElOption
                v-for="option in rateBasisOptions"
                :key="`${rateAttribute.name}-${option.value}`"
                :value="option.value"
                :label="option.label"
              />
            </ElSelect>
            <span
              v-else
              v-text="rateBasis"
            />
          </ElFormItem>
        </template>
        <!-- Layout for Plan Summary modal -->
        <template v-else>
          <h4>
            Rate options:
            <template v-if="isPlan">
              {{ policyValues.tier_group.name
                || policyValues.tier_group.tier_subtypes[0].rate_value.type | tierGroupName }}
            </template>
            <template v-else>
              {{ policyValues.tier_group.name
                || policyValues.tier_group.tier_subtypes[0].rate_values[0].type | tierGroupName }}
            </template>
          </h4>
          <h4>Rate basis: {{ rateBasis }}</h4>
        </template>
      </template>
    </TableHeader>
    <div
      v-if="!tierGroupsIsLoading"
      v-loading.body="updatingRateAttributes"
      class="attributes-tables"
    >
      <!-- Incumbent table -->
      <RateEntryTable
        v-if="policyValues.tier_group && !isNewCoverage"
        v-bind="{
          attributeType,
          incumbent: true,
          rateAttributeIndex,
          tierGroup: policyValues.tier_group }"
      />
      <!-- Proposal/Renewal table -->
      <RateEntryTable
        v-if="!isPlanSummary"
        v-bind="{
          id: rateAttribute.id,
          attributeType,
          rateAttributeIndex,
          tierGroup: proposalValues.tier_group }"
      />
    </div>
  </div>
</template>

<script>
  import { mapState, mapWritableState, mapActions } from 'pinia';
  import { useAccountStore } from '@/stores/account.js';
  import { useRateEntryStore } from '@/stores/rateEntry.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductStore } from '@/stores/product.js';
  import { reusableRateBasisOptions, reusableAgeBandedValues as ageBanded } from '@watchtowerbenefits/es-utils-public';
  import TableHeader from '@/components/DataEntry/TableHeader.vue';
  import RateEntryTable from './RateEntryTable.vue';

  /**
   * Rate Entry Attribute
   *
   * @exports RateEntry/RateEntryAttribute
   */
  export default {
    name: 'RateEntryAttribute',
    components: { RateEntryTable, TableHeader },
    /**
     * sends reactive storeAttributeId and containerId
     *
     * @returns {object}
     */
    provide() {
      const props = {};

      [
        'storeAttributeId',
        'containerId',
      ].forEach((prop) => {
        Object.defineProperty(props, prop, {
          get: () => this[prop],
        });
      });

      return props;
    },
    inject: [
      'containerIds',
      'isPlanSummary',
      'isPlan',
    ],
    props: {
      /**
       * Rate Entry Attribute containing the ID, name, and incumbent/proposal values
       */
      rateAttribute: {
        type: Object,
        required: true,
      },
      rateAttributeIndex: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        updatingRateAttributes: false,
      };
    },
    computed: {
      ...mapWritableState(useRateEntryStore, [
        'rateAttributes',
        'rateErrors',
      ]),
      ...mapWritableState(useRateEntryStore, ['rateUpdateRequests', 'rateEntryContainers']),
      ...mapState(useRateEntryStore, [
        'info',
        'drainingUpdateRequests',
      ]),
      ...mapState(useProjectStore, ['projectId']),
      ...mapState(useProductStore, [
        'rateTierGroups',
        'tierGroupsIsLoading',
        'currentProduct',
        'isNewCoverage',
        'isStopLoss',
        'productId',
      ]),
      ...mapState(useAccountStore, ['userInfo']),
      ageBanded,
      /**
       * Attribute Information object from the store used for `attributeType` and `rateBasis`
       *
       * @returns {object}
       */
      attributeInfo() {
        return this.rateAttributes[this.storeAttributeId] || {};
      },
      /**
       * Rate options select model
       * Get: gets the attributeType from the store
       * Set: Construct the new attribute type and call patch to update all the subtypes then sync from the API
       */
      attributeType: {
        get() {
          return this.attributeInfo.attributeType;
        },
        async set(newValue) {
          if (newValue === this.rateAttributes[this.storeAttributeId].attributeType || this.drainingUpdateRequests) {
            return;
          }

          if (this.rateUpdateRequests.length) {
            this.rateUpdateRequests.splice(0, this.rateUpdateRequests.length);
          }

          const attributeType = Number.isNaN(Number(newValue))
            ? newValue
            : Object.values(this.rateAttributes[this.storeAttributeId].rateValues)[0].type;

          await this.updateTierGroup({
            productId: this.productId,
            rateAttribute: {
              ...this.rateAttributes[this.storeAttributeId],
              tierGroupId: Number.isNaN(Number(newValue)) ? null : newValue,
              attributeType,
            },
          });
          this.rateErrors.splice(0, this.rateErrors.length);
          this.updatingRateAttributes = false;
        },
      },
      /**
       * Rate basis select model
       * GET: get the policy rate basis
       * SET: update all the subtypes with the new rate basis
       */
      rateBasis: {
        get() {
          let { rateBasis } = this.attributeInfo;

          if (this.isPlanSummary || this.rateBasisOptions.length === 1) {
            rateBasis = this.isPlan
              ? this.policyValues.tier_group.tier_subtypes[0].rate_value.formatted_rate_basis
              : this.policyValues.tier_group.tier_subtypes[0].rate_values[0].formatted_rate_basis;
          }

          return rateBasis;
        },
        async set(value) {
          if (!value || value === this.rateAttributes[this.storeAttributeId].rateBasis) {
            return;
          }

          this.updatingRateAttributes = true;
          let subtypes = [];

          if (this.attributeInfo.tierGroupId) {
            const tierGroup = this.rateTierGroups.find(
              ({ tier_group_id: id }) => id === this.attributeInfo.tierGroupId,
            );

            subtypes = tierGroup.tier_subtypes;
          } else {
            subtypes.push({ subtype_id: this.attributeType });
          }

          // Patch all the subtypes
          await Promise.all(subtypes.map(async (subtype) => this.updateRateAttribute({
            productId: this.productId,
            storeAttributeId: this.storeAttributeId,
            subtypeId: subtype.subtype_id,
            rateBasis: value,
            rateValues: this.rateAttributes[this.storeAttributeId].rateValues,
          })));

          // Re-up the rate entry data
          await this.getRateEntry({ productId: this.productId });

          this.rateErrors.splice(0, this.rateErrors.length);
          this.updatingRateAttributes = false;
        },
      },
      /**
       * Show the age-banded value option only for dental, dental 2, vision, and stop loss products
       *
       * @returns {boolean}
       */
      showAgeBandedValueOption() {
        return !/dental|vision|stop loss/i.test(this.currentProduct.product_type_name);
      },
      /**
       * Policy values used for the incumbent tables
       *
       * @returns {object}
       */
      policyValues() {
        return this.rateAttribute.products.find((product) => product.document_type.toLowerCase() === 'policy');
      },
      /**
       * proposal/renewal values used for the right tables
       *
       * @returns {object}
       */
      proposalValues() {
        return this.rateAttribute.products.find((product) => product.document_type.toLowerCase() !== 'policy');
      },
      /**
       * Get the available Rate Basis Options based on the product type
       *
       * @returns {Array}
       */
      rateBasisOptions() {
        return reusableRateBasisOptions(this.info.product_type_id);
      },
      /**
       * Boolean to determine the state of the tables (review or edit)
       *
       * @returns {boolean}
       */
      readonly() {
        return this.$route.meta.readonly;
      },
      /**
       * storeAttributeId is passed down to children so they can point to the appropriate rateAttribute in the store
       *
       * @returns {string}
       */
      storeAttributeId() {
        return `${this.containerIds.join('_')}_${this.rateAttribute.id}`;
      },
    },
    methods: {
      ...mapActions(useRateEntryStore, [
        'updateRateAttribute',
        'getRateEntry',
        'updateTierGroup',
        'setClassTypeContainers',
        'setPlanTypeContainers',
      ]),
    },
  };
</script>

<style lang="scss" scoped>
.attributes-tables {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  margin: 0 30px;

  .dialog-plan-summary & {
    margin: 0;
  }
}

.container-attributes {
  width: 100%;
  max-width: $max-width;
  margin: 0 auto;
}

h4 {
  font-weight: 400;

  .dialog-plan-summary & {
    font-size: 14px;
  }
}

:deep(.el-form-item) {
  margin-bottom: 10px;
}
</style>
