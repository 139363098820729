/* eslint-disable camelcase */
import Vue from 'vue';
import { defineStore } from 'pinia';
import { useCarrierInfoStore } from '@/stores/carrierInfo.js';
import { useAccountStore } from '@/stores/account.js';
/* eslint-disable-next-line import/no-cycle */
import { useProductStore } from '@/stores/product.js';
import { isStopLossProduct } from '@watchtowerbenefits/es-utils-public';
import { productLabel, tfAssistAndDiveUnsupportedProductTypes } from '@/utils/product.js';
import rateEntryService from '@/services/rateEntry.js';
import productService from '@/services/product.js';
import { arrayHasItem } from '@/utils/general.js';

const statusSortOrder = {
  'Action Needed': 1,
  'Ready to Submit': 2,
  'Not Submitted': 3,
  Processing: 4,
  'Not Started': 5,
  // 'ThreeFlow Review': 6,  Left in for future use if threeflow review role is used
  'Firm Requested': 6,
  'Firm Quote Expired': 7,
  'Firm Quote': 8,
  Submitted: 9,
  Selected: 10,
  Closed: 11,
  Declined: 12,
};

export const useProductTableStore = defineStore('productTable', {
  state: () => ({
    proposalRateGuarantees: {},
    productRateGuarantees: {},
    planDesignCompleteness: {},
  }),
  getters: {
    /**
     * Does user have a role that allows them to edit Threeflow Assist products.
     *
     * @returns {boolean}
     */
    canEditThreeflowAssist() {
      const validRoles = ['threeflow_reviewer', 'admin'];

      return useAccountStore().roleNames.some((role) => validRoles.includes(role.toLowerCase()));
    },
    /**
     * Maps over the products array and returns products that are formatted for the product table
     *
     * @param {object} state
     * @returns {Array}
     */
    productTableData(state) {
      /**
       * @param {object} product
       * @returns {object}
       */
      function createProduct(product) {
        const policyInfo = () => {
          if (!useProductStore().isInforceCarrier(product) && useProductStore().isSmartProposal) return ['New'];

          return product.policy_ids.length > 0 ? product.policy_ids : ['--'];
        };
        const percentageComplete = useProductStore().isSmartProposal
          ? state.planDesignCompleteness[product.id]?.percentage_complete
          : undefined;
        const isSmartProposalWithIncompletePdv = typeof percentageComplete === 'undefined' ? false : percentageComplete !== 100;
        // for this to be true it HAS to be a smart proposal
        const isTfAssistAndDiveUnsupportedProduct = useProductStore().isSmartProposal
          && arrayHasItem(product.product_type_name, tfAssistAndDiveUnsupportedProductTypes);

        return {
          id: product.id,
          alternative: product.project_product.alternative,
          product: productLabel(product),
          productTypeName: product.product_type_name,
          status: state.statusInfo(product, isTfAssistAndDiveUnsupportedProduct, isSmartProposalWithIncompletePdv),
          policyNumber: policyInfo(),
          renewalDate: (product.originating_renewal_date && new Date(product.originating_renewal_date).toLocaleDateString('en-US', { timeZone: 'UTC' })) ?? '--',
          rateGuarantee: state.productRateGuarantees[product.id] ? `${state.productRateGuarantees[product.id]} months` : '--',
          ratePass: product.rate_pass,
          isTfAssistAndDiveUnsupportedProduct,
          details: {
            productId: product.id,
            state: product.state,
          },
          percentageComplete,
        };
      }

      return useProductStore().products
        .map((product) => createProduct(product))
        .sort((a, b) => {
          const statusA = a.status.status;
          const statusB = b.status.status;

          return statusSortOrder[statusA] - statusSortOrder[statusB];
        });
    },
  },
  actions: {
    /**
     * Fetch rate guarantee values for all NON Stop Loss products that are in a Completed state
     */
    async getProductRateGuarantees() {
      const completedProducts = useProductStore().products.filter(({ state }) => state === 'completed');

      if (completedProducts.length === 0) return;
      await Promise.all(completedProducts.map(async ({ id }) => {
        const { project_product: { rate_guarantee } } = await rateEntryService.getRateEntry(id);

        Vue.set(this.productRateGuarantees, id, rate_guarantee?.value);
      })).catch(() => { });
    },

    /**
     * Reset the product table state
     */
    resetProductTableState() {
      this.$reset();
    },
    projectStatusFromProducts(products) {
      let productStates = products.map((product) => {
        const { status } = this.statusInfo(product);

        if (['Submitted', 'Firm Requested', 'Firm', 'Firm Expired'].includes(status)) {
          return 'Submitted';
        }
        if (['Not Submitted', 'Processing', 'Ready to Submit', 'Action Needed', 'ThreeFlow Assist'].includes(status)) {
          return 'Incomplete';
        }

        return status;
      });
      const projectStatus = {
        dashboard: '',
        badge: '',
      };
      const computeProjectStatus = ({ dashboard, badge }) => ({
        dashboard: projectStatus.dashboard || dashboard,
        badge,
      });

      // Project Status Calculation Source of Truth Outline Here: https://docs.google.com/spreadsheets/d/1xTey7BU77EN0Z-Scy6_T5-M6fwVyzrVAmCyy83_y1CI/edit?gid=1047366607#gid=1047366607

      // Scenario 5

      // The Closed status computations should only be made if all products are in Closed
      if (productStates.every((state) => state === 'Closed')) {
        productStates = products.map(({ state }) => {
          // We can then compute the Project Status and Badge based on the product statuses from the BE (PRE StatusInfo computation). We will do this by using this mapping to create a new array of statuses:
          // completed === Submitted     |     Submitted Group
          // not_started === Not Started     |     Not Started Group
          // EVERYTHING ELSE === Not Submitted      |      Incomplete Group
          if (state === 'completed') {
            return 'Submitted';
          }
          if (state === 'not_started') {
            return 'Not Started';
          }

          return 'Incomplete';
        });

        projectStatus.dashboard = 'Closed';
      }

      // We should then be able to run the array of statuses through the SAME CHECKS we ran for our other non-Closed products in order to calculate the Badge state. The Project State will always be Closed.

      // Scenario 1
      // All products in the same state
      if (productStates.every((state) => state === 'Not Started')) {
        return computeProjectStatus({
          dashboard: 'New',
          badge: 'Not Started',
        });
      }
      if (productStates.every((state) => state === 'Incomplete')) {
        return computeProjectStatus({
          dashboard: 'Incomplete',
          badge: 'Incomplete',
        });
      }
      if (productStates.every((state) => state === 'Selected')) {
        return computeProjectStatus({
          dashboard: 'Sold',
          badge: 'Selected',
        });
      }
      if (productStates.every((state) => state === 'Declined')) {
        return computeProjectStatus({
          dashboard: 'Declined',
          badge: 'Declined',
        });
      }

      // Scenario 2
      // - No products Selected, or Closed
      if (productStates.every((state) => !['Selected', 'Closed'].includes(state))) {
        // No products Incomplete or Submitted and at least on product Not Started
        if (productStates.every((state) => !['Incomplete', 'Submitted', 'Declined'].includes(state))
          && productStates.some((state) => state === 'Not Started')) {
          return computeProjectStatus({
            dashboard: 'New',
            badge: 'Not Started',
          });
        }
        if (productStates.some((state) => state === 'Incomplete')) {
          return computeProjectStatus({
            dashboard: 'Incomplete',
            badge: 'Incomplete',
          });
        }
        if (productStates.some((state) => state === 'Submitted')) {
          if (productStates.some((state) => state === 'Not Started')) {
            return computeProjectStatus({
              dashboard: 'Incomplete',
              badge: 'Incomplete',
            });
          }

          return computeProjectStatus({
            dashboard: 'Submitted',
            badge: 'Submitted',
          });
        }
      }

      // Scenario 3
      // - No products Selected
      // - Some products Closed
      if (productStates.every((state) => state !== 'Selected')
        && productStates.some((state) => state === 'Closed')) {
        if (productStates.every((state) => !['Incomplete', 'Submitted'].includes(state))
          && productStates.some((state) => state === 'Not Started')) {
          return computeProjectStatus({
            dashboard: 'New',
            badge: 'Not Started',
          });
        }
        if (productStates.some((state) => state === 'Incomplete')) {
          return computeProjectStatus({
            dashboard: 'Incomplete',
            badge: 'Incomplete',
          });
        }
        if (productStates.some((state) => state === 'Submitted')) {
          if (productStates.some((state) => state === 'Not Started')) {
            return computeProjectStatus({
              dashboard: 'Incomplete',
              badge: 'Incomplete',
            });
          }

          return computeProjectStatus({
            dashboard: 'Submitted',
            badge: 'Submitted',
          });
        }
      }

      // Scenario 4
      // - Some product Selected
      if (productStates.some((state) => state === 'Selected')) {
        if (productStates.every((state) => !['Incomplete', 'Not Started'].includes(state))) {
          return computeProjectStatus({
            dashboard: 'Sold',
            badge: 'Submitted',
          });
        }
        if (productStates.some((state) => state === 'Incomplete')) {
          return computeProjectStatus({
            dashboard: 'Sold',
            badge: 'Incomplete',
          });
        }
        if (productStates.every((state) => ['Selected', 'Not Started'].includes(state))) {
          return computeProjectStatus({
            dashboard: 'Sold',
            badge: 'Incomplete',
          });
        }
      }

      // QA Additions
      if (productStates.every((state) => ['Declined', 'Closed'].includes(state))) {
        return computeProjectStatus({
          dashboard: 'Declined',
          badge: 'Declined',
        });
      }
      if (productStates.some((state) => state === 'Not Started')
        && productStates.some((state) => state === 'Submitted')) {
        return computeProjectStatus({
          dashboard: 'Incomplete',
          badge: 'Incomplete',
        });
      }

      return computeProjectStatus({
        dashboard: 'Incomplete',
        badge: 'Incomplete',
      });
    },
    /**
     * Builds the status info for the product row.
     *
     * @param {object} product
     * @param {boolean} isTfAssistAndDiveUnsupportedProduct
     * @param {boolean} isSmartProposalWithIncompletePdv
     * @returns {object}
     */
    statusInfo(product, isTfAssistAndDiveUnsupportedProduct, isSmartProposalWithIncompletePdv = false) {
      let icon;
      let iconType;
      let status;
      let stopLossState;
      let rowCtaText = 'Review Details';
      let rowCtaEnabled = true;

      if (this.soldToThisCarrier(product.project_product || product)) {
        icon = 'fa-regular fa-medal';
        iconType = 'info';
        status = 'Selected';

        return {
          icon,
          iconType,
          status,
          rowCtaEnabled,
          rowCtaText,
        };
      }

      if (this.notSoldToThisCarrier(product.project_product || product) || product.baseOrAlternativeWasSold) {
        icon = 'fa-solid fa-lock';
        iconType = '';
        status = 'Closed';

        return {
          icon,
          iconType,
          status,
          rowCtaEnabled,
          rowCtaText,
        };
      }

      switch (product.state) {
        case 'completed':
          stopLossState = product.stop_loss_state;
          icon = 'fa-regular fa-circle-check';
          iconType = 'success';
          status = 'Submitted';
          if (stopLossState && isStopLossProduct(product) && stopLossState !== 'no_action') {
            // Firm Quote (quote) has been requested.
            if (stopLossState === 'firm_quote_requested') {
              icon = 'fa-regular fa-clock-five';
              iconType = 'info';
              status = 'Firm Requested';
            }
            // Firm Quote (quote) has been submitted.
            if (stopLossState === 'firm_quote_submitted') {
              icon = 'fa-solid fa-lock';
              iconType = 'info';
              status = 'Firm';
            }
            // Firm Quote (quote) Date has been expired.
            if (stopLossState === 'firm_quote_expired') {
              icon = 'fa-regular fa-clock-five';
              iconType = 'danger';
              status = 'Firm Expired';
            }
          }
          break;
        case 'not_started':
          icon = 'fa-light fa-circle-ellipsis';
          iconType = '';
          status = 'Not Started';
          rowCtaText = isTfAssistAndDiveUnsupportedProduct ? 'Enter Rates Manually' : 'Review Details';
          if (product.project_product?.alternative) {
            icon = 'fa-light fa-circle-ellipsis';
            iconType = '';
            status = 'Not Started';
            rowCtaText = 'Enter Rates Manually';
          }
          break;
        case 'pending_review':
          icon = 'fa-regular fa-clipboard-check';
          iconType = 'success';
          status = 'Ready to Submit';
          rowCtaText = isSmartProposalWithIncompletePdv ? 'Complete Plan Design' : 'Review Details';
          break;
        case 'action_needed':
          icon = 'fa-solid fa-triangle-exclamation';
          iconType = 'warning';
          status = 'Action Needed';
          rowCtaText = 'Complete Rates';
          break;
        case 'declined':
          icon = 'fa-regular fa-circle-xmark';
          iconType = 'danger';
          status = 'Declined';
          rowCtaText = 'Undo Decline';
          break;
        case 'automation_locked':
          icon = 'fa-solid fa-spinner';
          iconType = 'info';
          status = 'Processing';
          rowCtaEnabled = false;
          break;
        case 'in_progress_modifying':
          icon = 'fa-solid fa-pencil';
          iconType = 'warning';
          status = 'Not Submitted';
          rowCtaText = 'Enter Rates Manually';
          break;
        case 'threeflow_assist':
          icon = 'fa-kit fa-tf-logo-white-small';
          iconType = 'info';
          status = 'ThreeFlow Assist';
          rowCtaEnabled = this.canEditThreeflowAssist;
          break;
        default:
          icon = 'fa-solid fa-pencil';
          iconType = 'warning';
          status = 'Not Submitted';
          rowCtaText = isTfAssistAndDiveUnsupportedProduct ? 'Enter Rates Manually' : 'Review Details';
          if (product.project_product?.alternative) {
            icon = 'fa-solid fa-pencil';
            iconType = 'warning';
            status = 'Not Submitted';
            rowCtaText = 'Enter Rates Manually';
          }
      }

      return {
        icon,
        iconType,
        status,
        rowCtaEnabled,
        rowCtaText,
      };
    },
    /**
     * If product is sold to this carrier
     *
     * @param {object} product
     * @param {number} product.sold_carrier_id
     * @returns {boolean}
     */
    soldToThisCarrier({ sold_carrier_id }) {
      return useCarrierInfoStore().id === sold_carrier_id;
    },
    /**
     * If product is sold but not to this carrier, or if the product was marked as 'no policy sold'.
     *
     * @param {object} product
     * @param {number} product.sold_carrier_id
     * @param {string} product.sold_status
     * @returns {boolean}
     */
    notSoldToThisCarrier({ sold_carrier_id, sold_status }) {
      return (!this.soldToThisCarrier({ sold_carrier_id }) && this.isSold(sold_status)) || (/no policy sold/i).test(sold_status);
    },
    /**
     * If product is sold
     *
     * @param {string} soldStatus
     * @returns {boolean}
     */
    isSold(soldStatus) {
      return (/new policy sold|renewed/i).test(soldStatus);
    },
    /**
     * Fetches the plan design completeness for all the products
     *
     * @param {number} documentId
     * @returns {Array}
     */

    async getPlanDesignCompleteness(documentId) {
      try {
        const { products } = await productService.getPlanDesignCompleteness(documentId);

        this.planDesignCompleteness = products.reduce((acc, product) => {
          acc[product.id] = product;

          return acc;
        }, {});

        return this.planDesignCompleteness;
      } catch {
        throw new Error('Unable to load plan design completeness values');
      }
    },
  },
});
