<template>
  <td
    :class="{
      'cell-empty' : !label,
      'cell-composite' : isComposite
    }"
    width="25%"
  >
    <template v-if="label && !isComposite">
      {{ label | ageBanded }}
    </template>
  </td>
</template>

<script>
/**
 * Rate Entry Table Cell Label
 *
 * @exports RateEntry/RateEntryTdLabel
 */
  export default {
    name: 'RateEntryTdLabel',
    props: {
      /**
       * Subtype cell group label
       */
      label: {
        type: String,
        default: '',
      },
      /**
       * Subtype Type used to determine the label layout
       */
      subtypeType: {
        type: String,
        default: 'CompositeRateValue',
      },
    },
    computed: {
      /**
       * Tests the Subtype group type to determine if cell group should be a composite layout.
       *
       * @returns {boolean}
       */
      isComposite() {
        return this.subtypeType === 'CompositeRateValue';
      },
    },
  };
</script>

<style scoped lang="scss">
.cell-composite:not(.cell-empty) {
  /* https://www.coffee-break-designs.com/labs/svg_stripe_generator/ */
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPjxkZWZzPjxwYXR0ZXJuIGlkPSJwYXR0ZXJuX3F4UUNPMSIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgd2lkdGg9IjQuNSIgaGVpZ2h0PSI0LjUiIHBhdHRlcm5UcmFuc2Zvcm09InJvdGF0ZSg0NSkiPjxsaW5lIHgxPSIwIiB5PSIwIiB4Mj0iMCIgeTI9IjQuNSIgc3Ryb2tlPSIjQjRCNEI0IiBzdHJva2Utd2lkdGg9IjEiLz48L3BhdHRlcm4+PC9kZWZzPiA8cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI3BhdHRlcm5fcXhRQ08xKSIgb3BhY2l0eT0iMSIvPjwvc3ZnPg==');
  background-repeat: repeat;
}
</style>
