import { VueLd } from 'vue-ld'; // Plugin to integrate Launch Darkly into Vue apps
import { developmentFlagValues, defaultFlagValues } from '@/utils/featureFlags.js';
import Vue from 'vue';
import { canUseThirdParty } from '@/utils/general.js';
import { config } from '@/utils/config.js';
import { trackLogRocketEvent } from '@/utils/analytics.js';

/**
 * Launch darkly configuration functions including initialization and identify calls for when a user changes
 *
 * @exports utils/launchDarkly
 */

// When we're in a local environment, we want to stub flags by default (use the developmentFlagValues values)
// however if we run a local build with `serve:with --third_parties=launchDarkly`, we don't stub and instead use
// the values from the launch darkly QA environment.
const shouldStubFlags = config.featureFlagsDisabled(['development', 'test', 'ci']) && !canUseThirdParty('launchDarkly');

/**
 * Adds checkFlags method to the global LaunchDarkly object to allow us to declare default flags in case
 * a flag cannot be found or a connection to LaunchDarkly cannot be established
 *
 * @param {string} flagName
 * @returns {number|string|boolean}
 */
export const checkFlags = (flagName) => {
  if (window.Cypress?.cypressOverrideFlags?.hasOwnProperty(flagName)) {
    return window.Cypress.cypressOverrideFlags[flagName];
  }

  const { ready, flags } = Vue.prototype.$ld;
  // Unable to call hasOwnProperty on a global variable, so object.keys used instead
  const ldFlagNameExists = flags && Object.keys(flags).includes(flagName);
  const defaultFlagNameExists = Object.prototype.hasOwnProperty.call(defaultFlagValues, flagName);

  if (shouldStubFlags || (ready && ldFlagNameExists)) {
    if (flags[flagName]) trackLogRocketEvent(flagName);

    return flags[flagName];
  }

  // If a flag cannot be found or a connection to LaunchDarkly cannot be established and there is
  // no defaultFlagValue in the utility file, then the feature flag's value falls back to false.
  // Whether or not we want it to be true or false by default is a conversation for the team
  return defaultFlagNameExists ? defaultFlagValues[flagName] : false;
};

/**
 * Initializes Launch Darkly with an anonymous user and the clientSideId that corresponds with the
 * the current environment. Also passes any custom options to the Launch Darkly client.
 * Leverages dashHudsons Vue-Ld Vue plugin to interface with Launch Darkly: https://github.com/dashhudson/vue-ld
 *
 * @param {object} configuration
 * @param {string} configuration.clientSideId
 * @param {object} configuration.options
 * @param {object} configuration.user
 */
export const initializeLD = ({ clientSideId, options, user }) => {
  Vue.use(VueLd, {
    clientSideId,
    options,
    user,
    // If flagsStub is provided, the ldClient will not be initialized and $ld.flags will be set to the provided stub (developmentFlagValues)
    flagsStub: shouldStubFlags ? developmentFlagValues : undefined,
  });
  Vue.prototype.$ld.checkFlags = checkFlags;
};

/**
 * The identify method notifies Launch Darkly that the user has changed and provides their related userInfo so that
 * Launch Darkly can conditionally render flags based on the user
 *
 * @param {object} configuration
 */
export const identifyLDUser = (configuration) => {
  Vue.prototype.$ld.identify({
    hash: configuration.options.hash,
    newUser: configuration.user,
  });
};

/**
 * Parse the LD-CONFIGURATION header on every Core API response as a JSON object
 *
 * @param {object} response
 * @param {object} response.headers
 * @returns {object}
 */
export const parseLDConfig = ({ headers = {} }) => JSON.parse(headers['ld-configuration']);
